import React, { useCallback, useEffect, useState } from 'react';
import { useStrings } from 'providers/stringsprovider';
import useWindowDimensions from 'hooks/useWindowDimensions';
import SuggestionCard from 'components/suggestion-card';
import Button from 'components/common/button';
import StatisticsCard from 'components/statistics-card';
import { useNavigate } from 'react-router';
import routePaths from 'routes/paths';
import { useAuth } from 'providers/authprovider';
import { account_types } from 'resources/data';
import { useAppData } from 'providers/appdataprovider';
import Loader from 'components/common/loader';
import { getStatisticsCountApi } from 'networking/api/company';
import styles from './styles.module.css';

const Dashboard = () => {
  const { setBreadcrumbs } = useAppData();
  const { userType, company } = useAuth();
  const { strings } = useStrings();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [isSuggestionBoardOpen, setIsSuggestionBoardOpen] = useState(() => {
    const storedValue = localStorage.getItem('DS');
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [statistics, setStatistics] = useState();

  useEffect(() => {
    setBreadcrumbs([{ title: strings.dashboard, route: '' }]);
  }, [setBreadcrumbs, strings.dashboard]);

  const getStatistics = useCallback(async () => {
    try {
      if (company) {
        setIsShowLoading(true);
        const response = await getStatisticsCountApi(company._id);
        setStatistics(response.data.data);
        setIsShowLoading(false);
      }
    } catch (error) {
      setIsShowLoading(false);
    }
  }, [company, setStatistics]);

  useEffect(() => {
    getStatistics();
  }, [getStatistics]);

  const handleSuggestionBoardClose = () => {
    localStorage.setItem('DS', JSON.stringify(false));
    setIsSuggestionBoardOpen(false);
  };

  const renderStaticsSection = () => {
    return (
      <div className={styles.statisticsBlockStyle}>
        <div className={styles.dashboardSectionStyle}>
          <div className={styles.labelWrapperStyle}>
            <p className={styles.labelTitleStyle}>Assets</p>
          </div>
          <div className={styles.staticCardStyles}>
            <StatisticsCard
              count={
                statistics?.assetCount?.assignedCount + statistics?.assetCount?.unAssignedCount
              }
              title={strings.totalAssets}
              onClick={() => navigate(routePaths.assetList)}
            />
            <StatisticsCard
              count={statistics?.assetCount?.assignedCount}
              title={strings.assignedAssets}
              onClick={() => navigate(routePaths.assetList)}
            />
            <StatisticsCard
              count={statistics?.assetCount?.unAssignedCount}
              title={strings.unAssignedAssets}
              onClick={() => navigate(routePaths.assetList)}
            />
            {/* 
             // *Todo this is hided feture 
            <StatisticsCard
              count={assets?.filter((a) => a.asset_status === 'Marked lost')?.length}
              title={strings.lostAssets}
              onClick={() => navigate(routePaths.assetList)}
            /> */}
          </div>
        </div>
        {userType !== account_types.employee && (
          <div className={styles.dashboardSectionStyle}>
            <div className={styles.labelWrapperStyle}>
              <p className={styles.labelTitleStyle}>Users</p>
            </div>
            <div className={styles.staticCardStyles}>
              <StatisticsCard
                count={statistics?.userCount?.adminCount + statistics?.userCount?.employeeCount}
                title={'Total users'}
                onClick={() => navigate(routePaths.employeeList)}
              />
              <StatisticsCard
                count={statistics?.userCount?.adminCount}
                title={'Admin users'}
                onClick={() => navigate(routePaths.employeeList)}
              />
              <StatisticsCard
                count={statistics?.userCount?.employeeCount}
                title={'Employee users'}
                onClick={() => navigate(routePaths.employeeList)}
              />
            </div>
          </div>
        )}
        <div className={styles.dashboardSectionStyle}>
          <div className={styles.labelWrapperStyle}>
            <p className={styles.labelTitleStyle}>Tickets</p>
          </div>
          <div className={styles.staticCardStyles}>
            <StatisticsCard
              count={statistics?.ticketCount?.closedTickets + statistics?.ticketCount?.openTickets}
              title={'All tickets'}
              onClick={() => navigate(routePaths.ticketList)}
            />
            <StatisticsCard
              count={statistics?.ticketCount?.openTickets}
              title={'Open tickets'}
              onClick={() => navigate(routePaths.ticketList)}
            />
            <StatisticsCard
              count={statistics?.ticketCount?.closedTickets}
              title={'Closed tickets'}
              onClick={() => navigate(routePaths.ticketList)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.containerStyle}>
      {isShowLoading && <Loader />}
      {isSuggestionBoardOpen && (
        <SuggestionCard title={strings.title} description={strings.descp}>
          <div className={styles.suggestionBtnWrapperStyle}>
            <Button title={strings.learnMore} />
            <Button
              title={width <= 480 ? strings.hide : strings.dontShowAgain}
              variant="light"
              onClick={handleSuggestionBoardClose}
            />
          </div>
        </SuggestionCard>
      )}
      {renderStaticsSection()}
    </div>
  );
};

export default Dashboard;
