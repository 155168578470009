import React, { createContext, useContext, useState } from 'react';
import Loader from 'components/common/loader';

export const SpinnerContext = createContext();

const SpinnerProvider = (props) => {
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [spinnerForPages, setIsSpinnerForPages] = useState(false);
  return (
    <SpinnerContext.Provider
      value={{
        isShowLoading,
        setIsShowLoading,
        spinnerForPages,
        setIsSpinnerForPages,
      }}
    >
      {isShowLoading && <Loader />}
      {props.children}
    </SpinnerContext.Provider>
  );
};

export default SpinnerProvider;

export const useSpinner = () => {
  const context = useContext(SpinnerContext);

  if (context === undefined) {
    throw new Error('useSpinner must be used within SpinnerProvider');
  }
  return context;
};
