import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from 'layouts/dashboard';
import Login from 'pages/auth/login';
import GuestGuard from 'guards/guestguard';
import AuthGuard from 'guards/authguard';
import routePaths from './paths';
import { account_types } from 'resources/data';
import SignUp from 'pages/auth/signup';
import AssetList from 'pages/asset/list';
import AssetRegistration from 'pages/asset/registration';
import CompanyList from 'pages/company/list';
import EmployeeList from 'pages/employee/list';
import CompanyRegistration from 'pages/company/registration';
import TicketRaise from 'pages/ticket/raise';
import EmployeeRegistration from 'pages/employee/registration';
import AssetDetails from 'pages/asset/details';
import EmployeeDetails from 'pages/employee/details';
import Dashboard from 'pages/dashboard';
import TicketList from 'pages/ticket/list';
import MaintenanceClusterList from 'pages/maintenance-cluster/list';
import LostAndFound from 'pages/lost-and-found';
import Scanner from 'pages/scanner';
import ScanneddAsset from 'pages/scannedasset';
import ReturnItem from 'pages/returnitem';
import RaiseTicketThroughQR from 'pages/raiseticketthroughQR';
import EmailRequired from 'pages/emailrequired';

const AppRoutes = () => {
  const routes = useRoutes([
    {
      path: routePaths.login,
      element: (
        <GuestGuard userType={Object.values(account_types)}>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: routePaths.signup,
      element: <SignUp />,
    },
    {
      path: routePaths.scanner,
      element: <Scanner />,
    },
    {
      path: '/asset/:id',
      element: <ScanneddAsset />,
    },
    {
      path: routePaths.guestLogin,
      element: <EmailRequired />,
    },
    {
      path: routePaths.returnItem,
      element: <ReturnItem />,
    },
    {
      path: routePaths.raiseTicketThroughQr,
      element: <RaiseTicketThroughQR />,
    },
    {
      path: '',
      element: (
        <AuthGuard userType={Object.values(account_types)}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={routePaths.dashboard} replace />,
          index: true,
        },
        {
          path: routePaths.dashboard,
          element: <Dashboard />,
        },
        {
          path: routePaths.createCompany,
          element: <CompanyRegistration />,
        },
        {
          path: routePaths.createEmployee,
          element: <EmployeeRegistration />,
        },
        {
          path: routePaths.createAsset,
          element: <AssetRegistration />,
        },
        {
          path: routePaths.raiseTicket,
          element: <TicketRaise />,
        },
        {
          path: routePaths.companyList,
          element: <CompanyList />,
        },
        {
          path: routePaths.employeeList,
          element: <EmployeeList />,
        },
        {
          path: '/employee/details/:id',
          element: <EmployeeDetails />,
        },
        {
          path: routePaths.assetList,
          element: <AssetList />,
        },
        {
          path: '/asset/details/:id',
          element: <AssetDetails />,
        },
        {
          path: routePaths.ticketList,
          element: <TicketList />,
        },
        {
          path: routePaths.clusterList,
          element: <MaintenanceClusterList />,
        },
        {
          path: routePaths.lostAndFound,
          element: <LostAndFound />,
        },
      ],
    },
  ]);

  return routes;
};

export default AppRoutes;
