import React, { createContext, useState, useMemo, useCallback, useEffect, useContext } from 'react';
import { useToast } from 'providers/toastprovider';
import { setAxiosSession } from 'utils/auth';
import { getDataFromToken, isTokenExpired } from 'utils/jwt';
import { employeeDetailsWithCompaniesApi } from 'networking/api/employee';
import { superAdminDetailsApi } from 'networking/api/admin';
import { userLoginApi } from 'networking/api/auth';
import { account_types } from 'resources/data';

const AuthContext = createContext();

const AuthProvider = (props) => {
  const toast = useToast();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);
  const [userType, setUserType] = useState(null);
  const [authError, setAuthError] = useState(null);

  const initialize = useCallback(async () => {
    try {
      const token = localStorage.getItem('accessToken');
      if (token && isTokenExpired(token)) {
        setAxiosSession(token);
        const tokenData = await getDataFromToken(token);
        if (tokenData.type === account_types.super_admin) {
          let response = await superAdminDetailsApi();
          setUser(response.data.data);
          setUserType(tokenData.type);
          setAxiosSession(token);
          if (response.data.data.companies.length === 1) {
            setCompany(response.data.data.companies[0]);
            setIsLoggedIn(true);
          } else {
            let previousCompany = localStorage.getItem('company');
            let findCompany = response.data.data.companies.find(
              (company) => company._id === previousCompany
            );
            if (findCompany) {
              setCompany(findCompany);
              setIsLoggedIn(true);
            }
          }
        } else {
          let response = await employeeDetailsWithCompaniesApi(tokenData.id);
          setUser(response.data.data);
          setCompany(response.data.data.companies[0]);
          setUserType(tokenData.type);
          setAxiosSession(token);
          setIsLoggedIn(true);
        }
        setIsInitialized(true);
      } else {
        setIsInitialized(true);
        setIsLoggedIn(false);
      }
    } catch (error) {
      setIsInitialized(true);
      setIsLoggedIn(false);
      setAuthError(error.message);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const userLogin = useCallback(
    async (loginData) => {
      try {
        setAuthError(null);
        let response = await userLoginApi(loginData);
        const { data, token } = response.data;
        toast.success('Login success');
        localStorage.setItem('accessToken', token);
        const tokenData = await getDataFromToken(token);
        if (tokenData.type === account_types.super_admin) {
          setUser(data);
          setUserType(tokenData.type);
          setAxiosSession(token);
          if (response.data.data.companies.length === 1) {
            setIsLoggedIn(true);
            setCompany(response.data.data.companies[0]);
          }
        } else {
          // Todo this is usefull in future update
          // setIsLoggedIn(true);
          // let ss = [
          //   ...data.companies,
          //   { company_name: 'Tata Motors', email: 'tata@yopmail.com' },
          //   { company_name: 'Tesla', email: 'tesla@yopmail.com' },
          // ];
          // data.companies = ss;
          setUser(data);
          if (data.companies.length === 1) {
            setIsLoggedIn(true);
            setCompany(data.companies[0]);
          }
          setUserType(tokenData.type);
          setAxiosSession(token);
        }
      } catch (error) {
        setUser(null);
        setAuthError(error.message);
        setIsLoggedIn(false);
      }
    },
    [toast]
  );

  const logout = useCallback(() => {
    localStorage.removeItem('accessToken');
    setUser(null);
    setCompany(null);
    localStorage.removeItem('company');
    setAxiosSession(null);
    setIsLoggedIn(false);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized,
      isLoggedIn,
      user,
      company,
      userType,
      authError,
      setUser,
      setIsLoggedIn,
      setCompany,
      setAuthError,
      userLogin,
      logout,
    }),
    [
      isInitialized,
      isLoggedIn,
      user,
      company,
      userType,
      authError,
      setUser,
      setIsLoggedIn,
      setCompany,
      setAuthError,
      userLogin,
      logout,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{props.children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within AuthProvider');
  }
  return context;
};
