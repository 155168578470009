import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'components/common/image';
import {
  calendarIcon,
  chevronDownFillGrayIcon,
  chevronUpFillGrayIcon,
  closeIcon,
  infoCircleDownGrayIcon,
  infoCircleUpFillGrayIcon,
} from 'resources/images';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Capsule from 'components/common/capsule';
import Input from 'components/common/input';
import DatePicker from 'components/common/date-picker';
import PopOver from 'components/common/pop-over';
import MultiFileUpload from 'components/multi-file-upload';
import Button from 'components/common/button';
import { addReminderApi, updateReminderApi } from 'networking/api/asset';
import classNames from 'classnames';
import Loader from 'components/common/loader';
import { addUserReminderApi, handleUpdateUserReminderApi } from 'networking/api/employee';
import { reminderActionTypes } from 'resources/data';
import { useStrings } from 'providers/stringsprovider';
import CheckBox from 'components/common/checkbox';
import { isMatch } from 'lodash';
import uuidv4 from 'utils/uuidv4';
import { useToast } from 'providers/toastprovider';
import styles from './styles.module.css';

const AddEditReminder = (props) => {
  const { type, handleWithApi = false, onclose = () => {}, reminder, setReminder } = props;
  const { strings } = useStrings();
  const toast = useToast();
  const reminderTypes = {
    once: 'once',
    repeat: 'repeat',
  };
  const repeatTypes = ['Hours', 'Days', 'Weeks', 'Months', 'Years'];
  const [reminderType, setReminderType] = useState(reminder?.type || reminderTypes.once);
  const [timerRef, setTimerRef] = useState(null);
  const [showTimerPopup, setShowTimerPopup] = useState(false);
  const [timerArray, setTimerArray] = useState([]);
  const [timerArrayType, setTimerArrayType] = useState('');
  const [hoursArray, setHoursArray] = useState([]);
  const [minutesArray, setMinutesArray] = useState([]);
  const [meridiemArray, setMeridiemArray] = useState([]);
  const [date, setDate] = useState(
    reminder?.start_date ? moment(reminder.start_date).format('YYYY-MM-DD') : ''
  );
  const [selectedHour, setSelectedHour] = useState(
    reminder?.start_date ? moment(reminder.start_date).format('h') : 0
  );
  const [selectedMinute, setSelectedMinute] = useState(
    reminder?.start_date ? moment(reminder.start_date).format('m') : 0
  );
  const [selectedMeridiem, setSelectedMeridiem] = useState(
    reminder?.start_date ? moment(reminder.start_date).format('A') : moment().format('A')
  );
  const [showRepeatTypePopUp, setShowRepeatTypePopUp] = useState(false);
  const [repeatRef, setRepeatRef] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [disableCreate, setDisableCreate] = useState(false);
  const [endDateCheckBoxState, setEndDateCheckBoxState] = useState(false);
  const [isValuesChanged, setIsValuesChanged] = useState(false);
  const schemaObj = {
    type: Yup.string(),
    title: Yup.string().required('Title is required'),
    start_date: Yup.date().required('Reminder date and time is required'),
    repeat_interval: Yup.object().shape({
      type: Yup.string(),
      value: Yup.string(),
    }),
    end_date: Yup.date().notRequired(),
  };

  const assetSchema = Yup.object().shape(schemaObj);
  const formik = useFormik({
    initialValues: {
      type: reminderType,
      title: reminder ? reminder.title : '',
      notes: reminder ? reminder.notes : '',
      start_date: reminder ? reminder.start_date : '',
      repeat_interval:
        reminder && reminder?.repeat_interval
          ? reminder?.repeat_interval
          : {
              type: 'Hours',
              value: 1,
            },
      end_date: reminder ? reminder.end_date : '',
      attachments: reminder ? reminder.attachments : [],
    },
    validationSchema: assetSchema,
    onSubmit: handleNextAndSubmitBtn,
  });

  useEffect(() => {
    if (date) {
      const currentDate = moment();
      const selectedDate = moment(date);
      if (selectedDate.isSame(currentDate, 'day')) {
        const hours = [];
        const minutes = [];
        let currentHour = currentDate.hour();
        let currentMinute = currentDate.minute();
        if (currentDate.hour() >= 12) {
          setMeridiemArray(['PM']);
          setSelectedMeridiem('PM');
        } else {
          setMeridiemArray(['AM', 'PM']);
        }
        if (currentDate.hour() < 12 && selectedMeridiem === 'AM') {
          for (let i = currentHour; i <= 11; i++) {
            hours.push(i);
          }
        } else {
          for (let i = currentHour; i <= 23; i++) {
            if (i > 12) {
              if (!hours.includes(i - 12)) {
                hours.push(i - 12);
              }
            } else {
              if (!hours.includes(i)) {
                hours.push(i);
              }
            }
          }
        }
        if (selectedHour) {
          for (
            let minute = selectedHour > currentHour % 12 ? 0 : currentMinute + 1;
            minute < 60;
            minute++
          ) {
            minutes.push(minute);
          }
        }
        setHoursArray(hours);
        setMinutesArray(minutes);
      } else if (selectedDate.isAfter(currentDate, 'day')) {
        const hours = [];
        const minutes = [];
        for (let hour = 1; hour <= 12; hour++) {
          hours.push(hour);
        }
        for (let minute = 0; minute < 60; minute++) {
          minutes.push(minute);
        }
        setHoursArray(hours);
        setMinutesArray(minutes);
        setMeridiemArray(['AM', 'PM']);
      } else {
        setMeridiemArray([]);
        setHoursArray([]);
        setMinutesArray([]);
      }
    }
  }, [date, selectedHour, selectedMeridiem]);

  useEffect(() => {
    if (date) {
      formik.setFieldValue(
        'start_date',
        moment(
          `${moment(date).format(
            'YYYY-MM-DD'
          )} ${selectedHour}:${selectedMinute} ${selectedMeridiem}`
        ).format()
      );
    }
    // eslint-disable-next-line
  }, [date, selectedHour, selectedMinute, selectedMeridiem]);

  useEffect(() => {
    if (type === reminderActionTypes.edit) {
      const objectToCompare = {
        type: reminder?.type,
        title: reminder?.title,
        notes: reminder?.notes,
        start_date: reminder?.start_date ? moment(reminder.start_date).format() : '',
        repeat_interval: reminder?.repeat_interval,
        end_date: reminder?.end_date,
        attachments: reminder?.attachments,
      };
      setIsValuesChanged(isMatch(objectToCompare, formik.values));
    }
  }, [type, formik.values, reminder, setIsValuesChanged]);

  async function handleNextAndSubmitBtn(values) {
    try {
      let modifiedReminder = values;
      modifiedReminder.start_date = moment(
        `${moment(modifiedReminder.start_date).format(
          'YYYY-MM-DD'
        )} ${selectedHour}:${selectedMinute} ${selectedMeridiem}`
      ).format();
      if (modifiedReminder.type === reminderTypes.once) {
        delete modifiedReminder['repeat_interval'];
      }
      let attachments = values.attachments?.map((item) => item._id);
      if (type === reminderActionTypes.add && handleWithApi) {
        if (reminder?.user) {
          await handleAddUserReminder({ user: reminder?.user, ...modifiedReminder, attachments });
        } else {
          await handleAddAssetReminder({
            asset: reminder?.asset,
            ...modifiedReminder,
            attachments,
          });
        }
      } else if (type === reminderActionTypes.edit && handleWithApi && reminder?._id) {
        await handleUpdateReminder({
          reminder_id: reminder._id,
          ...reminder,
          ...modifiedReminder,
          attachments,
        });
      } else {
        if (type === reminderActionTypes.add) {
          setReminder({ local_id: uuidv4(), ...modifiedReminder });
        } else {
          setReminder({ local_id: reminder?.local_id, ...modifiedReminder });
        }
        onclose();
      }
    } catch (error) {}
  }

  const handleAddUserReminder = async (data) => {
    try {
      setLoading(true);
      setErrorMessage('');
      let response = await addUserReminderApi(data);
      setReminder({ ...response.data.data, status: response.data.data?.status || [] });
      toast.success(response.data.message);
      setLoading(false);
      onclose();
    } catch (error) {
      toast.error(error.message);
      setErrorMessage(error.message);
      setLoading(false);
    }
  };

  const handleAddAssetReminder = async (data) => {
    try {
      setLoading(true);
      setErrorMessage('');
      let response = await addReminderApi(data);
      setReminder({ ...response.data.data, status: response.data.data?.status || [] });
      toast.success(response.data.message);
      setLoading(false);
      onclose();
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
      setErrorMessage(error.message);
    }
  };

  const handleUpdateReminder = async (data) => {
    try {
      setErrorMessage('');
      setLoading(true);
      let response = {};
      if (reminder?.user) {
        response = await handleUpdateUserReminderApi(data);
      } else {
        response = await updateReminderApi(data);
      }
      setReminder(response.data.data);
      toast.success(response.data.message);
      setLoading(false);
      onclose();
    } catch (error) {
      toast.error(error.message);
      setErrorMessage(error.message);
      setLoading(false);
    }
  };

  const handleTimeFunction = (value) => {
    setShowTimerPopup(false);
    if (timerArrayType === 'Hour') {
      setSelectedHour(value);
      setTimerArrayType('');
    } else if (timerArrayType === 'Minute') {
      setSelectedMinute(value);
      setTimerArrayType('');
    } else if (timerArrayType === 'Meridiem') {
      setSelectedMeridiem(value);
      setTimerArrayType('');
    }
  };

  const headerSection = () => {
    return (
      <div className={styles.headerContainerStyle}>
        <h2 className={styles.headerTextStyle}>
          {type === reminderActionTypes.add ? strings.addReminder : strings.editReminder}
        </h2>
        <Image src={closeIcon} containerStyle={styles.closeIconStyle} onClick={onclose} />
      </div>
    );
  };

  const renderInputsSection = () => {
    return (
      <div className={styles.inputsContainerStyle}>
        {renderReminderType()}
        {renderTitleAndReminderInput()}
        {renderDateAndTimer()}
        {reminderType === reminderTypes.repeat && (
          <React.Fragment>{renderRepeatTimerInputs()}</React.Fragment>
        )}
        <div className={styles.attachmentsViewStyle}>
          <div className={styles.attachmentsLabelViewStyle}>
            <p className={styles.labelTextStyle}>Reminder attachments</p>
            <Image src={infoCircleUpFillGrayIcon} containerStyle={styles.labelIconStyle} />
          </div>
          <MultiFileUpload
            files={formik.values.attachments || []}
            setFiles={(data) => formik.setFieldValue('attachments', data)}
            setFilesUploading={(val) => setDisableCreate(val)}
          />
        </div>
      </div>
    );
  };

  const renderReminderType = () => {
    return (
      <div className={styles.reminderTypeWrapperStyle}>
        <p className={styles.labelTextStyle}>Select reminder type*</p>
        <div className={styles.capsulesWrapperStyle}>
          <Capsule
            label="One-time event"
            containerStyle={
              reminderType === reminderTypes.once
                ? styles.capsuleStyle
                : styles.capsuleUnselectStyle
            }
            labelStyle={reminderType !== reminderTypes.once ? styles.capsuleLabelStyle : ''}
            onClick={() => {
              setReminderType(reminderTypes.once);
              formik.setFieldValue('type', reminderTypes.once);
            }}
          />
          <Capsule
            label="Repeat event"
            containerStyle={
              reminderType === reminderTypes.repeat
                ? styles.capsuleStyle
                : styles.capsuleUnselectStyle
            }
            labelStyle={reminderType !== reminderTypes.repeat ? styles.capsuleLabelStyle : ''}
            onClick={() => {
              setReminderType(reminderTypes.repeat);
              formik.setFieldValue('type', reminderTypes.repeat);
            }}
          />
        </div>
      </div>
    );
  };

  const renderTitleAndReminderInput = () => {
    return (
      <div className={styles.inputsSectionStyle}>
        <Input
          name="title"
          labelText="Title*"
          placeholder="Enter Title"
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasError={formik.touched.title && formik.errors.title ? formik.errors.title : ''}
        />
        <Input
          name="notes"
          type="textarea"
          labelText="Reminder notes"
          placeholder="Enter notes here.."
          value={formik.values.notes}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasError={formik.touched.notes && formik.errors.notes ? formik.errors.notes : ''}
        />
      </div>
    );
  };

  const renderDateAndTimer = () => {
    return (
      <React.Fragment>
        <div className={styles.datePickerWrapperStyle}>
          <DatePicker
            labelText={'Remind me on?*'}
            labelTextStyle={styles.labelTextStyle}
            placeholder="DD-MM-YYYY"
            format="DD MMMM YYYY"
            icon={calendarIcon}
            containerStyle={styles.datePickerStyle}
            minDate={new Date()}
            value={date}
            onChange={(date) => setDate(date)}
          />

          <div className={styles.timeInputsContainerStyle}>
            <p className={styles.labelTextStyle}>Time*</p>
            <div className={styles.timeSectionWrapperStyle}>
              <div
                className={styles.timeInputStyle}
                onClick={(e) => {
                  setTimerRef(e.currentTarget);
                  setShowTimerPopup(true);
                  setTimerArray(hoursArray);
                  setTimerArrayType('Hour');
                }}
              >
                <p className={styles.timeSelectTextStyle}>
                  {selectedHour ? String(selectedHour).padStart(2, '0') : '00'}
                </p>
                <Image
                  src={timerArrayType === 'Hour' ? chevronUpFillGrayIcon : chevronDownFillGrayIcon}
                  containerStyle={styles.chevronIconStyle}
                />
              </div>
              <span>:</span>
              <div
                className={styles.timeInputStyle}
                onClick={(e) => {
                  setTimerRef(e.currentTarget);
                  setTimeout(() => setShowTimerPopup(true), 0);
                  setTimerArray(minutesArray);
                  setTimerArrayType('Minute');
                }}
              >
                <p className={styles.timeSelectTextStyle}>
                  {selectedMinute ? String(selectedMinute).padStart(2, '0') : '00'}
                </p>
                <Image
                  src={
                    timerArrayType === 'Minute' ? chevronUpFillGrayIcon : chevronDownFillGrayIcon
                  }
                  containerStyle={styles.chevronIconStyle}
                />
              </div>
              <span>:</span>
              <div
                className={styles.timeInputStyle}
                onClick={(e) => {
                  setTimerRef(e.currentTarget);
                  setShowTimerPopup(true);
                  setTimerArray(meridiemArray);
                  setTimerArrayType('Meridiem');
                }}
              >
                <p className={selectedMeridiem ? styles.timeSelectTextStyle : styles.timeTextStyle}>
                  {selectedMeridiem ? selectedMeridiem : 'AM'}
                </p>
                <Image
                  src={
                    timerArrayType === 'Meridiem' ? chevronUpFillGrayIcon : chevronDownFillGrayIcon
                  }
                  containerStyle={styles.chevronIconStyle}
                />
              </div>
            </div>
          </div>
        </div>
        {formik.errors.start_date && (
          <p className={styles.errorTextStyle}>
            {formik.touched.start_date && formik.errors.start_date ? formik.errors.start_date : ''}
          </p>
        )}
      </React.Fragment>
    );
  };

  const renderTimerPopup = () => {
    return (
      <PopOver
        reference={timerRef}
        show={showTimerPopup}
        onClose={() => {
          setShowTimerPopup(false);
          setTimerArrayType('');
          setTimerArray([]);
          setTimerRef(null);
        }}
        placement="bottom"
        containerStyle={styles.popUpWrapperStyle}
        relativeWidth
      >
        <div className={styles.timerPopUpWrapperStyle}>
          {timerArray?.map((item, index) => {
            return (
              <p
                key={index}
                className={classNames(
                  styles.selectTimeTextStyle,
                  ((item === selectedHour && timerArrayType === 'Hour') ||
                    (item === selectedMinute && timerArrayType === 'Minute') ||
                    item === selectedMeridiem) &&
                    styles.activeOptionStyle
                )}
                onClick={() => handleTimeFunction(item)}
              >
                {typeof item === 'number' ? String(item).padStart(2, '0') : item}
              </p>
            );
          })}
        </div>
      </PopOver>
    );
  };

  const getMinimumDate = () => {
    if (formik.values?.repeat_interval?.type === 'Weeks') {
      return moment(formik.values.start_date).add(formik.values?.repeat_interval?.value, 'week');
    } else if (formik.values?.repeat_interval?.type === 'Months') {
      return moment(formik.values.start_date).add(formik.values?.repeat_interval?.value, 'month');
    } else if (formik.values?.repeat_interval?.type === 'Years') {
      return moment(formik.values.start_date).add(formik.values?.repeat_interval?.value, 'year');
    } else {
      return formik.values.start_date;
    }
  };

  const renderRepeatTimerInputs = () => {
    return (
      <div className={styles.datePickerWrapperStyle}>
        <div className={styles.timeInputsContainerStyle}>
          <p className={styles.labelTextStyle}>Repeat every*</p>
          <div className={styles.timeSectionWrapperStyle}>
            <Input
              name="repeat_interval.value"
              type="number"
              placeholder="00"
              min="1"
              max="100"
              containerStyle={styles.repeatNumberInputSTyle}
              value={formik.values?.repeat_interval?.value}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div
              className={styles.timeInputStyle}
              onClick={(e) => {
                setShowRepeatTypePopUp(true);
                setRepeatRef(e.target);
              }}
            >
              <p
                className={
                  formik.values?.repeat_interval?.type
                    ? styles.timeSelectTextStyle
                    : styles.timeTextStyle
                }
              >
                {formik.values?.repeat_interval?.type
                  ? formik.values?.repeat_interval?.type
                  : 'Hours'}
              </p>
              <Image
                src={showRepeatTypePopUp ? chevronUpFillGrayIcon : chevronDownFillGrayIcon}
                containerStyle={styles.chevronIconStyle}
              />
            </div>
          </div>
        </div>
        <div className={styles.endTimerContainerStyle}>
          <DatePicker
            labelText={'Ends on'}
            labelTextStyle={styles.labelTextStyle}
            placeholder="DD-MM-YYYY"
            format="DD MMMM YYYY"
            icon={calendarIcon}
            containerStyle={styles.datePickerStyle}
            minDate={getMinimumDate()}
            value={formik.values.end_date}
            onChange={(date) => {
              formik.setFieldValue('end_date', date);
              setEndDateCheckBoxState(false);
            }}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.end_date && formik.errors.end_date ? formik.errors.end_date : ''
            }
          />
          <div className={styles.infoTextWrapperStyle}>
            <Image src={infoCircleDownGrayIcon} containerStyle={styles.closeIconStyle} />
            <p className={styles.infoTextStyle}>
              Reminder event never ends if you didn’t pick an end date.
            </p>
          </div>
          <CheckBox
            title="Remove end date"
            checked={endDateCheckBoxState}
            onChange={() => {
              setEndDateCheckBoxState(true);
              formik.setFieldValue('end_date', '');
            }}
            boxStyle={styles.checkBoxStyle}
            titleStyle={styles.checkBoxTitleStyle}
          />
        </div>
      </div>
    );
  };

  const renderRepeatPopup = () => {
    return (
      <PopOver
        reference={repeatRef}
        show={showRepeatTypePopUp}
        onClose={() => {
          setShowRepeatTypePopUp(false);
        }}
        containerStyle={styles.repeatPopUpWrapperStyle}
      >
        <div className={styles.timerPopUpWrapperStyle}>
          {repeatTypes?.map((item, index) => {
            return (
              <p
                key={index}
                className={styles.selectTimeTextStyle}
                onClick={() => {
                  formik.setFieldValue('repeat_interval.type', item);
                  setShowRepeatTypePopUp(false);
                }}
              >
                {item}
              </p>
            );
          })}
        </div>
      </PopOver>
    );
  };

  const renderBtns = () => {
    return (
      <div className={styles.btnsAndMsgSectionStyle}>
        <p className={styles.errorTextStyle}>{errorMessage}</p>
        <div className={styles.btnSectionStyle}>
          <Button
            title={strings.cancel}
            variant="light"
            size="md"
            onClick={onclose}
            disabled={loading}
          />
          <Button
            title={type === reminderActionTypes.add ? strings.create : strings.update}
            size="md"
            type="submit"
            disabled={
              loading ||
              disableCreate ||
              !formik.values.title ||
              !formik.values.start_date ||
              isValuesChanged
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.modalContainerStyle}>
      {loading && <Loader />}
      {headerSection()}
      <form onSubmit={formik.handleSubmit} className={styles.formStyles}>
        {renderInputsSection()}
        {renderBtns()}
      </form>
      {renderTimerPopup()}
      {renderRepeatPopup()}
    </div>
  );
};

AddEditReminder.propTypes = {
  type: PropTypes.oneOf([reminderActionTypes.add, reminderActionTypes.edit]),
  handleWithApi: PropTypes.bool,
  onclose: PropTypes.func,
  reminder: PropTypes.object,
  setReminder: PropTypes.func,
};
export default AddEditReminder;
