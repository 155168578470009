import axios from 'utils/axios';
import * as endPoints from 'networking/endpoints';

export const createCompanyApi = async (data) =>
  Promise.resolve(axios.post(endPoints.createCompany, data));

export const getAllCompaniesListApi = async () =>
  Promise.resolve(axios.get(endPoints.getAllCompanies));

export const getStatisticsCountApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getStatisticsCount + `?company=${id}`));
