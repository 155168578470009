function path(root, sublink) {
  return `${root}${sublink}`;
}

const compnay_root = '/company';
const asset_root = '/asset';
const employee_root = '/employee';
const ticket_root = '/ticket';
const cluster_root = '/maintenance-cluster';

const routePaths = {
  login: '/login',
  signup: '/register',

  dashboard: '/dashboard',
  createCompany: path(compnay_root, '/create'),
  companyList: path(compnay_root, '/list'),

  assetList: path(asset_root, '/list'),
  createAsset: path(asset_root, '/create'),
  assetDetails: (id) => path(asset_root, `/details/${id}`),

  employeeList: path(employee_root, '/list'),
  createEmployee: path(employee_root, '/create'),
  employeeDetails: (id) => path(employee_root, `/details/${id}`),

  ticketList: path(ticket_root, '/list'),
  raiseTicket: path(ticket_root, '/raise'),

  clusterList: path(cluster_root, '/list'),
  createCluster: path(cluster_root, '/create'),

  lostAndFound: '/lost-and-found',
  scanner: '/scan',
  guestLogin: '/email-required',
  returnItem: '/return-item',
  raiseTicketThroughQr: '/raise-ticket-through-qr',
};

export default routePaths;
