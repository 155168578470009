import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'components/common/button';
import Loader from 'components/common/loader';
import styles from './styles.module.css';

const SuccessCard = (props) => {
  const {
    title,
    description,
    leftBtnTitle,
    leftBtnVariant,
    rightBtnTitle,
    rightBtnVariant,
    leftAction = () => {},
    rightAction = () => {},
    cardStyle,
    titleStyle,
    descriptionStyle,
    cardBtnStyle,
    rightBtnStyle,
    leftBtnStyle,
    isLoader,
  } = props;

  return (
    <div className={classNames(styles.wrapperStyle, cardStyle)}>
      {isLoader && <Loader />}
      <div className={styles.textWrapperStyle}>
        <h5 className={classNames(styles.titleStyle, titleStyle)}>{title}</h5>
        <p className={classNames(styles.descStyle, descriptionStyle)}>{description}</p>
      </div>
      <div className={classNames(styles.cardBtnStyle, cardBtnStyle)}>
        {leftBtnTitle && (
          <Button
            onClick={leftAction}
            title={leftBtnTitle}
            variant={leftBtnVariant || 'primary'}
            btnStyle={classNames(styles.customBtnStyle, leftBtnStyle)}
            size="md"
          />
        )}
        {rightBtnTitle && (
          <Button
            title={rightBtnTitle}
            variant={rightBtnVariant || 'light'}
            onClick={rightAction}
            btnStyle={classNames(styles.customBtnStyle, rightBtnStyle)}
            size="md"
          />
        )}
      </div>
    </div>
  );
};

SuccessCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  leftBtnTitle: PropTypes.string,
  leftBtnVariant: PropTypes.string,
  rightBtnTitle: PropTypes.string,
  rightBtnVariant: PropTypes.string,
  cardStyle: PropTypes.string,
  titleStyle: PropTypes.string,
  descriptionStyle: PropTypes.string,
  cardBtnStyle: PropTypes.string,
  leftBtnStyle: PropTypes.string,
  leftAction: PropTypes.func,
  rightAction: PropTypes.func,
  rightBtnStyle: PropTypes.string,
  isLoader: PropTypes.bool,
};

export default SuccessCard;
