import axios from 'utils/axios';
import * as endPoints from 'networking/endpoints';

export const assetCreateApi = async (data) =>
  Promise.resolve(axios.post(endPoints.createAsset, data));

export const assetDetailsApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getAssetDetails.replace('{id}', id)));

export const assetListApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getAllAssets + `?company=${id}`));

export const assetUpdateApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateAsset, data));

export const assetAssignApi = async (data) =>
  Promise.resolve(axios.post(endPoints.assignAsset, data));

export const assetDeleteApi = async (id) =>
  Promise.resolve(axios.delete(endPoints.deleteAsset.replace('{id}', id)));

export const assetUnAssignApi = async (id) =>
  Promise.resolve(axios.put(endPoints.assetUnAssign.replace('{id}', id)));

export const assetAssignToOthersApi = async (data) =>
  Promise.resolve(axios.post(endPoints.assignAssetToOthers, data));

export const getAssetReminderListApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getAssetRemindersList.replace('{id}', id)));

export const addReminderApi = async (data) =>
  Promise.resolve(axios.post(endPoints.addReminder, data));

export const updateReminderApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateReminder, data));

export const deleteReminderApi = async (id) =>
  Promise.resolve(axios.delete(endPoints.deleteReminder.replace('{id}', id)));

export const uploadAssetXlsFileApi = async (data) =>
  Promise.resolve(axios.post(endPoints.uploadAssetXlsFile, data));

export const markAsLostApi = async (id) =>
  Promise.resolve(axios.put(endPoints.markAsLost.replace('{id}', id)));

export const getAssetByQRApi = async (data) =>
  Promise.resolve(axios.post(endPoints.getAssetDetailsByQr, data));

export const getAssetCompletedRemindersApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getAssetCompletedReminders + `?company=${id}`));

export const getAssetsByPageApi = async (id, pageNumber, searchQuery, limit, sortBy, sortOrder) => {
  let data = new URLSearchParams();
  if (id) data.append('company', id);
  if (pageNumber) data.append('page', pageNumber);
  if (searchQuery) data.append('search', searchQuery);
  if (limit) data.append('limit', limit);
  if (sortBy) data.append('sortBy', sortBy);
  if (sortOrder) data.append('sortOrder', sortOrder);
  return Promise.resolve(axios.get(endPoints.getAllAssets + `?${data.toString()}`));
};

export const getAssetsWithSearchApi = async (id, searchQuery) => {
  let data = new URLSearchParams();
  if (id) data.append('company', id);
  if (searchQuery) data.append('search', searchQuery);
  return Promise.resolve(axios.get(endPoints.getAssetsWithSearch + `?${data.toString()}`));
};

export const getAssetTicketsApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getAssetTickets.replace('{id}', id)));

export const getAssetClustersApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getAssetClusters.replace('{id}', id)));
