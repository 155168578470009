import React, { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useStrings } from 'providers/stringsprovider';
import { useAuth } from 'providers/authprovider';
import { avatar1Icon, closeCircleFillGrayIcon } from 'resources/images';
import Button from 'components/common/button';
import AvatarInfoCard from 'components/common/avatar-info-card';
import PopOver from 'components/common/pop-over';
import Input from 'components/common/input';
import Avatar from 'components/common/avatar';
import Image from 'components/common/image';
import Loader from 'components/common/loader';
import { getAllEmployeesWithLastAssetApi } from 'networking/api/employee';
import { useUserData } from 'providers/userdataprovider';
import styles from './styles.module.css';
import InfiniteScroll from 'components/infinite-scroll';

const AssignAssetCard = (props) => {
  const { asset, onClick = () => {}, onCancel = () => {}, setUser, isLoader } = props;
  const { strings } = useStrings();
  const { user, company } = useAuth();
  const { users, setUsers } = useUserData();
  const [userListRef, setUserListRef] = useState();
  const [usersLoading, setUsersLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDropDownList, setShowDropDownList] = useState(false);
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    if (users) {
      setUsersList(
        asset.assigned_to
          ? users?.filter((item) => asset.assigned_to._id !== item._id && item._id !== user._id)
          : users?.filter((item) => item._id !== user._id)
      );
    }
  }, [user, users, asset]);

  useEffect(() => {
    if (selectedUser) {
      setUser(selectedUser._id);
    }
  }, [selectedUser, setUser]);

  const debouncedSearch = useMemo(
    () =>
      debounce((searchValue) => {
        setDebouncedQuery(searchValue);
        setCurrentPage(1);
      }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(query);
  }, [query, debouncedSearch]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        if (!debouncedQuery) {
          setUsers([]);
          return;
        }
        if (currentPage === 1) {
          setUsers([]);
        }
        setUsersLoading(true);
        const response = await getAllEmployeesWithLastAssetApi(
          company?._id,
          currentPage,
          debouncedQuery,
          10
        );
        setUsers((prev) => {
          if (currentPage === 1) {
            return response.data.data.users;
          } else {
            return [...prev, ...response.data.data.users];
          }
        });
        setHasMore(response.data.data?.meta?.hasNextPage);
        setUsersLoading(false);
      } catch (err) {
        setUsersLoading(false);
      }
    };
    getUsers();
  }, [debouncedQuery, currentPage, company, setUsers]);

  // ui section

  const renderSelectUserSection = () => {
    return (
      <div className={styles.assignToViewStyles}>
        <p className={styles.assignToLabelTextStyles}>
          {asset?.assigned_to ? strings.assignToDiffUser : strings.assignToRequired}
        </p>
        <div className={styles.inviteUserWrapperStyle}>
          {selectedUser ? (
            <div className={styles.selectedUserWrapperStyle}>
              <Image
                src={closeCircleFillGrayIcon}
                containerStyle={styles.assignedUserRemoveIconStyle}
                onClick={() => setSelectedUser(null)}
              />
              <div className={styles.selectedProfileWrapperStyle}>
                <Avatar src={selectedUser.image || avatar1Icon} />
                <div className={styles.profileTextWrapperStyle}>
                  <p className={styles.profileTitleStyle}>{selectedUser.full_name}</p>
                  <p className={styles.profileDescStyle}>{selectedUser.email}</p>
                </div>
              </div>
            </div>
          ) : (
            <React.Fragment>
              <div ref={setUserListRef} className={styles.assignUserInputWrapperStyle}>
                <Input
                  placeholder={strings.typeEmailOrEmployeeName}
                  inputStyle={styles.assignUserInputStyle}
                  value={query}
                  onChange={(e) => {
                    setQuery(e.target.value);
                    setShowDropDownList(true);
                  }}
                />
              </div>
              <PopOver
                reference={userListRef}
                show={showDropDownList}
                onClose={() => setShowDropDownList(false)}
                containerStyle={styles.dropDownWrapperStyle}
              >
                <InfiniteScroll
                  data={usersList.filter((item) => item._id !== asset?.assigned_to?._id)}
                  loading={usersLoading}
                  hasMore={hasMore}
                  setCurrentPage={setCurrentPage}
                >
                  {usersLoading || usersList.length > 0 ? (
                    <React.Fragment>
                      {usersList
                        .filter((item) => item._id !== asset?.assigned_to?._id)
                        .map((user, index) => (
                          <div
                            className={styles.profileWrapperStyle}
                            key={'user' + index}
                            onClick={() => {
                              setSelectedUser(user);
                              setQuery('');
                            }}
                          >
                            <Avatar src={avatar1Icon} />
                            <div className={styles.profileTextWrapperStyle}>
                              <p className={styles.profileTitleStyle}>{user.full_name}</p>
                              <p className={styles.profileDescStyle}>{user.email}</p>
                            </div>
                          </div>
                        ))}
                      {usersLoading && <Loader containerStyle={styles.loaderContainerStyle} />}
                    </React.Fragment>
                  ) : (
                    <p className={styles.noDataStyle}>No data available</p>
                  )}
                </InfiniteScroll>
              </PopOver>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  const renderBtns = () => {
    return (
      <div className={styles.btnsWrapperStyle}>
        <Button
          title="Cancel"
          variant="light"
          size="md"
          onClick={onCancel}
          btnStyle={styles.btnStyle}
        />
        <Button
          title="Confirm"
          size="md"
          onClick={onClick}
          btnStyle={styles.btnStyle}
          disabled={selectedUser === null ? true : false}
        />
      </div>
    );
  };

  return (
    <div className={styles.containerStyle}>
      {isLoader && <Loader />}
      <div className={styles.assetStyles}>
        <p className={styles.assetTextStyles}>
          {asset.asset_uid}: {asset.asset_name}
        </p>
      </div>
      <div className={styles.subContainerStyle}>
        <div className={styles.titleViewStyle}>
          <p className={styles.titleTextStyles}>
            {asset?.assigned_to ? strings.assignAssetDifferntUser : strings.assignAsset}
          </p>
          <p className={styles.descpTextStyles}>
            {asset?.assigned_to ? strings.note : strings.selectRegisteruser}
          </p>
        </div>
        {/* // currently assigned view */}
        {asset?.assigned_to && (
          <div className={styles.currentlyAssignedViewStyles}>
            <p className={styles.currentlyAssignedlabelStyles}>{strings.currentlyAssignLabel}</p>
            <div className={styles.userDetailsStyles}>
              <AvatarInfoCard
                icon={asset.assigned_to?.image}
                title={asset?.assigned_to.full_name}
                description={asset?.assigned_to.email}
                containerStyle={styles.avatarInfoCardStyle}
                accountType={asset.assigned_to.account_type}
              />
            </div>
          </div>
        )}
        {/* // select user */}
        {renderSelectUserSection()}
        {renderBtns()}
      </div>
    </div>
  );
};

AssignAssetCard.propTypes = {
  onClick: PropTypes.func,
  onCancel: PropTypes.func,
  asset: PropTypes.object,
  isLoader: PropTypes.bool,
};

export default AssignAssetCard;
