import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const StatisticsCard = (props) => {
  const { count, title, containerStyle, countStyle, titleStyle, onClick } = props;

  return (
    <div
      className={[
        styles.statisticCardWrapperStyle,
        containerStyle,
        onClick ? styles.cursorStyle : '',
      ].join(' ')}
      onClick={onClick}
    >
      <p className={[styles.statsCountStyle, countStyle].join(' ')}>
        {count ? count.toString().padStart(2, 0) : 'NA'}
      </p>
      <p className={[styles.statsTitleStyle, titleStyle].join(' ')}>{title}</p>
    </div>
  );
};

StatisticsCard.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  containerStyle: PropTypes.string,
  countStyle: PropTypes.string,
  titleStyle: PropTypes.string,
  onClick: PropTypes.func,
};

export default StatisticsCard;
