import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as PopperJS from '@popperjs/core';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { closeLightGrayIcon } from 'resources/images';
import PopOver from 'components/common/pop-over';
import Input from 'components/common/input';
import SelectedCard from 'components/common/selected-card';
import Avatar from 'components/common/avatar';
import Loader from 'components/common/loader';
import styles from './styles.module.css';
import InfiniteScroll from 'components/infinite-scroll';

const MultiSelectInput = (props) => {
  const {
    inputLabel,
    placeholder,
    setSelectedData,
    selectedData,
    options = [],
    optionsPlacement,
    type,
    image,
    customMultiSelectContsinerStyle,
    customMultiSelectInputStyle,
    disabledInput,
    eachOptionViewStyle,
    multiInputStyle,
    optionsModalViewStyle,
    multi = false,
    searchText, // <-- Now passed as a prop
    setSearchText = () => {},
    dataLoading = false,
    setCurrentPage,
    hasMore,
  } = props;
  const { width } = useWindowDimensions();
  const [showOptions, setShowOptions] = useState(false);
  const [anchorElement, setAnchorElement] = useState();
  const [filteredData, setFilteredOptions] = useState(options || []);

  useEffect(() => {
    setFilteredOptions(
      [...options].filter((opt) => !selectedData?.some((sa) => sa.value === opt.value))
    );
  }, [options, selectedData]);

  useEffect(() => {
    if (searchText.length > 0) {
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  }, [searchText]);

  const selectOption = (option) => {
    if (multi) {
      const isSelected = selectedData.find((item) => item.value === option.value);
      if (isSelected) {
        setSelectedData(selectedData.filter((item) => item.value !== option.value));
      } else {
        setSelectedData([...selectedData, option]);
      }
    } else {
      setSearchText('');
      setShowOptions(false);
      setSelectedData([option]);
    }
  };

  const removeSelectedOptions = (option) => {
    const data = [...selectedData];
    let filteredData = data.filter((item) => item.value !== option.value);
    setSelectedData(filteredData);
  };

  const isPresent = (data) => {
    return selectedData?.find((item) => item.value === data.value);
  };

  const renderOptionsPopUp = () => {
    return (
      <PopOver
        show={showOptions}
        showOverlay={false}
        reference={anchorElement}
        placement={width > 480 ? 'bottom-start' : optionsPlacement ? optionsPlacement : 'top-start'}
        onClose={() => setShowOptions(false)}
        containerStyle={classNames(styles.optionsModalViewStyle, optionsModalViewStyle)}
      >
        <div className={styles.optionsViewStyle}>
          <InfiniteScroll
            data={filteredData}
            hasMore={hasMore}
            loading={dataLoading}
            setCurrentPage={setCurrentPage}
          >
            {dataLoading || filteredData?.length > 0 ? (
              <React.Fragment>
                {filteredData?.map((item, index) => (
                  <div
                    key={'#' + index}
                    onClick={() => selectOption(item)}
                    className={classNames(
                      styles.eachOptionViewStyle,
                      isPresent(item) && styles.selectedOptionViewStyle,
                      eachOptionViewStyle
                    )}
                  >
                    <Avatar
                      src={image}
                      label={item.label}
                      containerStyle={styles.optionImageViewStyle}
                    />
                    <div className={styles.optionDetailsViewStyle}>
                      <p className={styles.optionTitleTextStyle}>
                        {`${item.label} ${item?.desig ? `(${item.desig})` : ''}`}
                      </p>
                      <p className={styles.optionDescTextStyle}>{item.desc}</p>
                    </div>
                  </div>
                ))}
                {dataLoading && <Loader containerStyle={styles.loaderContainerStyle} />}
              </React.Fragment>
            ) : (
              <div className={styles.emptyDataViewStyle}>
                <p className={styles.optionDescTextStyle}>No data available</p>
              </div>
            )}
          </InfiniteScroll>
        </div>
      </PopOver>
    );
  };

  return (
    <div className={classNames(styles.multiSelectContsinerStyle, customMultiSelectContsinerStyle)}>
      {inputLabel && <p className={styles.lableStyle}>{inputLabel}</p>}
      <div
        className={classNames(
          styles.multiSelectInputStyle,
          selectedData?.length > 0 && styles.multiSelectInputStyle2,
          customMultiSelectInputStyle
        )}
        ref={setAnchorElement}
      >
        {selectedData?.length > 0 && (
          <div className={styles.selectedOptionsViewStyle}>
            {selectedData.map((data, index) => (
              <SelectedCard
                key={index}
                title={data.label}
                icon={closeLightGrayIcon}
                removeItem={() => removeSelectedOptions(data)}
                containerStyle={
                  type === 'asset' ? styles.selectedCardViewStyle : styles.selectedCardViewStyle2
                }
                iconStyle={styles.iconStyle}
              />
            ))}
          </div>
        )}

        <Input
          placeholder={placeholder}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          inputStyle={classNames(styles.inputCustomStyle, multiInputStyle)}
          // onFocus={(e) => {
          //   setShowOptions(true);
          // }}
          disabled={disabledInput}
        />
        {renderOptionsPopUp()}
      </div>
    </div>
  );
};

MultiSelectInput.propTypes = {
  options: PropTypes.array.isRequired,
  inputLabel: PropTypes.string,
  placeholder: PropTypes.string,
  setSelectedData: PropTypes.func,
  selectedData: PropTypes.array,
  optionsPlacement: PropTypes.string,
  type: PropTypes.oneOf(['assets', 'users']),
  image: PropTypes.string,
  customMultiSelectContsinerStyle: PropTypes.string,
  customMultiSelectInputStyle: PropTypes.string,
  multiInputStyle: PropTypes.string,
  disabledInput: PropTypes.bool,
  eachOptionViewStyle: PropTypes.string,
  optionsModalViewStyle: PropTypes.oneOf(PopperJS.placements),
  multi: PropTypes.bool,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  dataLoading: PropTypes.bool,
  setCurrentPage: PropTypes.func,
  hasMore: PropTypes.bool,
};

export default MultiSelectInput;
