import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/button';
import Image from 'components/common/image';
import {
  assetIcon,
  calendarIcon,
  checkBlueIcon,
  chevronDownFillGrayIcon,
  closeIcon,
  deleteIcon,
  listAddBlueIcon,
} from 'resources/images';
import Input from 'components/common/input';
import DatePicker from 'components/common/date-picker';
import { useStrings } from 'providers/stringsprovider';
import ImagePreview from 'components/common/image-preview';
import { uploadImageApi } from 'networking/api/upload';
import PopOver from 'components/common/pop-over';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { assetUpdateApi } from 'networking/api/asset';
import Loader from 'components/common/loader';
import { isMatch } from 'lodash';
import { useToast } from 'providers/toastprovider';
import styles from './styles.module.css';

const EditAsset = (props) => {
  const { asset, onCancel = () => {}, onSuccess = () => {} } = props;
  const { strings } = useStrings();
  const toast = useToast();
  const default_categories = useMemo(
    () => [strings.laptop, strings.handBag, strings.mobile, strings.tab],
    [strings.laptop, strings.handBag, strings.mobile, strings.tab]
  );
  const [selctedFile, setSelectedFile] = useState(null);
  const [showUploadPreview, setShowUploadPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [assetTypesRef, setAssetTypesRef] = useState(null);
  const [isShowAssetCategories, setIsShowAssetCategories] = useState(false);
  const [customTypeRef, setCustomTypeRef] = useState(null);
  const [isShowCustomTypeInput, setIsShowCustomTypeInput] = useState(false);
  const [categories, setCategories] = useState(default_categories);
  const [newCategory, setNewCategory] = useState('');
  const fileInputRef = useRef();
  const [apiError, setApiError] = useState('');
  const [isValuesChanged, setIsValuesChanged] = useState(false);
  const schemaObj = {
    asset_name: Yup.string().required(strings.assetNameRequired),
    asset_category: Yup.string().required(strings.assetcategoryRequired),
  };
  const assetSchema = Yup.object().shape(schemaObj);
  const formik = useFormik({
    initialValues: {
      asset_name: asset?.asset_name || '',
      asset_category: asset?.asset_category || '',
      image: asset?.image || '',
      qr_id: asset?.qr_id || '',
      purchased_on: asset?.purchased_on || '',
      description: asset?.description || '',
      asset_id: asset._id,
      attachments: asset?.attachments.map((item) => item._id),
    },
    validationSchema: assetSchema,
    onSubmit: handleAssetUpdate,
  });

  useEffect(() => {
    let new_categories = getCategories();
    if (new_categories && new_categories?.length > 0 && Array.isArray(new_categories)) {
      setCategories([...default_categories, ...new_categories]);
    }
  }, [default_categories]);

  useEffect(() => {
    setIsValuesChanged(
      isMatch(
        {
          asset_name: asset?.asset_name || '',
          asset_category: asset?.asset_category || '',
          image: asset?.image || '',
          qr_id: asset?.qr_id || '',
          purchased_on: asset?.purchased_on || '',
          description: asset?.description || '',
          asset_id: asset._id,
          attachments: asset?.attachments.map((item) => item._id),
        },
        formik.values
      )
    );
  }, [formik.values, asset, setIsValuesChanged]);

  async function handleAssetUpdate(values, { resetForm }) {
    setIsLoading(true);
    try {
      const response = await assetUpdateApi(values);
      setIsLoading(false);
      onSuccess(response.data.data);
      toast.success(response.data.message);
    } catch (error) {
      setIsLoading(false);
      setApiError(error.message);
    }
  }

  const getCategories = () => {
    let new_categories = localStorage.getItem('ASSET_CAT');
    let new_parsed_categories = JSON.parse(new_categories);
    return new_parsed_categories || [];
  };

  const handleNewCategory = () => {
    let old_categories = getCategories();
    let data = old_categories?.length > 0 ? old_categories : [];
    let existing = data.find((item) => item === newCategory);
    if (!existing) {
      data.push(newCategory);
    }
    localStorage.setItem('ASSET_CAT', JSON.stringify(data));
    getCategories();
    setIsShowCustomTypeInput(false);
    formik.setFieldValue('asset_category', newCategory);
    setNewCategory('');
  };

  const handleImageUploadSelection = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    // setAssetData({ ...assetData, image: URL.createObjectURL(file) });
    formik.setFieldValue('image', URL.createObjectURL(file));
    setShowUploadPreview(true);
    // handleUploadTicketSolvedImg(file);
  };

  const handleRemoveImage = () => {
    formik.setFieldValue('image', '');
    setSelectedFile(null);
    fileInputRef.current.value = '';
  };

  const handleUploadImg = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('image', selctedFile);
      const response = await uploadImageApi(formData);
      formik.setFieldValue('image', response.data.data);
      setIsLoading(false);
      setShowUploadPreview(false);
      // onSuccess(response.data.data);
    } catch (error) {
      formik.setFieldValue('image', '');
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  const handleUploadImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // ui section start

  const renderHeaderSection = () => {
    return (
      <div className={styles.headerStyle}>
        <p className={styles.headerTextStyle}>Edit details</p>
        <div className={styles.headerBtnsViewStyle}>
          <Button title="Cancel" variant="light" size="md" onClick={onCancel} />
          <Button
            type={'submit'}
            title="Save changes"
            size="md"
            disabled={isValuesChanged || isLoading}
          />
        </div>
        <Image src={closeIcon} onClick={onCancel} containerStyle={styles.closeIconStyle} />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={styles.contentWrapperStyle}>
        <p className={styles.textStyle}>Asset info</p>
        <div className={styles.contentSubWrapperStyle}>
          {renderImageSection()}
          {renderInputsSection()}
          {renderMobileViewBtns()}
        </div>
      </div>
    );
  };

  const renderImageSection = () => {
    return (
      <div className={styles.assetImageWrapperStyle}>
        <Image
          src={formik.values.image || assetIcon}
          containerStyle={styles.assetImageViewStyle}
          imgStyle={styles.assetStyle}
        />
        <div className={styles.assetImageDescViewStyle}>
          <p className={styles.assetImgDescTextStyle}>
            Supports PNG/JPG/JPEG/HEIF formats. Minimum resolution 500x500px. Maximum size 10MB.
          </p>
          <div className={styles.uploadBtnViewStyle}>
            <input
              type="file"
              accept="image/jpeg, image/jpg, image/png"
              placeholder="Upload an image"
              ref={fileInputRef}
              onChange={handleImageUploadSelection}
              hidden
            />
            <Button
              title="Choose image"
              btnStyle={styles.uploadBtnStyle}
              variant="light"
              size="md"
              onClick={() => handleUploadImageClick()}
            />
            <Button
              leftIcon={deleteIcon}
              variant="light"
              size="md"
              onClick={() => formik.setFieldValue('image', '')}
              btnStyle={styles.deleteIconViewStyle}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderInputsSection = () => {
    return (
      <div className={styles.inputsWrapperStyle}>
        <div className={styles.inputSubWrapperStyle}>
          <Input
            labelText={'Serial no. (auto allotted)'}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={'Enter serial no.'}
            value={asset?.asset_uid}
            disabled={true}
            inputStyle={styles.disableInputStyle}
          />
          <Input
            name="asset_name"
            labelText={strings.assetLabelNameRequired}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={strings.assetTypeItem}
            value={formik.values.asset_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.asset_name && formik.errors.asset_name ? formik.errors.asset_name : ''
            }
          />
        </div>
        <div className={styles.inputSubWrapperStyle}>
          <DatePicker
            labelText={strings.purchaseDateRequired}
            labelTextStyle={styles.lableTextStyle}
            placeholder="DD-MM-YYYY"
            format="DD MMMM YYYY"
            icon={calendarIcon}
            maxDate={new Date()}
            onChange={(date) => formik.setFieldValue('purchased_on', date)}
            containerStyle={styles.datePickerStyle}
            iconStyle={styles.dateIconStyle}
            valueStyle={
              formik.values.purchased_on ? styles.dateValueStyle : styles.datePlaceholdereStyle
            }
            value={formik.values.purchased_on}
          />
          <Input
            name="qr_id"
            labelText={'Unique identification code'}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={'Enter unique code on QR sticker'}
            value={formik.values.qr_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={formik.touched.qr_id && formik.errors.qr_id ? formik.errors.qr_id : ''}
          />
        </div>
        <div className={styles.inputSubWrapperStyle}>
          <Input
            type="textarea"
            name="description"
            labelText={strings.descriptionOptional}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={strings.enterAssetDescription}
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.description && formik.errors.description
                ? formik.errors.description
                : ''
            }
          />
        </div>
        <div className={styles.selectTypeInputContainerStyle}>
          <p className={styles.lableTextStyle}>Asset category*</p>
          <div
            className={styles.selectTypeContainerStyle}
            onClick={() => {
              setIsShowAssetCategories(true);
            }}
            ref={setAssetTypesRef}
          >
            <p
              className={
                formik.values.asset_category ? styles.valueTextStyle : styles.placeHolderTextStyle
              }
            >
              {formik.values.asset_category ? formik.values.asset_category : strings.selectEmpROle}
            </p>
            <Image src={chevronDownFillGrayIcon} containerStyle={styles.arrowDownIconStyle} />
          </div>
        </div>
        {apiError && <p className={styles.errorTextStyle}>{apiError}</p>}
      </div>
    );
  };

  const renderAssetTypesModal = () => {
    return (
      <PopOver
        show={isShowAssetCategories}
        reference={assetTypesRef}
        onClose={() => {
          if (!isShowCustomTypeInput) {
            setIsShowAssetCategories(false);
          }
        }}
        containerStyle={styles.actionPopUpViewStyle}
        placement="bottom-end"
        offset={[0, -44]}
      >
        <div className={styles.actionOptionsViewStyle}>
          {categories?.map((item, index) => {
            return (
              <div className={styles.optiondTextWrapperStyle} key={index}>
                <p
                  onClick={() => {
                    formik.setFieldValue('asset_category', item);
                    setIsShowAssetCategories(false);
                  }}
                  className={classNames(styles.optionsViewStyles)}
                >
                  {item}
                </p>
                {formik.values.asset_category === item && (
                  <Image src={checkBlueIcon} containerStyle={styles.checkIconStyle} />
                )}
              </div>
            );
          })}
        </div>
        <div
          className={styles.customTypeContainerStyle}
          onClick={() => setIsShowCustomTypeInput(true)}
          ref={setCustomTypeRef}
        >
          <Image src={listAddBlueIcon} containerStyle={styles.listIconStyle} />
          <p className={styles.customOptionsViewStyles}>Add custom category</p>
        </div>
      </PopOver>
    );
  };

  const renderCustomAssetCategories = () => {
    return (
      <PopOver
        show={isShowCustomTypeInput}
        reference={customTypeRef}
        onClose={() => setIsShowCustomTypeInput(false)}
        containerStyle={styles.inputPopUpViewStyle}
        showOverlay
        overlayStyle={styles.overlayStyle}
      >
        <div className={styles.customEmployeeViewStyle}>
          <Input
            name="asset_category"
            labelText={strings.addCustomCategory}
            inputLabelStyle={styles.lableTextStyle}
            placeholder={strings.assetCategoryEx}
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            containerStyle={styles.inputStyle}
          />
          <div className={styles.popUpBtnContainerStyle}>
            <Button
              title="Cancel"
              size="md"
              variant="light"
              onClick={() => {
                setIsShowCustomTypeInput(false);
                setNewCategory('');
              }}
            />
            <Button
              title="Save entry"
              size="md"
              disabled={newCategory !== '' ? false : true}
              onClick={() => handleNewCategory()}
            />
          </div>
        </div>
      </PopOver>
    );
  };

  const renderMobileViewBtns = () => {
    return (
      <div className={styles.mobileViewBtnsViewStyle}>
        <Button title="Cancel" variant="light" size="md" onClick={onCancel} />
        <Button type={'submit'} title="Save changes" size="md" />
      </div>
    );
  };

  return (
    <form onSubmit={formik.handleSubmit} className={styles.containerStyle}>
      {isLoading && <Loader />}
      {renderHeaderSection()}
      {renderContent()}
      {showUploadPreview && (
        <ImagePreview
          src={formik.values.image}
          onClose={() => {
            handleRemoveImage();
            setShowUploadPreview(false);
          }}
          onDone={handleUploadImg}
          isLoading={isLoading}
        />
      )}
      {renderAssetTypesModal()}
      {renderCustomAssetCategories()}
    </form>
  );
};

EditAsset.propTypes = {
  asset: PropTypes.object,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default EditAsset;
