import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const SuggestionCard = (props) => {
  const { title, description, cardStyle, titleStyle, descriptionStyle, children } = props;

  return (
    <div className={classNames(styles.wrapperStyle, cardStyle)}>
      <div className={styles.cardInfoStyle}>
        <h5 className={classNames(styles.titleStyle, titleStyle)}>{title}</h5>
        <p className={classNames(styles.descStyle, descriptionStyle)}>{description}</p>
      </div>
      {children && children}
    </div>
  );
};

SuggestionCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  cardStyle: PropTypes.string,
  titleStyle: PropTypes.string,
  descriptionStyle: PropTypes.string,
};

export default SuggestionCard;
