import axios from 'utils/axios';
import * as endPoints from 'networking/endpoints';

export const createClusterApi = async (data) =>
  Promise.resolve(axios.post(endPoints.createCluster, data));

export const getClustersApi = async (id, pageNumber, searchQuery, limit, sortBy, sortOrder) => {
  let data = new URLSearchParams();
  if (id) data.append('company', id);
  if (pageNumber) data.append('page', pageNumber);
  if (searchQuery) data.append('search', searchQuery);
  if (limit) data.append('limit', limit);
  if (sortBy) data.append('sortBy', sortBy);
  if (sortOrder) data.append('sortOrder', sortOrder);
  return Promise.resolve(axios.get(endPoints.getClusters + `?${data.toString()}`));
};

export const getSelectedClustersDetailsApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getClusterDetails.replace('{id}', id)));

export const updatedChecklistCheckedStatusApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updatedChecklistCheckedStatus, data));

export const updateAssetEntireStateApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateAssetEntireState, data));

export const raiseComplaintApi = async (data) =>
  Promise.resolve(axios.post(endPoints.raiseComplaint, data));

export const saveTemplateApi = async (data) =>
  Promise.resolve(axios.post(endPoints.saveTemplate, data));

export const getAllTemplatesApi = async (id, type) => {
  let url = '';
  if (type) {
    url = `?company=${id}&type=${type}`;
  } else {
    url = `?company=${id}`;
  }
  return Promise.resolve(axios.get(endPoints.getAllTemplate + url));
};

export const postClusterCommentApi = async (data) =>
  Promise.resolve(axios.post(endPoints.postClusterComment, data));

export const fetchtClusterCommentsApi = async (report, asset) => {
  let query = '';
  if (asset) {
    query = `?asset=${asset}`;
  }
  return Promise.resolve(axios.get(endPoints.fetchClusterComments.replace('{id}', report) + query));
};

export const inviteClusterMembersAPi = async (data) =>
  Promise.resolve(axios.post(endPoints.inviteClusterMembers, data));

export const updateClusterAccessApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateClusterAccess, data));

export const updateClusterApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateCluster, data));

export const archiveClusterApi = async (id) =>
  Promise.resolve(axios.put(endPoints.archiveCluster.replace('{id}', id)));

export const updateAssetOnHoldStatusApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateAssetOnHoldStatus, data));

export const removeClusterMemberApi = async (data) =>
  Promise.resolve(axios.post(endPoints.removeClusterMember, data));
