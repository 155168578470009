import React, { useCallback, useEffect, useState, useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Image from 'components/common/image';
import ProgressBar from 'components/common/progress-bar';
import Chip from 'components/common/chip';
import {
  checkCircleFillBlueIcon,
  chevronDownBlackIcon,
  chevronRightBlackIcon,
  chevronUpBlackIcon,
  closeIcon,
  closeWhiteIcon,
  flashWhiteIcon,
  infoCircleFillYellowIcon,
  potHoleConeBlackIcon,
  timerGrayIcon,
} from 'resources/images';
import classNames from 'classnames';
import ClusterAssetReport from 'sections/maintenance/cluster-asset-report';
import Modal from 'components/common/modal';
import Loader from 'components/common/loader';
import Input from 'components/common/input';
import Button from 'components/common/button';
import { useAuth } from 'providers/authprovider';
import { account_types } from 'resources/data';
import QrReader from 'react-qr-reader';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useToast } from 'providers/toastprovider';
import styles from './styles.module.css';

const ClusterReport = (props) => {
  const { report, show, cluster, setCluster, selectedTab } = props;
  const { userType } = useAuth();
  const toast = useToast();
  const { width } = useWindowDimensions();
  const [showReport, setShowReport] = useState(!show);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [selectedFilterTab, setSelectedFilterTab] = useState('All assets');
  const [complaintsData, setComplaintsData] = useState({
    toal_complaints_count: 0,
    ongoing_complaints_count: 0,
    resolved_count: 0,
  });
  const [selectedAssetId, setSelectedAssetId] = useState('');
  const [seletedAssetReport, setSelectedAssetReport] = useState(null);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [verifyCode, setVerifyCode] = useState('');
  const [verifyAssetLoader, setVerifyAssetLoader] = useState(false);
  const [verifyCodeErrorMsg, setVerifyCodeErrorMsg] = useState('');
  const [showAssetReportViewModal, setShowAssetReportViewModal] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const reportFilterTabs = ['All assets', 'Checked', 'Missed', 'Complaints'];
  const currentDate = new Date();
  const scrollRef = useRef(null);
  const [verifyType, setVerifyType] = useState('');

  useEffect(() => {
    setShowReport(!show);
  }, [show]);
  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef?.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [showReport]);

  useEffect(() => {
    if (selectedFilterTab && report) {
      if (selectedFilterTab === 'All assets') {
        setFilteredAssets(report.cluster_assets);
      } else if (selectedFilterTab === 'Checked') {
        setFilteredAssets(report.cluster_assets.filter((ast) => ast.checked === true));
      } else if (selectedFilterTab === 'Missed') {
        setFilteredAssets(report.cluster_assets.filter((ast) => ast.checked !== true));
      } else {
        let data = [];
        report.cluster_assets.map((ast) => {
          if (ast?.complaints.length > 0) {
            let is_exists = data.find((item) => item.asset._id === ast.asset._id);
            if (!is_exists) {
              data.push(ast);
            }
          }
          return ast;
        });
        report.cluster_assets.map((ast) => {
          if (ast?.checklist) {
            ast.checklist.map((cl) => {
              if (cl?.items) {
                cl.items.map((cl_item) => {
                  if (cl_item?.complaint) {
                    let is_exists = data.find((item) => item.asset._id === ast.asset._id);
                    if (!is_exists) {
                      data.push(ast);
                    }
                  }
                  return cl_item;
                });
              }
              return cl;
            });
          }
          return ast;
        });
        setFilteredAssets(data);
      }
    }
  }, [report, selectedFilterTab]);

  const getComplaintsData = useCallback(() => {
    let ongoing_complaints_count = 0;
    let resolved_count = 0;
    let toal_complaints_count = 0;
    report.cluster_assets.forEach((asset) => {
      asset.checklist.forEach((list) => {
        list?.items.forEach((list_item) => {
          if (list_item?.complaint) {
            if (selectedTab === 'Ongoing') {
              toal_complaints_count++;
              if (list_item.complaint.ticket_status === 'Closed') {
                resolved_count++;
              }
            } else {
              if (
                moment(list_item?.complaint.created_at).isBetween(
                  report.start_date,
                  report.end_date
                )
              ) {
                toal_complaints_count++;
              }
              if (list_item.complaint.ticket_status === 'Closed') {
                resolved_count++;
              }
            }
            if (list_item.complaint.ticket_status !== 'Closed') {
              ongoing_complaints_count++;
            }
          }
        });
      });
      if (asset?.complaints?.length > 0) {
        asset?.complaints.forEach((complaint) => {
          if (selectedTab === 'Ongoing') {
            toal_complaints_count++;
          } else {
            if (moment(complaint.created_at).isBetween(report.start_date, report.end_date)) {
              toal_complaints_count++;
            }
          }
          if (complaint?.ticket_status === 'Closed') {
            resolved_count++;
          }
          if (complaint?.ticket_status !== 'Closed') {
            ongoing_complaints_count++;
          }
        });
      }
    });
    setComplaintsData({
      toal_complaints_count,
      ongoing_complaints_count,
      resolved_count,
    });
  }, [report, selectedTab]);

  useEffect(() => {
    if (report) {
      getComplaintsData();
    }
    // eslint-disable-next-line
  }, [JSON.stringify(report), getComplaintsData]);

  useEffect(() => {
    if (selectedAssetId && report) {
      let data = report.cluster_assets.find((ca) => ca.asset._id === selectedAssetId);
      if (data) {
        setSelectedAssetReport(data);
      }
    } else {
      setSelectedAssetReport(null);
    }
  }, [selectedAssetId, report]);

  const displayReportCloseTime = () => {
    let today_date = new Date();
    const allChecked = report.cluster_assets.every((obj) => {
      if (obj.checklist.length > 0) {
        return obj.checklist.every((item) => item.items.every((data) => data.checked === true));
      } else {
        return obj.checked;
      }
    });
    let latest = '';
    report.cluster_assets.map((item) => {
      if (latest) {
        if (!(latest > item.updated_at)) {
          latest = item.updated_at;
        }
      } else {
        latest = item.updated_at;
      }
      return item;
    });
    if (moment(report.end_date).isBefore(today_date)) {
      if (allChecked) {
        return `Done at ${moment(latest).format('Do MMM YYYY LT')}`;
      } else {
        return `Closed at ${moment(report.end_date).format('Do MMM YYYY LT')}`;
      }
    } else {
      if (allChecked) {
        return `Done at ${moment(latest).format('Do MMM YYYY LT')}`;
      } else {
        return 'Pending';
      }
    }
  };

  const getReportStateIcon = (asset) => {
    let is_checked =
      asset.checklist.length > 0
        ? asset.checklist.reduce((acc, checklist_item) => {
            return acc + checklist_item.items.filter((item) => item.checked).length;
          }, 0)
        : asset.checked;
    return asset?.on_hold
      ? potHoleConeBlackIcon
      : is_checked
      ? checkCircleFillBlueIcon
      : moment(currentDate).isBetween(report.start_date, report.end_date)
      ? timerGrayIcon
      : infoCircleFillYellowIcon;
  };

  const getAssetTotalData = (asset) => {
    let checklist_length = 0;
    let checked_list_length = 0;
    let complaints_count = 0;
    let resolved_count = 0;
    asset.checklist.forEach((list) => {
      list.items.forEach((list_item) => {
        if (
          !list_item.complaint ||
          (list_item?.complaint &&
            moment(list_item.complaint.created_at).isBetween(report.start_date, report.end_date)) ||
          (list_item?.complaint &&
            list_item?.complaint === 'Closed' &&
            moment(list_item.complaint.updated_at).isBetween(report.start_date, report.end_date))
        ) {
          checklist_length += 1;
          if (list_item.checked) {
            checked_list_length += 1;
          }
        }
        if (list_item?.complaint) {
          complaints_count += 1;
          if (list_item.complaint.ticket_status === 'Closed') {
            resolved_count += 1;
          }
        }
      });
    });
    if (asset?.complaints?.length > 0) {
      asset?.complaints.forEach((com) => {
        complaints_count++;
        if (com?.ticket_status === 'Closed') {
          resolved_count++;
        }
      });
    }
    return {
      checklist_length,
      checked_list_length,
      complaints_count,
      resolved_count,
    };
  };

  const getReportCheckedCount = () => {
    let count = 0;
    report?.cluster_assets?.forEach((item) => {
      if (
        item?.on_hold &&
        moment(item.on_hold).isBetween(report.start_date, report.end_date) &&
        item.checked
      ) {
        count += 1;
      } else {
        if (item.checked === true) {
          count += 1;
        }
      }
    });
    return count;
  };

  const getReportAssetCount = () => {
    let count = 0;
    report?.cluster_assets?.forEach((item) => {
      if (item?.on_hold && moment(item.on_hold).isBetween(report.start_date, report.end_date)) {
        count += 1;
      } else {
        count += 1;
      }
    });
    return count;
  };

  const displayAssetReportDescrption = (asset) => {
    let data = getAssetTotalData(asset);
    if (moment(currentDate).isBetween(report.start_date, report.end_date)) {
      if (asset.checklist.length > 0) {
        if (data.checked_list_length === 0) {
          return asset?.on_hold
            ? `On-hold from ${moment(asset.on_hold).format('MMM D')}, until resolved`
            : `Check-up pending ...`;
        } else {
          if (data.resolved_count > 0) {
            return data.complaints_count > 0
              ? `${data.resolved_count} of ${data.complaints_count} ${
                  data.complaints_count > 1 ? ' Complaints' : ' Complaint'
                } resolved • ${data.checked_list_length} of ${data.checklist_length} checked ${
                  asset?.on_hold ? '• on-hold' : ''
                },`
              : `${data.checked_list_length} of ${data.checklist_length} checked ${
                  asset?.on_hold ? '• on-hold' : ''
                }`;
          } else {
            return data.complaints_count > 0
              ? `${data.complaints_count} ${
                  data.complaints_count > 1 ? ' Complaints' : ' Complaint'
                } • ${data.checked_list_length} of ${data.checklist_length} checked ${
                  asset?.on_hold ? '• on-hold' : ''
                }`
              : `${data.checked_list_length} of ${data.checklist_length} checked ${
                  asset?.on_hold ? '• on-hold' : ''
                }`;
          }
        }
      } else {
        if (!asset.checked) {
          return asset?.on_hold
            ? `On-hold from ${moment(asset.on_hold).format('MMM D')}, until resolved`
            : `Check-up pending ...`;
        } else {
          return data.complaints_count > 0
            ? `${data.resolved_count} of ${data.complaints_count} ${
                data.complaints_count > 1 ? ' Complaints' : ' Complaint'
              } resolved • Checked`
            : `Checked ${asset?.on_hold ? '• on-hold' : ''}`;
        }
      }
    } else {
      if (asset.checklist.length > 0) {
        if (data.resolved_count > 0) {
          return data.complaints_count > 0
            ? `${data.resolved_count} of ${data.complaints_count} resolved • ${
                data.checked_list_length
              } of ${data.checklist_length} checked ${asset?.on_hold ? '• on-hold' : ''}`
            : `${data.checked_list_length} of ${data.checklist_length} checked ${
                asset?.on_hold ? '• on-hold' : ''
              }`;
        } else {
          return data.complaints_count > 0
            ? `${data.complaints_count} ${
                data.complaints_count > 1 ? ' Complaints' : ' Complaint'
              } • ${data.checked_list_length} of ${data.checklist_length} checked,${
                asset?.on_hold ? '• on-hold' : ''
              }`
            : `${data.checked_list_length} of ${data.checklist_length} checked ${
                asset?.on_hold ? '• on-hold' : ''
              }`;
        }
      } else {
        if (!asset.checked) {
          return asset?.on_hold
            ? `On-hold from ${moment(asset.on_hold).format('MMM D')}, until resolved`
            : `Not checked ...`;
        } else {
          return data.complaints_count > 0
            ? `${data.resolved_count} of ${data.complaints_count} ${
                data.complaints_count > 1 ? ' Complaints' : ' Complaint'
              } resolved • Checked ${asset?.on_hold ? '• on-hold' : ''}`
            : `Checked ${asset?.on_hold ? '• on-hold' : ''}`;
        }
      }
    }
  };

  const renderDot = (asset) => {
    let reportCountData = getAssetTotalData(asset);
    let red = <div className={styles.dotRedStyle} />;
    let green = <div className={styles.dotGreenStyle} />;
    let yellow = <div className={styles.dotYellowStyle} />;
    let yellowGreen = (
      <div className={styles.dotMixedColorContainerStyle}>
        <div className={styles.dotMixedGreenStyle} />
        <div className={styles.dotMixedYellowStyle} />
      </div>
    );
    let yellowRed = (
      <div className={styles.dotMixedColorContainerStyle}>
        <div className={styles.dotMixedRedStyle} />
        <div className={styles.dotMixedYellowStyle} />
      </div>
    );
    if (reportCountData.complaints_count > 0) {
      if (reportCountData.complaints_count === reportCountData.resolved_count) {
        if (
          reportCountData.checked_list_length > 0 &&
          reportCountData.checked_list_length !== reportCountData.checklist_length
        ) {
          return yellowGreen;
        } else {
          return green;
        }
      } else {
        if (
          reportCountData.checked_list_length > 0 &&
          reportCountData.checked_list_length !== reportCountData.checklist_length
        ) {
          return yellowRed;
        } else {
          return red;
        }
      }
    } else {
      if (
        reportCountData.checked_list_length > 0 &&
        reportCountData.checked_list_length !== reportCountData.checklist_length
      ) {
        return yellow;
      }
    }
  };

  const tapOnAsset = (asset) => {
    if (userType === account_types.super_admin) {
      setShowAssetReportViewModal(true);
      setSelectedAssetId(asset.asset._id);
    } else {
      if (selectedTab === 'Ongoing' && userType !== account_types.super_admin) {
        if (width > 480) {
          setShowVerifyModal(true);
          setVerifyType('manual');
        } else {
          setShowScanner(true);
          setVerifyType('scan');
        }
        setSelectedAssetId(asset.asset._id);
      } else {
        setShowAssetReportViewModal(true);
        setSelectedAssetId(asset.asset._id);
      }
    }
  };

  const onVerifyAsset = (code = verifyCode) => {
    setVerifyAssetLoader(true);
    setVerifyCodeErrorMsg('');
    if (seletedAssetReport.asset.qr_id === code || seletedAssetReport.asset.asset_uid === code) {
      setTimeout(() => {
        setVerifyCode('');
        setVerifyAssetLoader(false);
        setShowVerifyModal(false);
        setShowAssetReportViewModal(true);
        setVerifyType('');
      }, 300);
    } else {
      setVerifyAssetLoader(false);
      setVerifyCode('');
      if (verifyType === 'scan') {
        toast.error('Id not matched');
      } else if (verifyType === 'manual') {
        setVerifyCodeErrorMsg('Id not matched');
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleScan = (url, error) => {
    if (url !== null && url !== '') {
      let data = url?.split('/');
      let id = data[data.length - 1];
      if (id) {
        setVerifyCode(id);
        setShowScanner(false);
        onVerifyAsset(id);
      }
    }
    if (error) {
      toast.error(error);
    }
  };

  // ui section

  const renderAssetReportModal = () => {
    return (
      <Modal
        show={showAssetReportViewModal}
        containerStyle={styles.assetReportModalStyle}
        showOverlay
        onClose={() => setShowAssetReportViewModal(false)}
        closeOnOutSideClick
      >
        <ClusterAssetReport
          cluster_report={report}
          asset_report={seletedAssetReport}
          cluster={cluster}
          setCluster={setCluster}
          onClose={() => setShowAssetReportViewModal('')}
        />
      </Modal>
    );
  };

  const renderVerifyModal = () => {
    return (
      <Modal
        show={showVerifyModal}
        containerStyle={styles.assetVerifyModalStyle}
        showOverlay
        onClose={() => {
          setShowVerifyModal(false);
          setSelectedAssetId('');
        }}
        closeOnOutSideClick
      >
        <div className={styles.verifyModalContainerStyle}>
          {verifyAssetLoader && <Loader />}
          <div className={styles.verifyModalHeaderStyle}>
            <p className={styles.verifyHeaderTextStyle}>Verify asset</p>
            <Image
              src={closeIcon}
              alt="img"
              onClick={() => {
                setShowVerifyModal(false);
                setVerifyCode('');
                setVerifyCodeErrorMsg('');
                setSelectedAssetId('');
              }}
              containerStyle={styles.closeIconStyle}
            />
          </div>
          <div className={styles.verifyModalMiddleViewStyle}>
            <div className={styles.verifyModalInputContainerStyle}>
              <div className={styles.verifyNoteViewStyle}>
                <p className={styles.verifyNoteTextStyle}>
                  Enter the unique asset ID (the one printed on QR sticker) to verify and update the
                  condition of the asset. If you’re accessing trakr on mobile phone, you’ll be able
                  to scan the QR directly to verify.
                </p>
              </div>
              <Input
                labelText="Unique asset ID*"
                placeholder="Ex: V67UWZ9081"
                value={verifyCode}
                onChange={(e) => {
                  setVerifyCode(e.target.value);
                  setVerifyCodeErrorMsg('');
                }}
                hasError={verifyCodeErrorMsg}
              />
            </div>
            <div className={styles.verifyBtnsViewStyle}>
              <Button
                title="Cancel"
                variant="light"
                onClick={() => {
                  setShowVerifyModal(false);
                  setVerifyCode('');
                  setVerifyCodeErrorMsg('');
                  setSelectedAssetId('');
                  setVerifyType('');
                }}
                btnStyle={styles.verifyBtnStyle}
              />
              <Button
                title="Proceed"
                onClick={() => {
                  onVerifyAsset();
                }}
                btnStyle={styles.verifyBtnStyle}
                disabled={verifyCode ? false : true}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const renderScannerSection = () => {
    return (
      <div className={styles.scannerSectionStyle}>
        <QrReader
          delay={2000}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%', height: '100%' }}
          className={styles.qrReader}
        />
        <div className={styles.scanBarStyle}>
          <em></em>
          <span></span>
        </div>
        <div className={styles.scannerOptionsViewStyle}>
          <div className={styles.scannerOptionsSubViewStyle}>
            <Image
              src={closeWhiteIcon}
              alt="X"
              containerStyle={styles.scannerIconsViewStyle}
              onClick={() => {
                setShowScanner(false);
                setVerifyType('');
              }}
            />
            <Image src={flashWhiteIcon} alt="img" containerStyle={styles.scannerIconsViewStyle} />
          </div>
        </div>
        <div className={styles.scannerBottomOptionsViewStyle}>
          <div className={styles.scannerBottomOptionsSubViewStyle}>
            <Button
              title="Enter asset’s unique ID"
              btnStyle={styles.scannerBtnViewStyle}
              onClick={() => {
                setShowVerifyModal(true);
                setVerifyType('manual');
                setShowScanner(false);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {showScanner ? (
        renderScannerSection()
      ) : (
        <div className={styles.containerStyle}>
          <div className={styles.dotStyle} />
          <div className={styles.contentBlockStyle}>
            <div
              className={classNames(styles.contentSubBlockStyle, show ? styles.pointerStyle : null)}
              onClick={() => show && setShowReport(!showReport)}
            >
              <div className={styles.basicInfoBlockStyle}>
                <p className={styles.titleStyle}>
                  {moment(report.start_date).format('Do MMMM')}{' '}
                  {moment().isSame(report.start_date) && '(Today)'}
                  {displayReportCloseTime() && (
                    <span className={styles.subDescStyle}> • {displayReportCloseTime()}</span>
                  )}
                </p>
                <div className={styles.checkedBlockStyle}>
                  <p className={styles.descStyle}>Checked assets: </p>
                  <ProgressBar
                    percentage={(getReportCheckedCount() / getReportAssetCount()) * 100}
                    trackStyle={styles.trackStyle}
                    barStyle={
                      (getReportCheckedCount() / getReportAssetCount()) * 100 !== 100
                        ? styles.barIncompleteStyle
                        : null
                    }
                  />
                  <p className={styles.descStyle}>
                    <span className={styles.subTitleStyle}>{getReportCheckedCount() + ' '}</span>
                    {'/ ' + getReportAssetCount()}
                  </p>
                </div>
                {complaintsData.toal_complaints_count > 0 && (
                  <div className={styles.complaintBlockStyle}>
                    <p className={styles.descStyle}>Complaints:</p>
                    <p
                      className={classNames(
                        styles.subTitleStyle,
                        styles.complaintCountTextStyle,
                        complaintsData.resolved_count > 0
                          ? styles.complaintResolvedCountStyle
                          : styles.complaintRaisedCountStyle
                      )}
                    >
                      {complaintsData.resolved_count > 0
                        ? `${complaintsData.resolved_count} of ${complaintsData.toal_complaints_count} resolved`
                        : `${complaintsData.toal_complaints_count} raised`}
                    </p>
                  </div>
                )}
              </div>
              {show && (
                <Image
                  src={showReport ? chevronUpBlackIcon : chevronDownBlackIcon}
                  containerStyle={styles.reportIconStyle}
                />
              )}
            </div>
            {showReport && (
              <div ref={scrollRef} className={styles.contentBottomBlockStyle}>
                <div className={styles.filterBlockStyle}>
                  {reportFilterTabs.map((item, index) => {
                    return (
                      <Chip
                        key={'rf' + index}
                        label={item}
                        onClick={() => setSelectedFilterTab(item)}
                        containerStyle={
                          selectedFilterTab === item ? styles.selectedFilterTabStyle : null
                        }
                      />
                    );
                  })}
                </div>
                <div className={styles.listStyle}>
                  {filteredAssets.map((asset, index) => (
                    <div
                      key={'asset' + index}
                      className={styles.listItemStyle}
                      // onClick={() => setSelectedAssetId(asset.asset._id)}
                      onClick={() => {
                        tapOnAsset(asset);
                      }}
                    >
                      <Image
                        src={getReportStateIcon(asset)}
                        containerStyle={styles.reportIconStyle}
                      >
                        {renderDot(asset)}
                      </Image>
                      <div className={styles.listItemContentStyle}>
                        <p className={styles.listItemTitleStyle}>
                          {asset.asset.asset_name + ' (' + asset.asset.asset_uid + ') '}
                        </p>
                        <p className={styles.listItemDescStyle}>
                          {displayAssetReportDescrption(asset)}
                        </p>
                      </div>
                      <Image src={chevronRightBlackIcon} containerStyle={styles.reportIconStyle} />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {renderAssetReportModal()}
      {renderVerifyModal()}
    </React.Fragment>
  );
};

ClusterReport.propTypes = {
  cluster: PropTypes.any,
  setCluster: PropTypes.func,
  report: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    mcluster: PropTypes.string.isRequired,
    cluster_assets: PropTypes.array,
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string.isRequired,
    deleted_at: PropTypes.oneOf([PropTypes.string, null]),
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    selectedTab: PropTypes.string,
  }),
  show: PropTypes.bool,
};

export default ClusterReport;
