import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Chip from 'components/common/chip';
import styles from './styles.module.css';

const Capsule = (props) => {
  const {
    reference,
    label,
    onClick,
    containerStyle,
    labelStyle,
    leftIcon,
    leftIconStyle,
    rightIcon,
    rightIconStyle,
    children,
  } = props;

  return (
    <Chip
      label={label}
      reference={reference}
      onClick={onClick}
      containerStyle={classNames(styles.containerStyle, containerStyle)}
      labelStyle={classNames(styles.labelStyle, labelStyle)}
      leftIcon={leftIcon}
      leftIconStyle={classNames(styles.leftIconStyle, leftIconStyle)}
      rightIcon={rightIcon}
      rightIconStyle={classNames(styles.rightIconStyle, rightIconStyle)}
    >
      {children && children}
    </Chip>
  );
};

Capsule.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  containerStyle: PropTypes.string,
  labelStyle: PropTypes.string,
  reference: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  leftIcon: PropTypes.string,
  leftIconStyle: PropTypes.string,
  rightIcon: PropTypes.string,
  rightIconStyle: PropTypes.string,
};

export default Capsule;
