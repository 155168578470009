import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const ProgressBar = (props) => {
  const { percentage, trackStyle, barStyle } = props;

  return (
    <div className={classNames(styles.trackStyle, trackStyle)}>
      <div className={classNames(styles.barStyle, barStyle)} style={{ width: `${percentage}%` }} />
    </div>
  );
};

ProgressBar.propTypes = {
  percentage: PropTypes.number,
  trackStyle: PropTypes.string,
  barStyle: PropTypes.string,
};

export default ProgressBar;
