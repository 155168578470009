import axios from 'utils/axios';
import * as endPoints from 'networking/endpoints';

export const employeeCreateApi = async (data) =>
  Promise.resolve(axios.post(endPoints.createEmployee, data));

export const employeeDetailsWithCompaniesApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getEmployeeDetailsWithCompanies.replace('{id}', id)));

export const employeeDetailsApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getEmployeeDetails.replace('{id}', id)));

export const getAllEmployeesWithLastAssetApi = async (
  id,
  pageNumber,
  searchQuery,
  limit,
  sortBy,
  sortOrder
) => {
  let data = new URLSearchParams();
  if (id) data.append('company', id);
  if (pageNumber) data.append('page', pageNumber);
  if (searchQuery) data.append('search', searchQuery);
  if (limit) data.append('limit', limit);
  if (sortBy) data.append('sortBy', sortBy);
  if (sortOrder) data.append('sortOrder', sortOrder);
  return Promise.resolve(axios.get(endPoints.getAllEmployeesWithLastAsset + `?${data.toString()}`));
};

export const employeeUpdateApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateEmployee, data));

export const employeeDeleteApi = async (id) =>
  Promise.resolve(axios.delete(endPoints.deleteEmployee.replace('{id}', id)));

export const getemployeeDetailsWithAssetsApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getemployeeDetailsWithAssets.replace('{id}', id)));
export const uploadUserXlsFileApi = async (data) =>
  Promise.resolve(axios.post(endPoints.uploadUserXlsFile, data));

// get all admins and employees api
export const getAllAdminsAndEmployeesApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getAdminsAndEmployees + `?company=${id}`));

export const getUserReminderListApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getUserReminderList.replace('{id}', id)));

export const handleUpdateUserReminderApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateUserReminder, data));

export const addUserReminderApi = async (data) =>
  Promise.resolve(axios.post(endPoints.addUserReminder, data));

export const deleteUserReminderApi = async (id) =>
  Promise.resolve(axios.delete(endPoints.deleteUserReminder.replace('{id}', id)));

export const getUserCompletedRemindersApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getUserCompletedReminders + `?company=${id}`));

export const getUsersWithSearchApi = async (id, searchQuery) => {
  let data = new URLSearchParams();
  if (id) data.append('company', id);
  if (searchQuery) data.append('search', searchQuery);
  return Promise.resolve(axios.get(endPoints.getUsersWithSearch + `?${data.toString()}`));
};

export const getUserTicketsApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getUserTickets.replace('{id}', id)));

export const getUserClustersApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getUserClusters.replace('{id}', id)));
