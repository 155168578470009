import React from 'react';
import PropTypes from 'prop-types';
import Info from 'components/common/info';
import moment from 'moment';
import styles from './styles.module.css';

const InfoCard = (props) => {
  const { type, data } = props;
  return (
    <div className={styles.containerStyle}>
      <Info
        leftlable={type === 'asset' ? 'Serial no.' : 'Employee ID'}
        leftValue={
          type === 'asset' ? data?.asset_uid : data?.employee_id ? data.employee_id : '---'
        }
        rightlable={type === 'asset' ? 'Asset name' : 'Account type'}
        rightValue={
          type === 'asset' ? data?.asset_name : data?.account_type ? data.account_type : '---'
        }
      />
      <Info
        leftlable={type === 'asset' ? 'Asset category' : 'Phone no.'}
        leftValue={
          type === 'asset'
            ? data?.asset_category
            : data?.phone_number
            ? `${data?.phone_number.country_code} ${data?.phone_number.value}`
            : '---'
        }
        rightlable={type === 'asset' ? 'Unique ID (QR no.)' : 'Role'}
        rightValue={
          type === 'asset'
            ? data?.qr_id
              ? data?.qr_id
              : '---'
            : data?.employee_type
            ? data?.employee_type
            : '---'
        }
      />
      <div className={styles.infoViewStyle}>
        <p className={styles.lableStyle}>{type === 'asset' ? 'Description' : 'Bio'}</p>
        <p className={styles.textStyle}>
          {type === 'asset'
            ? data?.description
              ? data?.description
              : '---'
            : data?.bio
            ? data?.bio
            : '---'}
        </p>
      </div>
      {type === 'asset' && (
        <Info
          leftlable="Purchase date"
          leftValue={data?.purchased_on ? moment(data?.purchased_on).format('DD/MM/YYYY') : '---'}
          rightlable="Assigned to"
          rightValue={data?.assigned_to ? data.assigned_to.full_name : '---'}
        />
      )}
    </div>
  );
};

InfoCard.propTypes = {
  type: PropTypes.oneOf(['asset', 'user']),
  data: PropTypes.object,
};

export default InfoCard;
