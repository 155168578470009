import axios from 'utils/axios';
import * as endPoints from 'networking/endpoints';

export const sendRegistrationOtpApi = async (data) =>
  Promise.resolve(axios.post(endPoints.sendRegistrationOtp, data));

export const superAdminRegisterApi = async (data) =>
  Promise.resolve(axios.post(endPoints.superAdminRegister, data));

export const superAdminDetailsApi = async () =>
  Promise.resolve(axios.get(endPoints.getSuperAdminDetails));
