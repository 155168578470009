import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { useAppData } from 'providers/appdataprovider';
import { useStrings } from 'providers/stringsprovider';
import { useAuth } from 'providers/authprovider';
import { useUserData } from 'providers/userdataprovider';
import useWindowDimensions from 'hooks/useWindowDimensions';
import {
  chevronDownFillBlackIcon,
  closeIcon,
  clusterIcon,
  plusBlackIcon,
  searchIcon,
} from 'resources/images';
import { account_types } from 'resources/data';
import ClusterDetails from 'sections/maintenance/cluster-details';
import ClusterCreate from 'sections/maintenance/cluster-create';
import Button from 'components/common/button';
import Input from 'components/common/input';
import Image from 'components/common/image';
import Chip from 'components/common/chip';
import ProgressBar from 'components/common/progress-bar';
import PopOver from 'components/common/pop-over';
import CheckBox from 'components/common/checkbox';
import Modal from 'components/common/modal';
import Loader from 'components/common/loader';
import { getClustersApi } from 'networking/api/cluster';
import InfiniteScroll from 'components/infinite-scroll';
import styles from './styles.module.css';

const MaintenanceClusterList = () => {
  const { userType, company } = useAuth();
  const location = useLocation();
  const { strings } = useStrings();
  let assetId = location?.state?.asset;
  const { setShowHeader, setBreadcrumbs } = useAppData();
  const { clusters, setClusters } = useUserData();
  const { width } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedCluster = searchParams.get('selected');
  const [filteredClusters, setFilteredClusters] = useState([]);
  const [enableListSearch, setEnableListSearch] = useState(false);
  const [query, setQuery] = useState(searchParams.get('query') || '');
  const [selectedClusterType, setSelectedClusterType] = useState('All clusters');
  const [sortClusterRef, setSortClusterRef] = useState(null);
  const [sortClusterModal, setSortClusterModal] = useState(false);
  const [showCreateClusterModal, setShowCreateClusterModal] = useState(false);
  const [initialLoder, setInitialLoder] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [debouncedQuery, setDebouncedQuery] = useState(searchParams.get('query') || '');

  const sortOptions = [
    'All clusters',
    'Daily clusters',
    'Weekly clusters',
    'Monthly clusters',
    'Custom clusters',
    'Archived clusters',
  ];

  useEffect(() => {
    searchParams.get('query') && setQuery(searchParams.get('query'));
  }, [searchParams]);

  useEffect(() => {
    setShowHeader(true);
    setInitialLoder(true);
  }, [setShowHeader]);

  useEffect(() => {
    if (selectedCluster) {
      document
        .getElementById(`selected-${selectedCluster}`)
        ?.scrollIntoView({ behavior: 'auto', block: 'center' });
    }
  }, [selectedCluster]);

  useEffect(() => {
    let data = [...clusters];
    if (selectedClusterType === 'All clusters') {
      data = data.filter((item) => !item.archived_at);
    } else if (selectedClusterType === 'Archived clusters') {
      data = data.filter((item) => item.archived_at);
    } else {
      data = data.filter(
        (item) => !item.archived_at && item.type === selectedClusterType.split(' ')[0]
      );
    }
    if (query) {
      data = data.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(query.toLowerCase())
      );
    }
    setFilteredClusters(data);
    setBreadcrumbs([{ title: strings.maintenance, route: '' }]);
  }, [clusters, selectedClusterType, setBreadcrumbs, query, strings.maintenance]);

  useEffect(() => {
    if (clusters.length > 0 && assetId) {
      let data = [];
      clusters.forEach((cls) => {
        if (cls.last_report) {
          cls.last_report.cluster_assets.forEach((cls_rep) => {
            if (cls_rep.asset === assetId) {
              data.push(cls);
            }
          });
        }
      });
      setFilteredClusters(data);
    }
  }, [assetId, clusters]);

  const debouncedSearch = useMemo(
    () =>
      debounce((searchValue) => {
        setDebouncedQuery(searchValue);
        setCurrentPage(1);
      }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(query);
  }, [query, debouncedSearch]);

  const getClusters = useCallback(async () => {
    try {
      if (currentPage === 1) {
        setClusters([]);
      }
      setLoading(true);
      const response = await getClustersApi(company?._id, currentPage, debouncedQuery, 10);
      setClusters((prev) => {
        if (currentPage === 1) {
          return response.data.data.clusters;
        } else {
          return [...prev, ...response.data.data.clusters];
        }
      });
      setHasMore(response.data.data?.meta?.hasNextPage);
      setLoading(false);
      setInitialLoder(false);
    } catch (err) {
      setLoading(false);
      setInitialLoder(false);
    }
  }, [company, currentPage, debouncedQuery, setClusters]);

  useEffect(() => {
    getClusters();
  }, [getClusters]);

  const getClusterAssetsLength = (cluster) => {
    let data = [];
    cluster.last_report?.cluster_assets?.filter((item) => {
      if (item?.on_hold) {
        if (moment(item.on_hold).isBetween(cluster.start_date, cluster.end_date)) {
          data.push(item);
        }
      } else {
        data.push(item);
      }
      return item;
    });
    return data.length.toString().padStart(2, 0);
  };

  const getClusterItemDate = (cluster) => {
    if (cluster.type === 'Daily' || cluster.type === 'Custom') {
      return moment(
        cluster.last_report ? cluster.last_report?.start_date : cluster.start_date
      ).format('Do MMMM');
    } else if (
      cluster.type === 'Weekly' ||
      cluster.type === 'Monthly' ||
      cluster.type === 'Weekday'
    ) {
      return `${moment(
        cluster.last_report ? cluster.last_report?.start_date : cluster.start_date
      ).format('D MMM')} ${
        cluster.end_date || cluster?.last_report?.end_date
          ? `- ${moment(
              cluster.last_report ? cluster.last_report?.end_date : cluster.end_date
            ).format('D MMM')}`
          : ''
      }`;
    }
  };

  const getCheckedAssetCount = (cluster) => {
    let checked_count = 0;
    cluster?.last_report?.cluster_assets?.map((item) => {
      if (item?.on_hold) {
        if (moment(item.on_hold).isBetween(cluster.start_date, cluster.end_date)) {
          if (item.checked === true) {
            checked_count += 1;
          }
        }
      } else {
        if (item.checked === true) {
          checked_count += 1;
        }
      }
      return item;
    });
    return checked_count;
  };

  const getTotalAssetCount = (cluster) => {
    let asset_count = 0;
    cluster.last_report?.cluster_assets?.filter((item) => {
      if (item?.on_hold) {
        if (moment(item.on_hold).isBetween(cluster.start_date, cluster.end_date)) {
          asset_count += 1;
        }
      } else {
        asset_count += 1;
      }
      return item;
    });
    return asset_count;
  };

  const getComplaintsCount = (cluster) => {
    let count = 0;
    cluster.last_report?.cluster_assets.map((clu_asset) => {
      clu_asset.checklist.map((cl) => {
        cl?.items.map((cl_item) => {
          if (cl_item?.complaint) {
            if (cl_item.complaint.ticket_status !== 'Closed') {
              count++;
            }
          }
          return cl_item;
        });
        return cl;
      });
      return clu_asset;
    });
    cluster.last_report?.cluster_assets.map((clu_asset) => {
      if (clu_asset?.complaints?.length > 0) {
        clu_asset.complaints.map((comp) => {
          if (comp.ticket_status !== 'Closed') {
            count++;
          }
          return comp;
        });
      }
      return clu_asset;
    });
    return count;
  };

  const getBgClolor = (type) => {
    if (type === 'Daily') {
      return styles.dailyBgStyle;
    } else if (type === 'Weekly') {
      return styles.weeklyBgStyle;
    } else if (type === 'Monthly') {
      return styles.monthlyBgStyle;
    } else {
      return styles.customBgStyle;
    }
  };

  const getTextClolor = (type) => {
    if (type === 'Daily') {
      return styles.dailyTextStyle;
    } else if (type === 'Weekly') {
      return styles.weeklyTextStyle;
    } else if (type === 'Monthly') {
      return styles.monthlyTextStyle;
    } else {
      return styles.customTextStyle;
    }
  };

  const renderSortClusterSection = () => {
    return (
      <PopOver
        show={sortClusterModal}
        reference={sortClusterRef}
        onClose={() => setSortClusterModal(false)}
        containerStyle={styles.sortClusterModalStyle}
      >
        {sortOptions.map((item, index) => {
          return (
            <div key={'sort' + index} className={styles.sortClusterListItemWrapperStyle}>
              <CheckBox
                checked={selectedClusterType === item}
                onChange={() => {
                  setSelectedClusterType(item);
                  setSortClusterModal(false);
                }}
                boxStyle={styles.sortClusterItemCheckboxStyle}
              />
              <p className={styles.sortClusterItemTitleStyle}>{item}</p>
            </div>
          );
        })}
      </PopOver>
    );
  };

  const renderClusterCreateModalSection = () => {
    return (
      <Modal
        show={showCreateClusterModal}
        showOverlay
        onClose={() => setShowCreateClusterModal(false)}
        containerStyle={styles.clusterCreateModalStyle}
      >
        <ClusterCreate onModalClose={() => setShowCreateClusterModal(false)} />
      </Modal>
    );
  };

  return (
    <div className={styles.clusterContainerStyle}>
      {initialLoder && <Loader />}
      <React.Fragment>
        {(!selectedCluster || width > 480) && (
          <div className={styles.clusterListContainerStyle}>
            <div
              className={classNames(
                styles.clusterListHeaderContainerStyle,
                enableListSearch ? styles.clusterListHeaderContainerEndStyle : null
              )}
            >
              {!enableListSearch && (
                <Button
                  title={'Create cluster'}
                  leftIcon={plusBlackIcon}
                  variant="light"
                  btnStyle={classNames(
                    userType === account_types.employee && styles.disableActionStyle,
                    styles.btnSmStyle
                  )}
                  onClick={() => setShowCreateClusterModal(true)}
                />
              )}

              <Input
                value={query}
                onChange={(e) => {
                  enableListSearch && setQuery(e.target.value);
                }}
                placeholder={enableListSearch ? 'Search...' : ''}
                rightIcon={enableListSearch ? closeIcon : searchIcon}
                rightIconStyle={styles.searchInputIconStyle}
                onClickRightIcon={() => {
                  setEnableListSearch(!enableListSearch);
                  setQuery('');
                }}
                containerStyle={classNames(
                  styles.searchInputContainerStyle,
                  enableListSearch ? styles.activeSearchInputContainerStyle : null
                )}
                inputStyle={classNames(
                  styles.searchInputStyle,
                  enableListSearch ? styles.activeSearchInputStyle : null
                )}
                disabled={!enableListSearch}
              />
            </div>
            <div className={styles.clusterListBodyWrapperStyle}>
              <div className={styles.clusterListBodyHeaderStyle}>
                <p className={styles.clisterListBodyHeaderTitleStyle}>Clusters</p>
                <Button
                  title={selectedClusterType}
                  rightIcon={chevronDownFillBlackIcon}
                  variant="gray"
                  btnStyle={styles.clisterListFilterBtnStyle}
                  reference={setSortClusterRef}
                  onClick={() => setSortClusterModal(true)}
                />
                {renderSortClusterSection()}
              </div>
              <div className={styles.clusterListBodyStyle}>
                <InfiniteScroll
                  data={filteredClusters}
                  loading={loading}
                  hasMore={hasMore}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                >
                  {filteredClusters.length === 0 && !initialLoder && !loading ? (
                    <div className={styles.emptyDataBlcokStyle}>
                      <p className={styles.emptyDataTextStyle}>No clusters available</p>
                    </div>
                  ) : (
                    <div className={styles.clusterListBodyContentStyle}>
                      {filteredClusters.map((item, index) => {
                        return (
                          <React.Fragment key={'cluster' + index}>
                            <div
                              className={classNames(
                                styles.clusterListItemStyle,
                                item._id === selectedCluster
                                  ? styles.selectedClusterListItemStyle
                                  : null
                              )}
                              onClick={() => setSearchParams({ selected: item._id })}
                              id={`selected-${item._id}`}
                            >
                              <Image
                                src={clusterIcon}
                                containerStyle={classNames(
                                  styles.clusterListItemIconStyle,
                                  getBgClolor(item.type)
                                )}
                              />
                              <div className={styles.clusterListItemRightStyle}>
                                <p className={styles.clusterListItemTitleStyle}>{item.name}</p>
                                <div className={styles.clusterListChipWrapperStyle}>
                                  <Chip
                                    label={item.type}
                                    labelStyle={classNames(getTextClolor(item.type))}
                                  />
                                  <Chip label={'Assets:' + getClusterAssetsLength(item)} />
                                </div>
                                <p className={styles.clusterListItemSubTitleStyle}>
                                  {getClusterItemDate(item)}
                                </p>
                                <div className={styles.clusterListItemSubWrapperBlockStyle}>
                                  <p className={styles.clusterListItemDescStyle}>Checked Assets:</p>
                                  <ProgressBar
                                    percentage={
                                      (getCheckedAssetCount(item) / getTotalAssetCount(item)) * 100
                                    }
                                    barStyle={
                                      (getCheckedAssetCount(item) / getTotalAssetCount(item)) *
                                        100 !==
                                      100
                                        ? styles.barIncompleteStyle
                                        : null
                                    }
                                  />
                                  <p className={styles.clusterListItemCheckedAssetNoStyle}>
                                    {getCheckedAssetCount(item) + ' '}
                                    <span className={styles.clusterListItemCheckedAssetTotalStyle}>
                                      / {getTotalAssetCount(item)}
                                    </span>
                                  </p>
                                </div>
                                {getComplaintsCount(item) > 0 && (
                                  <div className={styles.clusterListItemSubWrapperBlockStyle}>
                                    <p className={styles.clusterListItemDescStyle}>Complaints:</p>
                                    <p className={styles.clusterListItemPillStyle}>
                                      {getComplaintsCount(item)} raised
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            {!initialLoder && loading && index === filteredClusters.length - 1 && (
                              <div className={styles.loaderSectionStyle}>
                                <Loader containerStyle={styles.loaderMobileViewStyle} />
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  )}
                  {!initialLoder && loading && filteredClusters.length === 0 && (
                    <div className={styles.loaderSectionStyle}>
                      <Loader containerStyle={styles.loaderMobileViewStyle} />
                    </div>
                  )}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        )}
        {selectedCluster && (
          <ClusterDetails id={selectedCluster} setSortClusterBy={setSelectedClusterType} />
        )}

        {!selectedCluster && width > 480 && (
          <div className={styles.noClusterSelectedStyle}>
            <p className={styles.noClusterSelectedTitleStyle}>
              Select a cluster from the list to view detailed-updates and much more.
            </p>
          </div>
        )}
      </React.Fragment>
      {renderClusterCreateModalSection()}
    </div>
  );
};

export default MaintenanceClusterList;
