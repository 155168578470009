import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Avatar from 'components/common/avatar';
import Capsule from 'components/common/capsule';
import styles from './styles.module.css';

const AvatarInfoCard = (props) => {
  const {
    icon,
    title,
    description,
    onClick,
    containerStyle,
    infoWrapperStyle,
    avatarStyle = '',
    titleStyle,
    descStyle,
    accountType,
  } = props;
  return (
    <div onClick={onClick} className={classNames(styles.containerStyle, containerStyle)}>
      <Avatar
        src={icon}
        label={title}
        containerStyle={classNames(avatarStyle, onClick && styles.pointerStyle)}
      />
      <div className={classNames(styles.infoWrapperStyle, infoWrapperStyle)}>
        <div className={styles.infoSubWrapperStyle}>
          <p
            className={classNames(styles.titleStyle, onClick && styles.pointerStyle, titleStyle)}
            title={title}
          >
            {title}
          </p>
          {accountType && (
            <Capsule
              label={accountType}
              containerStyle={styles.capsuleViewStyle}
              labelStyle={styles.labelStyle}
            />
          )}
        </div>
        <p
          className={classNames(styles.descStyle, onClick && styles.pointerStyle, descStyle)}
          title={description}
        >
          {description}
        </p>
      </div>
    </div>
  );
};

AvatarInfoCard.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  containerStyle: PropTypes.string,
  infoWrapperStyle: PropTypes.string,
  avatarStyle: PropTypes.string,
  titleStyle: PropTypes.string,
  descStyle: PropTypes.string,
  onClick: PropTypes.func,
  accountType: PropTypes.string,
};

export default AvatarInfoCard;
