import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppDataProvider from 'providers/appdataprovider';
import AuthProvider from 'providers/authprovider';
import SpinnerProvider from 'providers/spinnerprovider';
import StringsProvider from 'providers/stringsprovider';
import UserDataProvider from 'providers/userdataprovider';
import SocketProvider from 'providers/socketprovider';
import ToastProvider from 'providers/toastprovider';
import AppRoutes from 'routes';

const App = () => {
  return (
    <ToastProvider>
      <SpinnerProvider>
        <StringsProvider>
          <AppDataProvider>
            <AuthProvider>
              <UserDataProvider>
                <SocketProvider>
                  <BrowserRouter>
                    <AppRoutes />
                  </BrowserRouter>
                </SocketProvider>
              </UserDataProvider>
            </AuthProvider>
          </AppDataProvider>
        </StringsProvider>
      </SpinnerProvider>
    </ToastProvider>
  );
};

export default App;
