import React, { createContext, useMemo, useEffect, useContext } from 'react';
import { io } from 'socket.io-client';
import { useAuth } from 'providers/authprovider';

export const socket = io(process.env.REACT_APP_SERVER_URL, {
  transports: ['websocket'],
});

const SocketContext = createContext();

const SocketProvider = (props) => {
  const { user, isLoggedIn } = useAuth();
  let guest_user = localStorage.getItem('guest_user');

  useEffect(() => {
    if (isLoggedIn && user) {
      let data = {
        usr: user?._id,
        is_guest: false,
      };
      socket.connect();
      socket.emit('join_socket', data);
    } else if (guest_user) {
      let data = {
        usr: guest_user,
        is_guest: true,
      };
      socket.connect();
      socket.emit('join_socket', data);
    }

    return () => {
      socket.offAny();
      socket.disconnect();
    };
  }, [user, guest_user, isLoggedIn]);

  const memoizedValue = useMemo(() => ({}), []);

  return <SocketContext.Provider value={memoizedValue}>{props.children}</SocketContext.Provider>;
};

export default SocketProvider;

export const useSocket = () => {
  const context = useContext(SocketContext);

  if (context === undefined) {
    throw new Error('useSocket must be used within SocketProvider');
  }
  return context;
};
