import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from 'components/common/image';
import styles from './styles.module.css';

const Input = (props) => {
  const {
    labelText,
    id,
    type = 'text',
    name,
    value,
    onChange = () => {},
    onKeyDown = () => {},
    placeholder,
    disabled,
    hasError,
    numberOfRows = 5,
    containerStyle,
    inputLabelStyle,
    inputStyle,
    leftIcon,
    leftIconStyle,
    onClickLeftIcon,
    rightIcon,
    rightIconStyle,
    onClickRightIcon,
    inputReference,
    inputErrorStyle,
    ...rest
  } = props;

  return (
    <div className={classNames(styles.inputWrapperStyle, containerStyle)}>
      {labelText && (
        <label className={classNames(styles.inputLabelStyle, inputLabelStyle)} htmlFor={id}>
          {labelText}
        </label>
      )}
      {type === 'textarea' ? (
        <textarea
          id={id}
          name={name}
          value={value}
          className={classNames(styles.inputStyle, styles.textAreaStyle, inputStyle)}
          onChange={onChange}
          placeholder={placeholder || ''}
          onKeyDown={onKeyDown}
          rows={numberOfRows}
          ref={inputReference}
          disabled={disabled}
          {...rest}
        />
      ) : (
        <React.Fragment>
          {leftIcon && (
            <Image
              src={leftIcon}
              containerStyle={classNames(styles.leftIconStyle, leftIconStyle)}
              onClick={onClickLeftIcon}
            />
          )}
          <input
            id={id}
            type={type}
            name={name}
            value={value}
            className={classNames(styles.inputStyle, inputStyle)}
            onChange={onChange}
            placeholder={placeholder || ''}
            onKeyDown={onKeyDown}
            ref={inputReference}
            disabled={disabled}
            {...rest}
          />
          {rightIcon && (
            <Image
              src={rightIcon}
              containerStyle={classNames(styles.rightIconStyle, rightIconStyle)}
              onClick={onClickRightIcon}
            />
          )}
        </React.Fragment>
      )}
      {hasError && (
        <span className={classNames(styles.inputErrorStyle, inputErrorStyle)}>{hasError}</span>
      )}
    </div>
  );
};

Input.propTypes = {
  labelText: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.string,
  numberOfRows: PropTypes.number,
  containerStyle: PropTypes.string,
  inputLabelStyle: PropTypes.string,
  inputStyle: PropTypes.string,
  leftIcon: PropTypes.string,
  leftIconStyle: PropTypes.string,
  onClickLeftIcon: PropTypes.func,
  rightIcon: PropTypes.string,
  rightIconStyle: PropTypes.string,
  onClickRightIcon: PropTypes.func,
  inputReference: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  inputErrorStyle: PropTypes.string,
};

export default Input;
