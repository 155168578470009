import React from 'react';
import PropTypes from 'prop-types';
import { chevronDownFillBlueIcon, chevronRightBlackIcon, deleteIcon } from 'resources/images';
import Avatar from 'components/common/avatar';
import Image from 'components/common/image';
import Button from 'components/common/button';
import { useStrings } from 'providers/stringsprovider';
import classNames from 'classnames';
import styles from './styles.module.css';

const AssetAndUserCard = (props) => {
  const {
    type = 'asset',
    title,
    description,
    image,
    arrowRight = true,
    leftLable,
    leftTitle,
    leftDescription,
    rightLable,
    rightTitle,
    rightDescription,
    onClick = () => {},
    actionBtn = false,
    tapOnAction = () => {},
    deleteImg = false,
    deleteAction = () => {},
    containerStyle,
    bottomSectionStyle,
    customActionStyle,
  } = props;
  const { strings } = useStrings();
  const getTextClolor = () => {
    if (rightTitle === 'Assigned') {
      return styles.assignedTextColor;
    } else if (rightTitle === 'Unassigned') {
      return styles.unAssignedTextColor;
    } else {
      return styles.markAsLostTextStyle;
    }
  };

  return (
    <div onClick={onClick} className={classNames(styles.containerStyle, containerStyle)}>
      <div className={styles.topSectionStyle}>
        <div className={styles.detailsViewStyle}>
          <Avatar src={image} label={title} containerStyle={styles.avatarViewStyle} />
          <div className={styles.titleViewStyle}>
            <p className={styles.titleTextViewStyle}>{title}</p>
            <p className={styles.descTextStyle}>{description}</p>
          </div>
        </div>
        {arrowRight && <Image src={chevronRightBlackIcon} alt=">" />}
      </div>
      <div className={classNames(styles.bottomSectionStyle, bottomSectionStyle)}>
        <div className={styles.bottomSubSectionStyle}>
          <p className={styles.bottomLeftlabelStyle}>{leftLable}&nbsp;:</p>
          <div className={styles.bottomLeftSubSectionStyle}>
            <p className={styles.subTitleTextViewStyle}>{leftTitle}</p>
            <p className={styles.descTextStyle}>{leftDescription}</p>
          </div>
        </div>
        {rightLable && (
          <div className={styles.bottomSubSectionStyle}>
            <p className={styles.bottomLeftlabelStyle}>{rightLable}</p>
            <div className={styles.bottomLeftSubSectionStyle}>
              <p className={type === 'asset' ? getTextClolor() : styles.subTitleTextViewStyle}>
                {rightTitle}
              </p>
              <p className={styles.descTextStyle}>{rightDescription}</p>
            </div>
          </div>
        )}
        {actionBtn && (
          <Button
            title={strings.actions}
            size="md"
            onClick={(e) => tapOnAction(e)}
            rightIcon={chevronDownFillBlueIcon}
            btnStyle={classNames(styles.btnStyle, customActionStyle)}
          />
        )}
        {deleteImg && (
          <Image
            src={deleteIcon}
            alt="icon"
            onClick={deleteAction}
            containerStyle={styles.deleteIconStyle}
          />
        )}
      </div>
    </div>
  );
};

AssetAndUserCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  arrowRight: PropTypes.bool,
  leftLable: PropTypes.string,
  leftTitle: PropTypes.string,
  leftDescription: PropTypes.string,
  rightLable: PropTypes.string,
  rightTitle: PropTypes.string,
  rightDescription: PropTypes.string,
  type: PropTypes.oneOf(['asset', 'user']),
  onClick: PropTypes.func,
  tapOnAction: PropTypes.func,
  actionBtn: PropTypes.bool,
  deleteImg: PropTypes.bool,
  deleteAction: PropTypes.func,
  containerStyle: PropTypes.string,
  bottomSectionStyle: PropTypes.string,
  customActionStyle: PropTypes.string,
};

export default AssetAndUserCard;
