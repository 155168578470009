import React, { createContext, useContext } from 'react';
import { Toaster, toast } from 'sonner';

const ToastContext = createContext();

const ToastProvider = ({ children }) => {
  const showToast = {
    success: (message, options = {}) => {
      const id = toast.success(message, {
        ...options,
        action: {
          label: 'Close',
          onClick: () => toast.dismiss(id),
        },
      });
    },
    error: (message, options = {}) => {
      const id = toast.error(message, {
        ...options,
        action: {
          label: 'Close',
          onClick: () => toast.dismiss(id),
        },
      });
    },
    info: (message, options = {}) => {
      const id = toast(message, {
        ...options,
        action: {
          label: 'Close',
          onClick: () => toast.dismiss(id),
        },
      });
    },
    warning: (message, options = {}) => toast(message, { ...options, icon: '⚠️' }),
    loading: (message, options = {}) => toast.loading(message, options),
    promise: (promise, messages, options = {}) => toast.promise(promise, messages, options),
    custom: (content, options = {}) => toast.custom(content, options),
    dismiss: (id) => toast.dismiss(id),
    clear: () => toast.dismiss(),
  };

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      <Toaster duration={3000} position="bottom-center" visibleToasts={3} />
    </ToastContext.Provider>
  );
};

export default ToastProvider;

export const useToast = () => {
  const context = useContext(ToastContext);

  if (context === undefined) {
    throw new Error('useToast must be used within ToastProvider');
  }
  return context;
};
