import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'components/common/modal';
import Image from 'components/common/image';
import Button from 'components/common/button';
import Loader from 'components/common/loader';
import { checkWhiteIcon, closeWhiteIcon } from 'resources/images';
import styles from './styles.module.css';

const ImagePreview = (props) => {
  const { src, onClose, onDone, isLoading, containerStyle, imageStyle, children } = props;
  return (
    <Modal
      show
      containerStyle={classNames(styles.containerStyle, containerStyle)}
      showOverlay
      overlayStyle={styles.overlayViewStyle}
    >
      <div className={styles.actionBtnWrapperStyle}>
        <Image src={closeWhiteIcon} onClick={onClose} containerStyle={styles.closeIconStyle} />
        <Button
          title={isLoading ? 'Uploading' : 'Done'}
          variant="dark"
          leftIcon={checkWhiteIcon}
          btnStyle={styles.doneBtnStyle}
          onClick={onDone}
        />
      </div>
      <Image src={src} containerStyle={classNames(styles.imageStyle, imageStyle)}>
        {isLoading && (
          <Loader containerStyle={styles.loaderContainerStyle} loaderStyle={styles.loaderStyle} />
        )}
      </Image>
      {children && children}
    </Modal>
  );
};

ImagePreview.propTypes = {
  src: PropTypes.string,
  onClose: PropTypes.func,
  onDone: PropTypes.func,
  isLoading: PropTypes.bool,
  containerStyle: PropTypes.string,
  imageStyle: PropTypes.string,
};

export default ImagePreview;
