import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const InfiniteScroll = (props) => {
  const { setCurrentPage, loading, hasMore, data } = props;
  const scrollRef = useRef(null);

  const handleScroll = useCallback(() => {
    const container = scrollRef.current;
    if (
      container &&
      container.scrollTop + container.clientHeight >= container.scrollHeight - 100 &&
      !loading &&
      hasMore
    ) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [loading, hasMore, scrollRef, setCurrentPage]);

  const debouncedHandleScroll = useMemo(() => debounce(handleScroll, 300), [handleScroll]);

  useEffect(() => {
    const container = scrollRef.current;
    if (container) {
      container.addEventListener('scroll', debouncedHandleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', debouncedHandleScroll);
      }
    };
  }, [loading, hasMore, debouncedHandleScroll]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!loading && hasMore && scrollRef.current) {
        timer && clearTimeout(timer);
        const { clientHeight } = scrollRef.current;
        if (clientHeight > scrollRef.current.children[0].clientHeight) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      }
    }, 10);
    return () => timer && clearTimeout(timer);
  }, [data, loading, hasMore, setCurrentPage]);

  return (
    <div ref={scrollRef} className={styles.containerStyle}>
      <div className={styles.subContainerStyle}>{props.children}</div>
    </div>
  );
};

InfiniteScroll.propTypes = {
  setCurrentPage: PropTypes.func,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  data: PropTypes.array,
};

export default InfiniteScroll;
