import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAuth } from 'providers/authprovider';
import { useStrings } from 'providers/stringsprovider';
import {
  assetIcon,
  avatar1Icon,
  avatar3Icon,
  chevronRightLightGrayIcon,
  closeIcon,
  flagIcon,
  maintenanceIcon,
} from 'resources/images';
import Loader from 'components/common/loader';
import { asset_options } from 'resources/data';
import { getAssetByQRApi, getAssetClustersApi } from 'networking/api/asset';
import routePaths from 'routes/paths';
import Image from 'components/common/image';
import { useToast } from 'providers/toastprovider';
import styles from './styles.module.css';

const ScanneddAsset = () => {
  const navigate = useNavigate();
  const { user, company } = useAuth();
  const toast = useToast();
  const { id } = useParams();
  const { strings } = useStrings();
  const [assetData, setAssetData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [maintenance, setMaintenance] = useState([]);
  let guest_user = localStorage.getItem('guest_user');

  const getAssetData = useCallback(async () => {
    try {
      setLoader(true);
      let response = await getAssetByQRApi({ qr_id: id });
      setAssetData(response.data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error(error.message);
    }
  }, [id, toast]);

  const getAssetClusters = useCallback(async () => {
    try {
      setLoader(true);
      let response = await getAssetClustersApi(id);
      setMaintenance('response.data.data', response.data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getAssetData();
      getAssetClusters();
    }
  }, [id, getAssetData, getAssetClusters]);

  let optionsData = [
    {
      name: 'Raise a ticket',
      desc: 'Click here to raise a ticket',
      icon: flagIcon,
      bgColor: '#F6E5F6',
      isVisible: true,
    },
    {
      name: 'Maintenance',
      desc: 'Only accessible by registered users',
      icon: maintenanceIcon,
      bgColor: '#E9F6E5',
      isVisible: user && maintenance.length > 0 ? true : false,
    },
    {
      name: 'Asset details',
      desc: 'Only accessible by registered users',
      icon: assetIcon,
      bgColor: '#E5ECF6',
      isVisible: company._id === assetData?.company?._id ? true : false,
    },
  ];

  const tapOnOption = async (data) => {
    if (data.name === asset_options.raise_a_ticket) {
      if (user) {
        navigate(routePaths.raiseTicketThroughQr, {
          state: { asset: assetData, guest_email: '' },
        });
      } else if (!user && guest_user) {
        navigate(routePaths.raiseTicketThroughQr, {
          state: { asset: assetData, guest_email: guest_user },
        });
      } else {
        navigate(routePaths.guestLogin, { state: { asset: assetData } });
      }
    } else if (data.name === asset_options.maintenance) {
      navigate(routePaths.clusterList, { state: { asset: assetData._id } });
    } else {
      navigate(`/asset/details/${assetData._id}`);
    }
  };

  const rendedrHeaderSection = () => {
    return (
      <div className={styles.headerViewStyle}>
        <Image
          src={closeIcon}
          alt="close"
          containerStyle={styles.closeIMgViewStyle}
          imgStyle={styles.imgStyle}
          onClick={() => navigate(-1)}
        />
      </div>
    );
  };

  const renderAssetDetails = () => {
    return (
      <div className={styles.assetDetailsViewStyle}>
        <div className={styles.assetImagesViewStyle}>
          <Image src={avatar1Icon} alt="user" containerStyle={styles.userImgViewStyle} />
          <Image
            src={assetData.image || avatar3Icon}
            alt="asset"
            containerStyle={styles.assetImgViewStyle}
          />
        </div>
        <div className={styles.assetDetailsSubViewStyle}>
          <p className={styles.titleTextStyle}>
            {strings.scannedItemTitle.replace('{company}', assetData.company?.company_name)}
          </p>
          <div className={styles.assetNameViewStyle}>
            <p>{assetData.asset_name}</p>
          </div>
          <p className={styles.uniqIdTextStyle}>
            {strings.uniqueId} <span className={styles.textStyle}>{assetData.asset_uid}</span>
          </p>
          <p className={styles.uniqIdTextStyle}>
            {strings.ownedBy} {/* <span className={styles.textStyle}>{}</span> */}
          </p>
        </div>
      </div>
    );
  };

  const renderAssetOptions = () => {
    return (
      <div className={styles.optionsViewStyle}>
        {optionsData.map((item, index) => (
          <React.Fragment key={index}>
            {item.isVisible && (
              <div onClick={() => tapOnOption(item)} className={styles.eachOptionViewStyle}>
                <div className={styles.leftSectionViewStyle}>
                  <div
                    className={styles.optionImgViewStyle}
                    style={{ backgroundColor: item.bgColor }}
                  >
                    <img src={item.icon} alt="img" className={styles.optionImgStyle} />
                  </div>
                  <div className={styles.optionDetailsViewStyle}>
                    <p className={styles.optionTitleTextStyle}>{item.name}</p>
                    <p className={styles.optionDescTextStyle}>{item.desc}</p>
                  </div>
                </div>
                <Image
                  src={chevronRightLightGrayIcon}
                  alt="img"
                  containerStyle={styles.optionRightImgViewStyle}
                  imgStyle={styles.optionRightImgStyle}
                />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.mainContainerStyle}>
      {loader ? (
        <Loader />
      ) : (
        <>
          {assetData ? (
            <React.Fragment>
              {rendedrHeaderSection()}
              {renderAssetDetails()}
              {renderAssetOptions()}
            </React.Fragment>
          ) : (
            <div className={styles.EmptyDataViewStyle}>
              <p className={styles.optionDescTextStyle}>No asset found</p>
              {/* <Button title="Try again" onClick={() => navigate(-1)} /> */}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ScanneddAsset;
