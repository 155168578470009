import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import classNames from 'classnames';
import { useAppData } from 'providers/appdataprovider';
import { useStrings } from 'providers/stringsprovider';
import { useAuth } from 'providers/authprovider';
import { useUserData } from 'providers/userdataprovider';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { chevronDownFillBlackIcon, flagFillWhiteIcon, searchIcon } from 'resources/images';
import TicketDetails from 'sections/ticket/ticket-details';
import Input from 'components/common/input';
import Button from 'components/common/button';
import PopOver from 'components/common/pop-over';
import Loader from 'components/common/loader';
import ListItemCard from 'components/list-item-card';
import { getAllTicketsApi } from 'networking/api/alltickets';
import InfiniteScroll from 'components/infinite-scroll';
import styles from './styles.module.css';

const TicketList = () => {
  const { strings } = useStrings();
  const { setShowHeader, setBreadcrumbs } = useAppData();
  const { user, company } = useAuth();
  const { tickets, setTickets } = useUserData();
  const { width } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTicket = searchParams.get('selected');
  const [query, setQuery] = useState(searchParams.get('query') || '');
  const [filtersRef, setFiltersRef] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(strings.all);
  const [filtersPopUp, setFiltersPopUp] = useState(false);
  const [filteredTickets, setFilteredTickets] = useState(tickets);
  const [initialLoder, setInitialLoder] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [debouncedQuery, setDebouncedQuery] = useState(searchParams.get('query') || '');

  useEffect(() => {
    searchParams.get('query') && setQuery(searchParams.get('query'));
  }, [searchParams]);

  const options = [
    strings.all,
    strings.archived,
    strings.complaintRaised,
    strings.assigned,
    strings.inProgress,
    strings.inReview,
    strings.closed,
  ];

  useEffect(() => {
    setShowHeader(true);
    setInitialLoder(true);
  }, [setShowHeader]);

  useEffect(() => {
    if (selectedTicket) {
      document
        .getElementById(`selected-${selectedTicket}`)
        ?.scrollIntoView({ behavior: 'auto', block: 'center' });
    }
  }, [selectedTicket]);

  useEffect(() => {
    if (tickets) {
      if (selectedFilter === strings.all) {
        setFilteredTickets(tickets);
      } else {
        setFilteredTickets(tickets.filter((ticket) => ticket.ticket_status === selectedFilter));
      }
    }
    setBreadcrumbs([{ title: strings.allTickets, route: '' }]);
  }, [tickets, selectedFilter, setBreadcrumbs, strings.all, strings.allTickets]);

  // useEffect(() => {
  //   debouncedSearch(query);
  // }, [query]);

  // const debouncedSearch = useCallback(
  //   debounce((searchValue) => {
  //     setDebouncedQuery(searchValue);
  //     setCurrentPage(1);
  //   }, 500),
  //   []
  // );

  const debouncedSearch = useMemo(
    () =>
      debounce((searchValue) => {
        setDebouncedQuery(searchValue);
        setCurrentPage(1);
      }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(query);
  }, [query, debouncedSearch]);

  const getTickets = useCallback(async () => {
    try {
      if (currentPage === 1) {
        setTickets([]);
      }
      setLoading(true);
      const response = await getAllTicketsApi(company?._id, currentPage, debouncedQuery, 10);
      setTickets((prev) => {
        if (currentPage === 1) {
          return response.data.data.tickets;
        } else {
          return [...prev, ...response.data.data.tickets];
        }
      });
      setHasMore(response.data.data?.meta?.hasNextPage);
      setLoading(false);
      setInitialLoder(false);
    } catch (err) {
      setLoading(false);
      setInitialLoder(false);
    }
  }, [company, currentPage, debouncedQuery, setTickets]);

  useEffect(() => {
    getTickets();
  }, [getTickets]);

  const handleTicketSubject = (data) => {
    let msg = '';
    if (data?.ticket_status === 'Complaint raised') {
      msg = `${
        data?.created_by
          ? data?.created_by.full_name +
            (data?.created_by._id === user?._id ? ` (${strings.you})` : '')
          : 'Guest user'
        // : data.created_by_guest.full_name
      } ${strings.hasRaisedaComplaint} “${data.subject}”`;
    } else if (data.ticket_status === 'Assigned') {
      msg = `${
        data?.last_activity?.action_by.full_name +
        (data?.last_activity?.action_by._id === user?._id ? ` (${strings.you})` : '')
      } ${strings.assignedTheTicketTo} ${
        data?.last_activity?.users.length > 0 && data?.last_activity?.users[0].full_name
      }`;
    } else {
      msg = `${
        data?.last_activity?.action_by.full_name +
        (data?.last_activity?.action_by._id === user?._id ? ` (${strings.you})` : '')
      } ${strings.changedTheStatusTo} "${data?.last_activity?.type}"`;
    }
    return msg;
  };

  const onSelectFilter = (opt) => {
    setSelectedFilter(opt);
    if (opt === strings.all) {
      setFilteredTickets(tickets);
    } else {
      setFilteredTickets(tickets.filter((item) => item.ticket_status === opt));
    }
  };

  //  ------- ui section -------

  const renderTicketsSection = () => {
    return (
      <div className={styles.ticketsSectionWrapperStyle}>
        <div className={styles.headerSectionStyle}>
          {renderSearchSection()}
          {renderFilterSecctiion()}
        </div>
        {renderTicketsList()}
      </div>
    );
  };

  const renderSearchSection = () => {
    return (
      <div className={styles.searchWrapperStyle}>
        <Input
          placeholder={strings.assetTickets}
          inputStyle={styles.searchInputStyle}
          leftIcon={searchIcon}
          leftIconStyle={styles.searchIconStyle}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        {/* <Button
          variant="gray"
          leftIcon={filterIcon}
          btnStyle={styles.searchBtnStyle}
          leftIconStyle={styles.searchIconStyle}
        /> */}
      </div>
    );
  };

  const renderFilterSecctiion = () => {
    return (
      <div className={styles.filterSectionWrapperStyle}>
        <p className={styles.headerTextStyle}>{strings.tickets}</p>
        <Button
          title={selectedFilter}
          rightIcon={chevronDownFillBlackIcon}
          btnStyle={styles.selectBtnStyle}
          variant="light"
          onClick={() => setFiltersPopUp(true)}
          reference={setFiltersRef}
        />
        <PopOver
          reference={filtersRef}
          show={filtersPopUp}
          containerStyle={styles.selectPopUpStyle}
          onClose={() => setFiltersPopUp(false)}
        >
          {options.map((opt, index) => {
            return (
              <div key={'label' + index} className={styles.optionsWrapperStyle}>
                <input
                  type="checkbox"
                  className={classNames(styles.customCheckBoxStyle)}
                  checked={selectedFilter === opt}
                  onChange={(e) => {
                    onSelectFilter(opt);
                    setFiltersPopUp(false);
                  }}
                />
                <span className={styles.filterTextStyle}>{opt}</span>
              </div>
            );
          })}
        </PopOver>
      </div>
    );
  };

  const renderTicketsList = () => {
    return (
      <div className={styles.listWrapperStyle}>
        <InfiniteScroll
          data={filteredTickets}
          loading={loading}
          hasMore={hasMore}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        >
          <div className={styles.listSubWrapperStyle}>
            {filteredTickets && filteredTickets?.length > 0
              ? filteredTickets.map((ticket, index) => (
                  <React.Fragment key={'ticket' + index}>
                    <ListItemCard
                      type="ticket"
                      _id={ticket._id}
                      icon={flagFillWhiteIcon}
                      title={`#${ticket?.ticket_uid}`}
                      status={ticket.ticket_status}
                      description={handleTicketSubject(ticket)}
                      subTitle={ticket?.asset.asset_name}
                      priority={ticket?.is_high_priority}
                      onClick={() => setSearchParams({ selected: ticket._id })}
                      selected={selectedTicket === ticket._id}
                      containerStyle={styles.listCustomStyle}
                    />
                    {loading && index === filteredTickets.length - 1 && (
                      <div className={styles.loaderSectionStyle}>
                        <Loader containerStyle={styles.loaderMobileViewStyle} />
                      </div>
                    )}
                  </React.Fragment>
                ))
              : !initialLoder && !loading && renderEmptySection(strings.noData)}
          </div>
          {!initialLoder && loading && filteredTickets.length === 0 && (
            <div className={styles.loaderSectionStyle}>
              <Loader containerStyle={styles.loaderMobileViewStyle} />
            </div>
          )}
        </InfiniteScroll>
      </div>
    );
  };

  const renderTicketOverViewSecction = () => {
    return (
      <div className={styles.ticketOverViewWrapperStyle}>
        <TicketDetails id={selectedTicket} />
      </div>
    );
  };

  const renderEmptySection = (message) => {
    return (
      <div className={styles.emptyViewStyle}>
        <p className={styles.emptyViewTextStyle}>{message}</p>
      </div>
    );
  };

  return (
    <div className={styles.containerStyle}>
      {initialLoder && <Loader />}
      {(!selectedTicket || width > 480) && renderTicketsSection()}
      {!selectedTicket && width > 480 && renderEmptySection(strings.selectTicketMsg)}
      {selectedTicket && renderTicketOverViewSecction()}
    </div>
  );
};

export default TicketList;
