import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from 'providers/authprovider';
import { account_types } from 'resources/data';
import Loader from 'components/common/loader';

const GuestGuard = (props) => {
  const { userType: guardUserType, children } = props;
  const { isLoggedIn, isInitialized, userType, setAuthError } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      if (!guardUserType.includes(userType)) {
        setAuthError('Please logout from other user type');
      }
    }
  }, [isLoggedIn, guardUserType, userType, setAuthError]);

  let dashBoardRoute = '/dashboard';
  if (
    guardUserType.includes(account_types.admin) ||
    guardUserType.includes(account_types.super_admin) ||
    guardUserType.includes(account_types.employee)
  ) {
    dashBoardRoute = '/dashboard';
  }

  if (!isInitialized) {
    return <Loader />;
  }

  if (isLoggedIn) {
    if (guardUserType.includes(userType)) {
      return <Navigate to={dashBoardRoute} />;
    }
  }
  return <>{children}</>;
};

GuestGuard.propTypes = {
  userType: PropTypes.arrayOf(
    PropTypes.oneOf([account_types.admin, account_types.super_admin, account_types.employee])
  ),
  children: PropTypes.node,
};

export default GuestGuard;
