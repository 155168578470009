import React, { useState } from 'react';
import { assetIcon, chevronRightBlackIcon, optionsVerticalBlackIcon } from 'resources/images';
import Image from 'components/common/image';
import PopOver from 'components/common/pop-over';
import styles from './styles.module.css';

const ChatHeader = (props) => {
  const {
    title,
    image,
    assetUid,
    actionOptions,
    backAction,
    tabOnBackAction = () => {},
    setSelectedOption,
  } = props;
  const [showPopUp, setShowPopUp] = useState(false);
  // eslint-disable-next-line
  const [popupRef, setPopupRef] = useState();

  const popUpShown = () => {
    setShowPopUp(!showPopUp);
  };

  const renderAssetOptionsPopUpSection = () => {
    return (
      <PopOver
        show={showPopUp}
        reference={popupRef}
        onClose={() => setShowPopUp(false)}
        containerStyle={styles.actionPopUpViewStyle}
        placement="left-start"
      >
        <div className={styles.assignPopUpStyles}>
          {actionOptions.map((item, index) => {
            return (
              <p
                key={index}
                onClick={() => {
                  setSelectedOption(item.text);
                  setShowPopUp(false);
                }}
                className={styles.optionTextViewStyle}
              >
                {item.text}
              </p>
            );
          })}
        </div>
      </PopOver>
    );
  };
  return (
    <div className={styles.rightTopHeaderContainerStyles}>
      <div className={styles.rightHeaderLeftBlockStyles}>
        {backAction && (
          <Image
            src={chevronRightBlackIcon}
            alt="Asset Img"
            onClick={tabOnBackAction}
            containerStyle={styles.backImgViewStyle}
          />
        )}
        <div className={styles.assetImgViewStyle}>
          <Image
            src={image ? image : assetIcon}
            alt="Asset Img"
            containerStyle={styles.imgViewStyle}
          />
        </div>
        <div className={styles.rightHeaderLeftSubBlockStyles}>
          <h5 className={styles.headerTextStyle}>{title}</h5>
          <p className={styles.headerSubTextStyle}>
            Serial no:
            <span className={styles.headerSubTextStyles2}>
              {' #'}
              {assetUid}
            </span>
          </p>
        </div>
      </div>
      <Image
        src={optionsVerticalBlackIcon}
        alt="menuIcon"
        onClick={() => popUpShown()}
        containerStyle={styles.menuBtnViewStyle}
      >
        {actionOptions && renderAssetOptionsPopUpSection()}
      </Image>
    </div>
  );
};

export default ChatHeader;
