import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const Info = (props) => {
  const { leftlable, leftValue, rightlable, rightValue, customLableStyle } = props;
  return (
    <div className={styles.eachWrapperStyle}>
      <div className={classNames(styles.infoViewStyle, !rightlable && styles.infoCustomStyle)}>
        <p className={classNames(styles.lableStyle, customLableStyle)}>{leftlable}</p>
        <p className={styles.textStyle}>{leftValue ? leftValue : '------'}</p>
      </div>
      {rightlable && (
        <div className={styles.infoViewStyle}>
          <p className={classNames(styles.lableStyle, customLableStyle)}>{rightlable}</p>
          <p className={styles.textStyle}>{rightValue}</p>
        </div>
      )}
    </div>
  );
};

Info.propTypes = {
  leftlable: PropTypes.string,
  leftValue: PropTypes.string,
  rightlable: PropTypes.string,
  rightValue: PropTypes.string,
  customLableStyle: PropTypes.string,
};

export default Info;
