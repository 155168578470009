import useWindowDimensions from 'hooks/useWindowDimensions';
import React, { createContext, useState, useMemo, useContext, useEffect } from 'react';

const AppDataContext = createContext();

const AppDataProvider = (props) => {
  const { width } = useWindowDimensions();
  const [showNavMenu, setShowNavMenu] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    if (width > 480) {
      setShowHeader(true);
    }
  }, [width]);

  const memoizedValue = useMemo(
    () => ({
      showNavMenu,
      setShowNavMenu,
      showHeader,
      setShowHeader,
      breadcrumbs,
      setBreadcrumbs,
    }),
    [showNavMenu, setShowNavMenu, showHeader, setShowHeader, breadcrumbs, setBreadcrumbs]
  );

  return <AppDataContext.Provider value={memoizedValue}>{props.children}</AppDataContext.Provider>;
};

export default AppDataProvider;

export const useAppData = () => {
  const context = useContext(AppDataContext);

  if (context === undefined) {
    throw new Error('useAppData must be used within AppDataProvider');
  }
  return context;
};
