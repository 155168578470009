import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/common/image';
import classNames from 'classnames';
import { checkWhiteIcon } from 'resources/images';
import styles from './styles.module.css';

const RadioButton = (props) => {
  const {
    selected,
    label,
    onSelect = () => {},
    icon,
    containerStyle,
    labelStyle,
    radioStyle,
    iconStyle,
  } = props;

  return (
    <div className={classNames(styles.containerStyle, containerStyle)}>
      <div
        onClick={onSelect}
        className={classNames(styles.radioStyle, selected && styles.selectedStyle, radioStyle)}
      >
        {selected && (
          <Image
            src={icon || checkWhiteIcon}
            containerStyle={classNames(styles.iconStyle, iconStyle)}
          />
        )}
      </div>
      {label && <p className={classNames(styles.labelStyle, labelStyle)}>{label}</p>}
    </div>
  );
};

RadioButton.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.bool,
  icon: PropTypes.string,
  onSelect: PropTypes.func,
  containerStyle: PropTypes.string,
  labelStyle: PropTypes.string,
  radioStyle: PropTypes.string,
  iconStyle: PropTypes.string,
};

export default RadioButton;
