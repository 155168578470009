import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from 'components/common/image';
import { checkWhiteIcon } from 'resources/images';
import styles from './styles.module.css';

const CheckBox = (props) => {
  const { checked, onChange, icon, containerStyle, title, titleStyle, boxStyle, iconStyle } = props;

  return (
    <div className={classNames(styles.wrapperStyle, containerStyle)}>
      <div
        className={classNames(styles.boxStyle, checked && styles.checkedBoxStyle, boxStyle)}
        onClick={onChange}
      >
        {checked && (
          <Image
            src={icon || checkWhiteIcon}
            containerStyle={classNames(styles.iconStyle, iconStyle)}
          />
        )}
      </div>
      {title && <p className={classNames(styles.titleStyle, titleStyle)}>{title}</p>}
    </div>
  );
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  icon: PropTypes.string,
  containerStyle: PropTypes.string,
  title: PropTypes.string,
  titleStyle: PropTypes.string,
  boxStyle: PropTypes.string,
  iconStyle: PropTypes.string,
};

export default CheckBox;
