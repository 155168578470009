import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/button';
import {
  chevronLeftBlackIcon,
  closeLightGrayIcon,
  multiSelectIcon,
  optionsHorizentBlackIcon,
  pencilEditBlackIcon,
  pencilEditBlueIcon,
  pencilLightGrayIcon,
  sampleImg,
} from 'resources/images';
import Image from 'components/common/image';
import Input from 'components/common/input';
import PopOver from 'components/common/pop-over';
import MultiSelectInput from 'components/common/multi-seleect-input';
import { useUserData } from 'providers/userdataprovider';
import { takeActionToAssignTaskApi } from 'networking/api/alltickets';
import Loader from 'components/common/loader';
import { useStrings } from 'providers/stringsprovider';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { markAsRevertApi } from 'networking/api/tickets';
import { useToast } from 'providers/toastprovider';
import styles from './styles.module.css';
import { debounce } from 'lodash';
import { getAllEmployeesWithLastAssetApi } from 'networking/api/employee';
import { useAuth } from 'providers/authprovider';
import { account_types } from 'resources/data';

const TicketAssign = (props) => {
  const { ticket, onClose, setTicket } = props;
  const toast = useToast();
  const { company } = useAuth();
  const { tickets, setTickets, users, setUsers } = useUserData();
  const { strings } = useStrings();
  const { width } = useWindowDimensions();
  const [ticketData, setTicketData] = useState(null);
  const [usersLoading, setUsersLoading] = useState(false);
  const [userSearchQuery, setUserSearchQuery] = useState('');
  const [debouncedUsersQuery, setDebouncedUsersQuery] = useState('');
  const [currentUsersPage, setCurrentUsersPage] = useState(1);
  const [hasMoreUsers, setHasMoreUsers] = useState(false);
  const [isShowCheckListModal, setIsShowCheckListModal] = useState(false);
  const [checkListRef, setCheckListRef] = useState(null);
  const [checkListOptRef, setCheckListOptRef] = useState(null);
  const [checkListOptNestedRef, setCheckListOptNestedRef] = useState(null);
  const [deleteChecklistRef, setDeleteChecklistRef] = useState(null);
  const [editChecklistItemRef, setEditChecklistItemRef] = useState(null);
  const [checkListTitle, setCheckListTitle] = useState('');
  const [taskData, setTaskData] = useState({
    subject: ticket?.subject || '',
    note: ticket?.note || '',
    check_list: ticket?.tasks || [],
    users: ticket?.ticket_users || [],
    is_high_priority: ticket?.is_high_priority || '',
    difficulty: ticket?.difficulty || '',
  });
  const [clItemTitle, setClItemTitle] = useState('');
  const [clTitleIndex, setClTitleIndex] = useState(-1);
  const [showInputIndex, setShowInputIndex] = useState(-1);
  const [clTitleModal, setClTitleModal] = useState(false);
  const [clModalTitle, setClModalTitle] = useState('');
  const [clDeleteModal, setClDeleteModal] = useState(false);
  const [clInnerItemTitle, setClInnerItemTitle] = useState('');
  const [clItemTitleModal, setClItemTitleModal] = useState(false);
  const [clItemTitleIndex, setClItemTitleIndex] = useState(-1);
  const [labelPopUp, setLabelPopUp] = useState(false);
  const [labelRef, setLabelRef] = useState(null);
  const [selectedLabels, setSelectedLabels] = useState([strings.addAssignee]);
  const [selectTaskPriority, setSelectTaskPriority] = useState();
  const [taskActionErrors, setTaskActionErrors] = useState({
    departments: '',
    employees: '',
    taskPriority: '',
    difficultyLevel: '',
  });
  const [selectDifficultyLevel, setSelectDifficultyLevel] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [assignPreview, setAssignPreview] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (ticket) {
      setTicketData(ticket);
      if (ticket?.ticket_status !== 'Complaint raised') {
        setSelectedEmployees(
          ticket?.ticket_users
            .filter((item) => item.user.account_type !== account_types.super_admin)
            .map((item) => ({
              label: item.user?.full_name,
              value: item.user?._id,
            }))
        );
      }
    }
  }, [ticket]);

  const debouncedUserSearch = useMemo(
    () =>
      debounce((searchValue) => {
        setDebouncedUsersQuery(searchValue);
        setCurrentUsersPage(1);
      }, 500),
    []
  );

  useEffect(() => {
    debouncedUserSearch(userSearchQuery);
  }, [userSearchQuery, debouncedUserSearch]);

  const getUsers = useCallback(async () => {
    try {
      if (!debouncedUsersQuery) {
        setUsers([]);
        return;
      }
      if (currentUsersPage === 1) {
        setUsers([]);
      }
      setUsersLoading(true);
      const response = await getAllEmployeesWithLastAssetApi(
        company?._id,
        currentUsersPage,
        debouncedUsersQuery,
        10
      );
      setUsers((prev) => {
        if (currentUsersPage === 1) {
          return response.data.data.users;
        } else {
          return [...prev, ...response.data.data.users];
        }
      });
      setHasMoreUsers(response.data.data?.meta?.hasNextPage);
      setUsersLoading(false);
    } catch (err) {
      setUsersLoading(false);
    }
  }, [company, currentUsersPage, debouncedUsersQuery, setUsers]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const taskLables = [strings.addAssignee, strings.taskPriority, strings.difficulty];

  const taskActionPriorityData = [strings.yes, strings.no];

  const taskActionDifficultyLevelData = [strings.easy, strings.moderate, strings.expert];

  const handleSelectDifficultyLevel = (item) => {
    setSelectDifficultyLevel((prevSelected) => {
      if (prevSelected === item) {
        return null;
      } else {
        return item;
      }
    });
    setTaskActionErrors((errors) => ({ ...errors, difficultyLevel: '' }));
  };

  const handleTaskPrioritySelection = (item) => {
    setSelectTaskPriority((prevSelected) => {
      if (prevSelected === item) {
        return null;
      } else {
        return item;
      }
    });
    setTaskActionErrors((errors) => ({ ...errors, taskPriority: '' }));
  };

  const taskActionModalValidation = () => {
    const errors = {
      subject: !taskData.subject && strings.enterSubject,
      note: !taskData.note && strings.enterNote,
      employees: selectedEmployees.length <= 0 && strings.selectAtLeastOneEmploy,
    };

    setTaskActionErrors(errors);
    if (Object.values(errors).every((error) => !error)) {
      // call task action api call here after check all validations
      setAssignPreview(true);
    }
  };

  // function to handle Take Action  apicall
  const handleTakeActionToAllocateTicket = async () => {
    try {
      setLoader(true);
      const selectedEmployeeId = selectedEmployees.map((item) => item.value);
      const data = {
        ticket: ticketData?._id,
        subject: taskData.subject,
        note: taskData.note,
        users: selectedEmployeeId,
        is_high_priority: selectTaskPriority === strings.yes ? true : false,
        check_list: taskData.check_list,
      };
      if (selectDifficultyLevel) {
        data.difficulty = selectDifficultyLevel;
      }
      let response = null;
      if (ticketData?.ticket_status === 'Complaint raised') {
        response = await takeActionToAssignTaskApi(data);
      } else {
        response = await markAsRevertApi(data);
      }
      setTicket(response.data.data);
      let previous_data = [...tickets];
      let ticketIndex = previous_data.findIndex((data) => data._id === ticketData?._id);
      previous_data[ticketIndex].ticket_status = response.data.data.ticket_status;
      previous_data[ticketIndex].updated_at = response.data.data?.updated_at || new Date();
      previous_data[ticketIndex].last_activity = response.data.data.activity[0];
      previous_data[ticketIndex].is_high_priority = response.data.data.is_high_priority;
      previous_data[ticketIndex].subject = response.data.data.subject;
      previous_data[ticketIndex].note = response.data.data.note;
      setTickets(previous_data);
      onClose(false);
      resetTaskActionData();
      setLoader(false);
      toast.success(response.data.message);
    } catch (error) {
      setLoader(false);
      setErrorMsg(error.message);
    }
  };

  const resetTaskActionData = () => {
    setSelectedEmployees([]);
    setSelectTaskPriority('');
    setTaskData({
      subject: '',
      note: '',
      check_list: [],
      users: [],
      is_high_priority: '',
      difficulty: '',
    });
  };

  // ------ ui section -------

  const renderHeader = () => {
    return (
      <div className={styles.headerViewStyle}>
        <p className={styles.headerTextStyle}>
          {ticketData?.ticket_status === 'Complaint raised' ? strings.takeAction : 'Revert Action'}
        </p>
        {width > 480 && renderBtnsSection()}
      </div>
    );
  };

  const renderBtnsSection = () => {
    return (
      <div className={styles.headerBtnsViewStyle}>
        <Button
          title={strings.cancel}
          variant="light"
          onClick={() => {
            onClose(false);
            resetTaskActionData();
          }}
          btnStyle={styles.headerBtnStyle}
        />
        <Button
          title={strings.continue}
          onClick={() => taskActionModalValidation()}
          btnStyle={
            selectedEmployees.length > 0 && taskData.subject && taskData.note
              ? styles.activeButtonStyle
              : styles.contineuBtnStyle
          }
          disabled={!taskData.check_list.every((cl) => cl.items.length > 0)}
        />
      </div>
    );
  };

  const renderTaskInfo = () => {
    return (
      <div className={styles.taskInfoWrapperStyles}>
        <div className={styles.taskLeftWrapperStyle}>
          <p className={styles.taskLeftTitleStyles}>{strings.taskInfo}</p>
        </div>
        <div className={styles.taskRightWrapperStyles}>
          <div className={styles.taskAssetStyles}>
            <span className={styles.taskAssetTitleStyles}>
              {`#${ticketData?.ticket_uid}: ${ticketData?.asset?.asset_name}`}
            </span>
            <div className={styles.taskAssetImgWrapperStyles}>
              <Image
                src={ticketData?.image ? ticketData?.image : sampleImg}
                alt={ticketData?.asset?.asset_name}
                containerStyle={styles.taskAssetImgStyles}
              />
            </div>
          </div>
          <Input
            labelText={strings.taskSubject}
            name="subject"
            value={taskData.subject}
            onChange={(e) => setTaskData({ ...taskData, subject: e.target.value })}
          />
          <Input
            type="textarea"
            labelText={strings.description}
            name="note"
            value={taskData.note}
            onChange={(e) => setTaskData({ ...taskData, note: e.target.value })}
            inputStyle={styles.textAreaStyle}
          />
        </div>
      </div>
    );
  };

  const renderChecklistSection = () => {
    return (
      <div className={styles.taskCLWrapperStyle}>
        <div className={styles.taskLeftWrapperStyle}>
          <p className={styles.taskLeftTitleStyles}>{strings.checklist}</p>
          <Button
            title={strings.addChecklist}
            onClick={() => {
              setIsShowCheckListModal(true);
              setClItemTitle('');
            }}
            leftIcon={multiSelectIcon}
            variant="light"
            reference={setCheckListRef}
            btnStyle={styles.btnViewStyle}
          />
          {renderCheckListModal()}
        </div>
        {renderCheckListRightSection()}
      </div>
    );
  };

  const renderCheckListRightSection = () => {
    return (
      <div className={styles.taskCLRightWraperStyle}>
        {taskData.check_list.length === 0 && (
          <div className={styles.emptyCheckListStyle}>
            {strings.checklistNote}&nbsp;<span>{strings.showMore}</span>
          </div>
        )}
        {taskData.check_list.map((cl, cl_index) => (
          <div
            key={'cl' + cl_index}
            className={styles.taskCLContentWrapperStyle}
            onClick={() => {
              setShowInputIndex(cl_index);
              if (cl_index !== showInputIndex) {
                setClItemTitle('');
              }
            }}
          >
            <div className={styles.taskCLTitleWrapperStyle}>
              <p className={styles.taskCLTitleStyle}>{cl?.title}</p>
              <div className={styles.taskCLActionImgWrapperStyle}>
                <Image
                  src={optionsHorizentBlackIcon}
                  alt=""
                  onClick={(e) => {
                    setClTitleIndex(cl_index);
                    setCheckListOptRef(e.target);
                  }}
                  containerStyle={styles.taskCLActionImgStyle}
                />
                <PopOver
                  show={clTitleIndex === cl_index}
                  showOverlay
                  containerStyle={styles.taskChecklistModalStyle}
                  overlayStyle={styles.customOverlayStyle}
                  reference={checkListOptRef}
                  onClose={() => {
                    if (
                      clTitleModal === false &&
                      clTitleIndex === cl_index &&
                      clDeleteModal === false
                    ) {
                      setClTitleIndex(-1);
                    }
                  }}
                >
                  <div className={styles.menuWrapperStyle}>
                    <div
                      className={styles.menuItemStyle}
                      onClick={() => {
                        setClTitleModal(true);
                        setClModalTitle(cl.title);
                      }}
                      ref={setCheckListOptNestedRef}
                    >
                      {strings.editTitle}
                      <PopOver
                        show={clTitleModal}
                        showOverlay
                        containerStyle={styles.checkListTitleModalStyle}
                        overlayStyle={styles.customOverlayStyle}
                        reference={checkListOptNestedRef}
                        onClose={() => {
                          if (clTitleModal) {
                            setClTitleModal(false);
                          }
                        }}
                      >
                        <Input
                          lableText={strings.editChecklistTitle}
                          value={clModalTitle}
                          onChange={(e) => {
                            setClModalTitle(e.target.value);
                          }}
                        />
                        <div className={styles.checkListModalBtnWrapperStyle}>
                          <Button
                            title="Cancel"
                            variant="light"
                            onClick={() => setClTitleModal(false)}
                            btnStyle={styles.checkListModalBtnStyle}
                          />
                          <Button
                            title="Save"
                            btnStyle={styles.checkListModalBtnStyle}
                            disabled={!clModalTitle || clModalTitle === cl?.title}
                            onClick={() => {
                              setClTitleModal(false);
                              let task = taskData;
                              task.check_list[clTitleIndex].title = clModalTitle;
                              setTaskData(task);
                              setClTitleIndex(-1);
                              setClModalTitle('');
                            }}
                          />
                        </div>
                      </PopOver>
                    </div>
                    <div
                      className={styles.menuItemStyle}
                      ref={setDeleteChecklistRef}
                      onClick={() => setClDeleteModal(true)}
                    >
                      {strings.deleteChecklist}
                      <PopOver
                        show={clDeleteModal}
                        showOverlay
                        containerStyle={styles.checkListDeleteModalStyle}
                        overlayStyle={styles.customOverlayStyle}
                        reference={deleteChecklistRef}
                        onClose={() => {
                          if (clDeleteModal) {
                            setClDeleteModal(false);
                          }
                        }}
                      >
                        <p className={styles.clModalDescStyle}>
                          {`${strings.deleteMsg} "${cl.title}" ${strings.permanently}?`}{' '}
                          <span className={styles.clModalDesc1Style}>
                            {strings.deleteChecklistDesc}
                          </span>
                        </p>
                        <div className={styles.checkListModalBtnWrapperStyle}>
                          <Button
                            title="Cancel"
                            variant="light"
                            onClick={() => setClDeleteModal(false)}
                            btnStyle={styles.checkListModalBtnStyle}
                          />
                          <Button
                            title="Yes"
                            btnStyle={styles.dangerBtnStyle}
                            onClick={() => {
                              let task = taskData;
                              task.check_list.splice(clTitleIndex, 1);
                              setTaskData(task);
                              setClTitleIndex(-1);
                              setClDeleteModal(false);
                            }}
                          />
                        </div>
                      </PopOver>
                    </div>
                  </div>
                </PopOver>
              </div>
            </div>
            <div className={styles.taskCLItemsWrapperStyle}>
              {cl &&
                cl.items?.map((cli, cli_index) => (
                  <div key={'cli' + cli_index} className={styles.taskCLItemWrapperStyle}>
                    <ul className={styles.taskCLItemUlStyle}>
                      <li className={styles.taskCLItemTitleStyle}>{cli.item_title}</li>
                    </ul>
                    <div className={styles.taskCLItemActionWrapperStyle}>
                      <Image
                        src={pencilLightGrayIcon}
                        alt=""
                        containerStyle={styles.clItemsImgWrapperStyle}
                        onClick={(e) => {
                          setClInnerItemTitle(cli.item_title);
                          setClItemTitleModal(true);
                          setClItemTitleIndex(cli_index);
                          setEditChecklistItemRef(e.target);
                        }}
                      >
                        <PopOver
                          show={
                            cl_index === showInputIndex &&
                            cli_index === clItemTitleIndex &&
                            clItemTitleModal
                          }
                          showOverlay
                          containerStyle={styles.editCheckListItemModalStyle}
                          overlayStyle={styles.customOverlayStyle}
                          reference={editChecklistItemRef}
                          onClose={() => {
                            if (cli_index === clItemTitleIndex) {
                              setClItemTitleModal(false);
                              setClItemTitleIndex(-1);
                              setClInnerItemTitle('');
                            }
                          }}
                        >
                          <Input
                            lableText="Edit item*"
                            value={clInnerItemTitle}
                            onChange={(e) => {
                              setClInnerItemTitle(e.target.value);
                            }}
                          />
                          <div className={styles.checkListModalBtnWrapperStyle}>
                            <Button
                              title="Cancel"
                              color="light"
                              onClick={() => {
                                setClItemTitleModal(false);
                                setClInnerItemTitle('');
                              }}
                              btnStyle={styles.checkListModalBtnStyle}
                            />
                            <Button
                              title="Save"
                              color="primary"
                              btnStyle={styles.checkListModalBtnStyle}
                              disabled={!clInnerItemTitle || clInnerItemTitle === cli.item_title}
                              onClick={() => {
                                setClItemTitleModal(false);
                                let task = taskData;
                                task.check_list[cl_index].items[cli_index].item_title =
                                  clInnerItemTitle;
                                setTaskData(task);
                                setClItemTitleModal(false);
                                setClItemTitleIndex(-1);
                                setClInnerItemTitle('');
                              }}
                            />
                          </div>
                        </PopOver>
                      </Image>
                      <Image
                        src={closeLightGrayIcon}
                        containerStyle={styles.clItemsImgWrapperStyle}
                        onClick={() => {
                          let task = { ...taskData };
                          let filtered = task.check_list[cl_index].items.filter(
                            (_, index) => index !== cli_index
                          );
                          task.check_list[cl_index].items = filtered;
                          setTaskData(task);
                        }}
                        alt=""
                      />
                    </div>
                  </div>
                ))}
            </div>
            {showInputIndex === cl_index && (
              <React.Fragment>
                <div className={styles.itemInputWrapperStyle}>
                  <Input
                    value={clItemTitle}
                    onChange={(e) => setClItemTitle(e.target.value)}
                    placeholder={strings.addChecklistItem}
                    customStyle={styles.addItemInputStyle}
                  />
                  <div className={styles.itemInputWrapperBtnsStyle}>
                    <Button
                      type="button"
                      title={strings.add}
                      btnStyle={styles.addItemBtnStyle}
                      disabled={clItemTitle === ''}
                      onClick={() => {
                        let task = taskData;
                        task.check_list[showInputIndex].items.push({
                          item_title: clItemTitle,
                        });
                        setTaskData(task);
                        setClItemTitle('');
                      }}
                    />
                    <Button type="button" title={strings.clear} btnStyle={styles.clearBtnStyle} />
                  </div>
                </div>
                <Button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowInputIndex(-1);
                    setClItemTitle('');
                  }}
                  title={'Done'}
                  btnStyle={styles.doneBtnStyle}
                />
              </React.Fragment>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderCheckListModal = () => {
    return (
      <PopOver
        show={isShowCheckListModal}
        showOverlay
        containerStyle={styles.clModalViewStyle}
        reference={checkListRef}
      >
        <div className={styles.taskCLFormStyle}>
          <Input
            lableText={strings.checklistTitle}
            value={checkListTitle}
            onChange={(e) => setCheckListTitle(e.target.value)}
            placeholder={strings.checklistplacheholder}
          />
          <div className={styles.taskCLModalActionStyle}>
            <Button
              title={strings.cancel}
              btnStyle={styles.resetButtonStyles}
              onClick={() => {
                setIsShowCheckListModal(false);
                setCheckListTitle('');
              }}
            />
            <Button
              title={strings.save}
              btnStyle={
                checkListTitle ? styles.activeProceedButtonStyles : styles.proceedButtonStyles
              }
              disabled={!checkListTitle}
              onClick={() => {
                setTaskData({
                  ...taskData,
                  check_list: [
                    ...taskData.check_list,
                    {
                      title: checkListTitle,
                      items: [],
                    },
                  ],
                });
                setCheckListTitle('');
                setIsShowCheckListModal(false);
                setShowInputIndex(taskData.check_list.length);
              }}
            />
          </div>
        </div>
      </PopOver>
    );
  };

  const renderLabelPopUp = () => {
    return (
      <PopOver
        reference={labelRef}
        show={labelPopUp}
        containerStyle={styles.labelPopUpStyle}
        onClose={() => setLabelPopUp(false)}
        placement="top-start"
      >
        {taskLables.map((item, index) => (
          <div key={'label' + index} className={styles.labelWrapperStyle}>
            <input
              type="checkbox"
              className={[
                styles.customCheckBoxStyle,
                item === strings.addAssignee && styles.disabledCheckBoxStyle,
              ].join(' ')}
              checked={selectedLabels.includes(item)}
              disabled={item === strings.addAssignee}
              selectedLabels
              onChange={(e) => {
                setSelectedLabels((prev) => {
                  if (prev.includes(item)) {
                    let data = [...prev];
                    return data.filter((label) => label !== item);
                  } else {
                    return [...prev, item];
                  }
                });
                // scrollScreen();
              }}
            />
            <span className={styles.labelTextStyle}>{item}</span>
          </div>
        ))}
      </PopOver>
    );
  };

  const renderAssignToSection = () => {
    return (
      <div className={styles.taskAssignWrapperStyle}>
        <div className={styles.taskLeftWrapperStyle}>
          <p className={styles.taskLeftTitleStyles}>{strings.assignToRequired}</p>
          <Button
            type="button"
            title={strings.addNewField}
            onClick={() => {
              setLabelPopUp(true);
            }}
            leftIcon={pencilEditBlackIcon}
            variant="light"
            btnStyle={styles.taskLeftBtnStyle}
            reference={setLabelRef}
          />
          {renderLabelPopUp()}
        </div>
        <div className={styles.taskRightWrapperStyles}>
          <div className={styles.selectUsersBlockViewStyles}>
            <MultiSelectInput
              inputLabel={strings.selectAssignee}
              placeholder={strings.searchUserLable}
              options={users.map((user) => {
                return {
                  label: user.full_name,
                  value: user._id,
                  desc: user.employee_type,
                };
              })}
              multi={ticketData?.ticket_status === 'Complaint raised' ? false : true}
              selectedData={selectedEmployees}
              setSelectedData={(data) => {
                setSelectedEmployees(data);
              }}
              searchText={userSearchQuery}
              setSearchText={(val) => setUserSearchQuery(val)}
              dataLoading={usersLoading}
              hasMore={hasMoreUsers}
              setCurrentPage={setCurrentUsersPage}
            />
          </div>
          {selectedLabels.includes(strings.taskPriority) && renderTicketPrioritySection()}
          {selectedLabels.includes(strings.difficulty) && renderTicketDifficultLevelSection()}
        </div>
      </div>
    );
  };

  const renderTicketPrioritySection = () => {
    return (
      <div className={styles.ticketPrirotyBlockStyles}>
        <p className={styles.ticketProrityTextStyles}>{strings.highPriorityLable}</p>
        <div className={styles.yesOrNoButtonTabStyles}>
          {taskActionPriorityData &&
            taskActionPriorityData.map((item, index) => {
              return (
                <Button
                  key={index}
                  title={item}
                  btnStyle={
                    selectTaskPriority === item
                      ? styles.activeTaskPriorityStyles
                      : styles.taskPriorityButtonTabStyles
                  }
                  onClick={() => handleTaskPrioritySelection(item)}
                />
              );
            })}
        </div>
        {taskActionErrors.taskPriority && (
          <p className={styles.errorTextStyle}>{taskActionErrors}</p>
        )}
      </div>
    );
  };

  const renderTicketDifficultLevelSection = () => {
    return (
      <div className={styles.ticketDifficultLevelBlockStyles}>
        <p className={styles.ticketDifficultyTextStyles}>{strings.ticketDifficultye}</p>
        <div className={styles.difficultyLevelBlockStyles}>
          {taskActionDifficultyLevelData &&
            taskActionDifficultyLevelData.map((item, index) => {
              return (
                <Button
                  key={index}
                  title={item}
                  image={false}
                  leftIcon={selectDifficultyLevel === item ? item.icon : null}
                  btnStyle={
                    selectDifficultyLevel
                      ? selectDifficultyLevel === strings.easy && selectDifficultyLevel === item
                        ? styles.easyBgStyle
                        : selectDifficultyLevel === strings.moderate &&
                          selectDifficultyLevel === item
                        ? styles.moderateBgStyle
                        : selectDifficultyLevel === strings.expert && selectDifficultyLevel === item
                        ? styles.expertBgStyle
                        : styles.defaultDifficultyBgStyle
                      : styles.defaultDifficultyBgStyle
                  }
                  customimgContainerStyle={styles.difficultyImgWrapperStyle}
                  onClick={() => {
                    handleSelectDifficultyLevel(item);
                  }}
                />
              );
            })}
        </div>
        {taskActionErrors.difficultyLevel && (
          <p className={styles.errorTextStyle}>{taskActionErrors.difficultyLevel}</p>
        )}
      </div>
    );
  };

  const renderPreviewSection = () => {
    return (
      <React.Fragment>
        <div className={styles.previewHeaderWrapperStyle}>
          <div className={styles.phLeftWrapperStyle}>
            <Image
              src={chevronLeftBlackIcon}
              containerStyle={styles.phLeftIconWrapperStyle}
              onClick={() => setAssignPreview(false)}
            />
            <span className={styles.phTitleStyle}>{strings.preview}</span>
          </div>
          <Button
            title={strings.publishTask}
            btnStyle={styles.phPublishBtnStyle}
            onClick={() => handleTakeActionToAllocateTicket()}
          />
        </div>
        <div className={styles.previewContentWrapperStyle}>
          <div className={styles.pcSubWrapperStyle}>
            <div className={styles.pcsHeaderWrapperStyle}>
              <span className={styles.pchTitleStyle}>{strings.taskInfo}</span>
              <div
                className={styles.editTaskBtnWrapperStyle}
                onClick={() => setAssignPreview(false)}
              >
                <Image
                  src={pencilEditBlueIcon}
                  alt={strings.edit}
                  containerStyle={styles.editBtnStyle}
                />
                <span className={styles.editTitleStyle}>{strings.edit}</span>
              </div>
            </div>
            <div className={styles.pcsContentWrapperStyle}>
              <div className={styles.taskAssetStyles}>
                <span className={styles.taskAssetTitleStyles}>
                  {`#${ticketData?.ticket_uid}: ${ticketData?.asset?.asset_name}`}
                </span>
                <div className={styles.taskAssetImgWrapperStyles}>
                  <Image
                    src={ticketData?.image || sampleImg}
                    alt={ticketData?.asset?.asset_name}
                    containerStyle={styles.taskAssetImgStyles}
                  />
                </div>
              </div>
              <div>
                <p className={styles.pciSubjectStyle}>{taskData?.subject}</p>
                <span className={styles.pciNoteStyle}>{taskData?.note}</span>
              </div>
            </div>
          </div>
          {taskData.check_list.length > 0 &&
            taskData.check_list.map((cl, index) => (
              <div key={'clp' + index} className={styles.pcSubWrapperStyle}>
                <div className={styles.pcsHeaderWrapperStyle}>
                  <span className={styles.pchTitleStyle}>{cl.title}</span>
                  <div
                    className={styles.editTaskBtnWrapperStyle}
                    onClick={() => setAssignPreview(false)}
                  >
                    <Image src={pencilEditBlueIcon} alt="Edit" />
                    <span className={styles.editTitleStyle}>Edit</span>
                  </div>
                </div>
                <div className={styles.pcsContentWrapperStyle}>
                  {cl?.items?.length > 0 &&
                    cl.items.map((cli, index) => (
                      <div key={'clip' + index} className={styles.labelWrapperStyle}>
                        <input
                          type="checkbox"
                          className={styles.customCheckBoxStyle}
                          checked={false}
                          onChange={(e) => {}}
                        />
                        <span className={styles.labelTextStyle}>{cli.item_title}</span>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          <div className={styles.pcSubWrapperStyle}>
            <div className={styles.pcsHeaderWrapperStyle}>
              <span className={styles.pchTitleStyle}>{strings.assignToRequired}</span>
              <div
                className={styles.editTaskBtnWrapperStyle}
                onClick={() => setAssignPreview(false)}
              >
                <Image src={pencilEditBlueIcon} alt={strings.edit} />
                <span className={styles.editTitleStyle}>{strings.edit}</span>
              </div>
            </div>
            <div className={styles.pcsContentWrapperStyle}>
              <div>
                <div className={styles.assignesViewStyle}>
                  <p className={styles.pcsAssignTitleStyle}>{strings.selectAssigne}&nbsp;:&nbsp;</p>
                  {selectedEmployees.map((emp, index) => (
                    <p key={index} className={styles.pcsAssignSubTitleStyle}>
                      {emp.label}
                      {index !== 0 && index !== selectedEmployees.length - 1 && ','}
                    </p>
                  ))}
                </div>
                {selectedLabels.includes(strings.taskPriority) && (
                  <p className={styles.pcsAssignTitleStyle}>
                    {strings.highPriority} &nbsp;:&nbsp;
                    <span className={styles.pcsAssignSubTitleStyle}>{selectTaskPriority}</span>
                  </p>
                )}
                {selectedLabels.includes(strings.difficulty) && (
                  <p className={styles.pcsAssignTitleStyle}>
                    {strings.ticketDifficultye}&nbsp;:&nbsp;
                    <span className={styles.pcsAssignSubTitleStyle}>{selectDifficultyLevel}</span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderTicketSection = () => {
    return (
      <div className={styles.takeActionWrapperStyle}>
        <div className={styles.takeActionMiddleWrapperStyle}>
          {renderTaskInfo()}
          {renderChecklistSection()}
          {renderAssignToSection()}
          {errorMsg && <p className={styles.errorTextStyle}>{errorMsg}</p>}
          {width <= 480 && renderBtnsSection()}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.containerStyle}>
      {loader && <Loader />}
      {assignPreview && renderPreviewSection()}
      {!assignPreview && (
        <React.Fragment>
          {renderHeader()}
          {renderTicketSection()}
        </React.Fragment>
      )}
    </div>
  );
};

TicketAssign.propTypes = {
  ticket: PropTypes.object,
  onClose: PropTypes.func,
  setTicket: PropTypes.func,
};
export default TicketAssign;
