import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { arrowLeftIcon, closePurpleIcon, optionsVerticalGrayIcon } from 'resources/images';
import Button from 'components/common/button';
import ReminderCard from 'components/reminder-card';
import Image from 'components/common/image';
import Attachments from 'sections/assets/attachments';
import styles from './styles.module.css';

const ReminderDetails = (props) => {
  const { reminder, onCloseAction = () => {}, type } = props;

  return (
    <div className={styles.remainderOverViewStyle}>
      <div className={styles.overViewHeaderStyle}>
        {type === 'reminder_alert' ? (
          <p className={styles.overViewHeaderTextStyle}>Reminder alert</p>
        ) : (
          <React.Fragment>
            <Image
              src={arrowLeftIcon}
              onClick={onCloseAction}
              containerStyle={styles.arrowBackIconStyle}
            />
            <p className={styles.backTextStyle}>Back</p>
          </React.Fragment>
        )}
      </div>
      <div className={styles.remainderContentViewStyle}>
        <ReminderCard
          data={reminder}
          rightIcon={optionsVerticalGrayIcon}
          customContainerStyle={styles.customContainerStyle}
          status={type ? true : false}
        />
        <div className={styles.reminderDetailsViewStyle}>
          <div className={styles.curvedViewStyle}></div>
          <div className={styles.reminderDetailsSubViewStyle}>
            <div className={styles.reminderDetailsHeaderStyle}>
              <p className={styles.reminderDetailsHeaderTextStyle}>Notes</p>
            </div>
            <p className={styles.reminderDetailsDescTextStyle}>{reminder?.notes || '---'}</p>
          </div>
        </div>
        <div className={styles.reminderDetailsViewStyle}>
          <div className={styles.curvedViewStyle}></div>
          <div className={styles.reminderDetailsSubViewStyle}>
            <div className={styles.reminderDetailsHeaderStyle}>
              <p className={styles.reminderDetailsHeaderTextStyle}>Frequency</p>
            </div>
            <p className={styles.reminderDetailsDescTextStyle}>
              <p className={styles.remainderTimeTextStyle}>
                {reminder?.type === 'repeat'
                  ? `Repeats every ${reminder?.repeat_interval?.value} ${
                      reminder?.repeat_interval?.type
                    } ${
                      reminder?.end_date
                        ? `till ${moment(reminder?.end_date).format('DD/MM/YYYY')}`
                        : ''
                    }`
                  : `Reminds on ${moment(reminder?.start_date).format('DD/MM/YYYY')} at ${moment(
                      reminder?.start_date
                    ).format('LT')} `}
              </p>
            </p>
          </div>
        </div>
        <div className={styles.attachmentsViewStyle}>
          <p className={styles.attachmentsTextStyle}>Attachments</p>
          {reminder?.attachments.length > 0 ? (
            <Attachments
              data={reminder?.attachments || []}
              customContainerStyle={styles.customattachContainerStyle}
            />
          ) : (
            <p className={styles.reminderDetailsDescTextStyle}>Not available</p>
          )}
        </div>
        {type === 'reminder_alert' && <div className={styles.dividerStyle}></div>}
        {type === 'reminder_alert' && (
          <Button
            title="Dismiss"
            variant="light"
            leftIcon={closePurpleIcon}
            btnStyle={styles.dismissBtnStyle}
            size="md"
            onClick={onCloseAction}
          />
        )}
      </div>
    </div>
  );
};

ReminderDetails.propTypes = {
  reminder: PropTypes.object,
  onCloseAction: PropTypes.func,
  type: PropTypes.string,
};

export default ReminderDetails;
