import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/common/image';
import { priorityIcon } from 'resources/images';
import classNames from 'classnames';
import Chip from 'components/common/chip';
import styles from './styles.module.css';

const ListItemCard = (props) => {
  const {
    _id,
    title,
    subTitle,
    description,
    icon,
    status,
    priority,
    onClick = () => {},
    selected,
    containerStyle,
    iconWrapperStyle,
    detailsWrapperStyle,
    titleTextStyle,
    descTextStyle,
  } = props;

  const getBgClolor = () => {
    if (status === 'Unresolved' || status === 'Found, Unrecovered') {
      return styles.unresolvedBgViewStyles;
    } else if (status === 'Assigned') {
      return styles.assigenedBgViewStyles;
    } else if (status === 'In progress' || status === 'Recovered') {
      return styles.inProgressBgViewStyles;
    } else if (status === 'In review') {
      return styles.inReviewBgViewStyles;
    } else if (status === 'Marked lost' || status === 'Complaint raised') {
      return styles.inMarkAsLostBgViewStyles;
    } else if (status === 'In review*') {
      return styles.cannotSolveBgViewStyles;
    } else if (status === 'Closed') {
      return styles.inClosedBgViewStyles;
    } else {
      return styles.primaryBgStyle;
    }
  };

  const getTextClolor = () => {
    if (status === 'Unresolved' || status === 'Found, Unrecovered') {
      return styles.unresolvedTextStyles;
    } else if (status === 'Assigned') {
      return styles.assigenedTextStyles;
    } else if (status === 'In progress' || status === 'Recovered') {
      return styles.inProgressTextStyles;
    } else if (status === 'In review') {
      return styles.inReviewTextStyles;
    } else if (status === 'Marked lost' || status === 'Complaint raised') {
      return styles.inMarkAsLostTextStyles;
    } else if (status === 'In review*') {
      return styles.cannotSolveTextStyles;
    } else if (status === 'Closed') {
      return styles.inClosedTextStyles;
    }
  };
  return (
    <div
      onClick={onClick}
      className={classNames(
        styles.containerStyle,
        selected && styles.selectedCardStyle,
        containerStyle
      )}
      id={`selected-${_id}`}
    >
      <div className={classNames(styles.iconWrapperStyle, getBgClolor(), iconWrapperStyle)}>
        <Image src={icon} alt="img" containerStyle={styles.iconStyle} />
      </div>
      <div className={classNames(styles.detailsWrapperStyle, detailsWrapperStyle)}>
        <p className={classNames(styles.titleTextStyle, titleTextStyle)}>{title}</p>
        <div className={styles.detailsSubWrapperStyle}>
          <Chip label={status} labelStyle={classNames(getTextClolor())} />
          <Chip
            label={subTitle}
            labelStyle={styles.subTitleLabelStyle}
            containerStyle={styles.subTitleContainerStyle}
          />
          {priority && <Chip leftIcon={priorityIcon} />}
        </div>
        <p className={classNames(styles.descTextStyle, descTextStyle)}>{description}</p>
      </div>
    </div>
  );
};

ListItemCard.propTypes = {
  type: PropTypes.oneOf(['ticket', 'lost&found']),
  title: PropTypes.string,
  _id: PropTypes.string,
  icon: PropTypes.string,
  status: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  containerStyle: PropTypes.string,
  iconWrapperStyle: PropTypes.string,
  detailsWrapperStyle: PropTypes.string,
  titleTextStyle: PropTypes.string,
  descTextStyle: PropTypes.string,
};

export default ListItemCard;
