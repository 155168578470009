import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CountryCodes from 'country-codes-list';
import PopOver from 'components/common/pop-over';
import styles from './styles.module.css';

const NumberInput = (props) => {
  const {
    name,
    type,
    value,
    countryCode = '+46',
    onChange = () => {},
    onChangeCountryCode = () => {},
    placeholder,
    hasError,
    lableText,
    lableTextStyle,
    ...rest
  } = props;

  const [elementRef, setElementRef] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const codes = Object.values(
    CountryCodes.customList('countryCode', '{countryCode},{countryNameEn},+{countryCallingCode}')
  ).map((item) => {
    let data = item.split(',');
    return {
      name: data[1],
      short_name: data[0],
      code: data[2],
    };
  });

  const invalid = ['e', ',', '.', 'ArrowUp', 'ArrowDown'];

  return (
    <div className={styles.inputContainerStyle}>
      {lableText ? (
        <p className={[styles.lableTextStyle, lableTextStyle].join(' ')}>{lableText}</p>
      ) : null}
      <div className={styles.inputSubContainerStyle} ref={setElementRef}>
        <p className={styles.countryCodeStyle} onClick={() => setShowModal(true)}>
          {countryCode || '+46'}
        </p>
        <div className={styles.deviderStyle}></div>
        <input
          type="number"
          name={name}
          className={styles.inputStyle}
          value={value ? Number(value) : value}
          onChange={(e) => {
            onChange(e);
          }}
          placeholder={placeholder || ''}
          onKeyDown={(event) => {
            if (invalid.find((e) => event.key === e)) {
              event.preventDefault();
            }
          }}
          inputMode="numeric"
          {...rest}
        />
      </div>

      <PopOver
        show={showModal}
        reference={elementRef}
        containerStyle={styles.countryCodeModalStyle}
        onClose={() => setShowModal(false)}
      >
        {codes.map((code, index) => (
          <p
            key={index}
            className={styles.countryCodeItemStyle}
            onClick={() => {
              onChangeCountryCode(code.code);
              setShowModal(false);
            }}
          >
            {code.name} {code.code}
          </p>
        ))}
      </PopOver>
      {hasError && <p className={styles.errorTextStyle}>{hasError}</p>}
    </div>
  );
};

NumberInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  countryCode: PropTypes.string,
  onChangeCountryCode: PropTypes.func,
  placeholder: PropTypes.string,
  hasError: PropTypes.string,
  lableText: PropTypes.string,
  lableTextStyle: PropTypes.string,
};

export default NumberInput;
