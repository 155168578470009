// User ENDPOINTS
export const login = '/auth/verify-login';
export const loginOtp = '/auth/send-login-otp';

// Company ENDPOINTS
export const createCompany = '/company/create';
export const getAllCompanies = '/company/all';
export const getStatisticsCount = '/dashboard/count';
// Super Admin ENDPOINTS
export const sendRegistrationOtp = '/super-admin/send-registration-otp';
export const superAdminRegister = '/super-admin/verify-registration';
export const sendLoginOtp = '/super-admin/send-login-otp';
export const superAdminLogin = '/super-admin/verify-login';
export const getSuperAdminDetails = '/super-admin/details';
// export const getAllAdminsDetails = '/employee/all-admins';
export const updateSuperAdminDetails = '/company/update';
export const superAdminChangePassword = '/super-admin/update-password';

// EMPLOYEE ENDPOINTS
export const createEmployee = '/employee/create';
export const getEmployeeDetails = '/employee/get/{id}';
export const getEmployeeDetailsWithCompanies = '/employee/get-with-companies/{id}';
export const getemployeeDetailsWithAssets = 'employee/get-assets/{id}';
export const getAllEmployeesWithLastAsset = '/employee/all-with-last-asset';
export const updateEmployee = '/employee/update';
export const deleteEmployee = '/employee/delete/{id}';
export const downloadUsersXLSFile = '/employee/sample-file';
export const uploadUserXlsFile = '/employee/insert-many';
export const getAdminsAndEmployees = '/employee/all';
export const getUserReminderList = '/employee/reminder/list/{id}';
export const updateUserReminder = '/employee/reminder/update';
export const addUserReminder = '/employee/reminder/create';
export const deleteUserReminder = '/employee/reminder/delete/{id}';
export const getUserCompletedReminders = '/employee/reminder/completed';
export const getUsersWithSearch = '/employee/search';
export const getUserTickets = '/employee/tickets/{id}';
export const getUserClusters = '/employee/clusters/{id}';

// ASSET ENDPOINTS
export const createAsset = '/asset/create';
export const getAssetDetails = '/asset/get/{id}';
export const getAllAssets = '/asset/all';
export const updateAsset = '/asset/update';
export const assignAsset = '/asset/assign';
export const deleteAsset = '/asset/delete/{id}';
export const assetUnAssign = '/asset/un-assign/{id}';
export const assignAssetToOthers = '/asset/assign-to-other';
export const getAssetRemindersList = '/asset/reminder/list/{id}';
export const addReminder = '/asset/reminder/create';
export const updateReminder = '/asset/reminder/update';
export const deleteReminder = '/asset/reminder/delete/{id}';
export const downloadassetsXLSFile = '/asset/sample-file';
export const uploadAssetXlsFile = '/asset/insert-many';
export const markAsLost = '/asset/mark-as-lost/{id}';
export const getAssetDetailsByQr = '/asset/scan-qr';
export const getAssetCompletedReminders = '/asset/reminder/completed';
export const getAssetsWithSearch = '/asset/search';
export const getAssetTickets = '/asset/tickets/{id}';
export const getAssetClusters = '/asset/clusters/{id}';

// UPLOAD ROUTES
export const uploadImage = '/upload/image';
export const uploadFiles = '/upload/files';

// ALL TICKETS ENDPOINTS
export const raiseTicket = '/ticket/raise';
export const getAllTickets = '/ticket/all';
export const getTicketData = '/ticket/get/{id}';
export const forwardTicket = '/ticket/forward';
export const assignTicket = '/ticket/assign';
export const acceptTicket = '/ticket/accept';
export const solveTicket = '/ticket/mark-as-done';
export const markAsTicketResolved = '/ticket/mark-as-resolved';
export const markAsTicketCantSolve = '/ticket/mark-as-cannot-solve';
export const needExpert = '/ticket/need-expert';
export const closeTicket = '/ticket/close';
export const updateTaskItemState = '/ticket/task/inner/update/state';
export const deleteTask = '/ticket/task/delete/{id}';
export const removeTicketMember = '/ticket/remove-user';

// ALL TICKETS COMMENT
export const addComment = '/ticket/post-activity-comment';
export const updateUserTicketAccess = '/ticket/user/update-access';
export const sendTicketMessage = '/ticket/post-comment';
export const markAsWorking = '/ticket/mark-as-working';
export const markAsCompleted = '/ticket/mark-as-completed';
export const markAsCantSolve = '/ticket/mark-as-cannot-solve';
export const markAsResolved = '/ticket/mark-as-resolved';
export const markAsRevert = '/ticket/mark-as-revert';
export const inviteTicketMembers = '/ticket/invite-users';

// Chat ENDPOINTS
export const getAssetsChatList = '/lost-found/list';
export const getAssetChatData = '/lost-found/info/{id}';
export const getChatMessages = '/lost-found/messages/{id}';
export const sendMessage = '/lost-found/chat/send';
export const markAsRetunred = '/lost-found/mark-as-returned';
export const markAsRecovered = '/lost-found/mark-as-recovered';
export const archiveChat = '/lost-found/archive';

// Cluster ENDPOINTS
export const createCluster = '/maintenance-cluster/create';
export const getClusters = '/maintenance-cluster/all';
export const getClusterDetails = '/maintenance-cluster/get/{id}';
export const updatedChecklistCheckedStatus = '/maintenance-cluster/checklist/toggle';
export const updateAssetEntireState = '/maintenance-cluster/asset/condition/update';
export const raiseComplaint = '/maintenance-cluster/complaint/raise';
export const saveTemplate = '/maintenance-cluster/template/save';
export const getAllTemplate = '/maintenance-cluster/template/all';
export const postClusterComment = '/maintenance-cluster/post-comment';
export const fetchClusterComments = '/maintenance-cluster/fetch-comments/{id}';
export const inviteClusterMembers = '/maintenance-cluster/invite-users';
export const updateClusterAccess = '/maintenance-cluster/update-access';
export const updateCluster = '/maintenance-cluster/update';
export const archiveCluster = '/maintenance-cluster/archive/{id}';
export const updateAssetOnHoldStatus = '/maintenance-cluster/unhold-asset';
export const removeClusterMember = '/maintenance-cluster/remove-user';
