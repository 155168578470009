import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { useStrings } from 'providers/stringsprovider';
import Image from 'components/common/image';
import {
  avatar1Icon,
  avatar2Icon,
  avatar3Icon,
  avatar4Icon,
  clusterIcon,
  infoCircleIcon,
} from 'resources/images';
import CircularProgressBar from 'components/common/circular-progress-bar';
import AvatarGroup from 'components/common/avatar-group';
import { useNavigate } from 'react-router';
import routePaths from 'routes/paths';
import styles from './styles.module.css';

const UserClusters = (props) => {
  const { clusters } = props;
  const { strings } = useStrings();
  const navigate = useNavigate();
  const images = [avatar1Icon, avatar2Icon, avatar3Icon, avatar4Icon];

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };

  const getBgClolor = (type) => {
    if (type === 'Daily') {
      return styles.dailyBgStyle;
    } else if (type === 'Weekly') {
      return styles.weeklyBgStyle;
    } else if (type === 'Monthly') {
      return styles.monthlyBgStyle;
    } else {
      return styles.customBgStyle;
    }
  };

  const getCheckedAssetCount = (cls_report) => {
    let checked_count = 0;
    cls_report?.cluster_assets.map((item) => {
      if (item?.on_hold) {
        if (moment(item.on_hold).isBetween(cls_report?.start_date, cls_report?.end_date)) {
          if (item.checked === true) {
            checked_count += 1;
          }
        }
      } else {
        if (item.checked === true) {
          checked_count += 1;
        }
      }
      return item;
    });
    return checked_count;
  };

  const renderClusters = () => {
    return (
      <div className={styles.tableSectionStyle}>
        <table className={styles.ticketsTableWrapperStyle}>
          <thead>
            <tr className={styles.tableHeaderRowStyle}>
              <th>Cluster info</th>
              <th>Total assets</th>
              <th>Last maintenance</th>
              <th>Ongoing tickets</th>
              <th>Assignees</th>
            </tr>
          </thead>
          <tbody>
            {clusters.length > 0 ? (
              clusters.map((item, index) => (
                <tr
                  key={index}
                  className={styles.tableRowStyle}
                  onClick={() => navigate(routePaths.clusterList + `?selected=${item._id}`)}
                >
                  <td>
                    <div className={styles.clusInfoViewStyle}>
                      <Image
                        src={clusterIcon}
                        containerStyle={classNames(
                          styles.clusterListItemIconStyle,
                          getBgClolor(item.type)
                        )}
                      />
                      <div className={styles.titleCustomColStyle}>
                        <span className={styles.clusterTitleTextStyle}>{item.name}</span>
                        <span className={styles.tableSmallTextStyle}>{item.type} maintenance</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className={styles.clusterDescTextStyle}>
                      {item.asset_count ? `${item.asset_count} assets` : '0 assets'}
                    </span>
                  </td>
                  <td>
                    {getCheckedAssetCount(item?.report) > 0 ? (
                      <div className={styles.clusInfoViewStyle}>
                        <CircularProgressBar
                          progress={
                            (getCheckedAssetCount(item?.report) /
                              item?.report.cluster_assets.length) *
                              100 || 0
                          }
                        ></CircularProgressBar>
                        <div className={styles.titleCustomColStyle}>
                          <span className={styles.clusterTitleTextStyle}>{`${getCheckedAssetCount(
                            item?.report
                          )} out of ${item?.report.cluster_assets.length} ${
                            item?.report.cluster_assets.length > 1 ? 'assets' : 'asset'
                          } checked`}</span>
                          <span className={styles.clusterDescTextStyle}>
                            {item?.type} maintenance
                          </span>
                        </div>
                      </div>
                    ) : (
                      <span className={styles.clusterDescTextStyle}>not checked</span>
                    )}
                  </td>
                  <td>
                    {item.ticket_count === 0 ? (
                      <span className={styles.clusterDescTextStyle}>None</span>
                    ) : (
                      <div className={styles.clusterTicketsWrapperStyle}>
                        <div className={styles.dotViewStyle}></div>
                        <span className={classNames(styles.clusterticketsTextStyle)}>
                          {item.ticket_count}
                          {item.ticket_count > 1 ? 'tickets' : 'ticket'}
                        </span>
                      </div>
                    )}
                  </td>
                  <td>
                    {/* <span className={styles.tableSmallTextStyle}>Not closed</span> */}
                    <AvatarGroup
                      data={item?.users?.map((u) => ({
                        label: u.user.full_name,
                        src: getRandomImage(),
                      }))}
                      maxItems={3}
                      imgContainerStyle={styles.avatarImgStyle}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr className={styles.tableRowStyle}>
                <td className={styles.emptyDataStyle}>{strings.noData}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const renderEmptyDataSection = () => {
    return (
      <div className={styles.emptyDataViewStyle}>
        <div className={styles.emptyDataSubViewStyle}>
          <Image src={infoCircleIcon} />
          <p className={styles.emptyDataTextStyle}>No clusters is assigned yet</p>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {clusters.length > 0 ? renderClusters() : renderEmptyDataSection()}
    </React.Fragment>
  );
};

UserClusters.propTypes = {
  clusters: PropTypes.array,
};

export default UserClusters;
