import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { raiseTicketApi } from 'networking/api/tickets';
import { useStrings } from 'providers/stringsprovider';
import { useAuth } from 'providers/authprovider';
import { arrowLeftIcon, avatar1Icon, sampleImg, scannerIcon } from 'resources/images';
import Button from 'components/common/button';
import Input from 'components/common/input';
import ImageUpload from 'components/image-upload';
import AvatarInfoCard from 'components/common/avatar-info-card';
import Modal from 'components/common/modal';
import SuccessCard from 'components/success-card';
import Image from 'components/common/image';
import { useToast } from 'providers/toastprovider';
import styles from './styles.module.css';

const RaiseTicketThroughQR = () => {
  const navigation = useNavigate();
  const { strings } = useStrings();
  const toast = useToast();
  const { user } = useAuth();
  const location = useLocation();
  const asset = location.state.asset;
  const [actionLoading, setActionLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [step, setStep] = useState(1);

  const raiseTicketSchema = Yup.object().shape({
    asset: Yup.string().notRequired(),
    subject: Yup.string().required('Ticket subject is required'),
    note: Yup.string().notRequired(),
    image: Yup.string().notRequired(),
  });

  const handleSubmit = async (values, { resetForm }) => {
    if (step === 1) {
      setStep(2);
    } else {
      await handleRaiseTicket(values);
    }
  };

  const handleRaiseTicket = async (values) => {
    try {
      setActionLoading(true);
      let response = await raiseTicketApi(values);
      setActionLoading(false);
      toast.success(response.data.message);
      setIsModalOpen(true);
    } catch (error) {
      setApiError(error.message);
      setActionLoading(false);
      toast.error(error.message);
    }
  };
  const formik = useFormik({
    initialValues: {
      asset: asset._id,
      subject: '',
      note: '',
      image: '',
    },
    validationSchema: raiseTicketSchema,
    onSubmit: handleSubmit,
  });

  const rendedrHeaderSection = () => {
    return (
      <div className={styles.headerViewStyle}>
        <div className={styles.headerLeftViewStyle}>
          <Image
            src={arrowLeftIcon}
            lt="close"
            containerStyle={styles.backImgViewStyle}
            onClick={() => navigation(-1)}
          />
          <p>{strings.back}</p>
        </div>
      </div>
    );
  };

  const renderAssetSection = () => {
    return (
      <div className={styles.assetSectionViewStyle}>
        <div className={styles.assetDetailsViewStyle}>
          <p className={styles.titleTextStyle}>Raise a ticket</p>
          <p className={styles.subTitleTextStyle}>{asset.asset_name}</p>
          <div className={styles.assetIdViewStyle}>
            <p className={styles.assetIdTextStyle}>#{asset.asset_uid}</p>
          </div>
        </div>
        <div className={styles.scannerImgViewStyle}>
          <Image src={scannerIcon} lt="scanner" containerStyle={styles.scannerViewStyle} />
        </div>
      </div>
    );
  };

  const renderStepOne = () => {
    return (
      <div className={styles.setpOneSectionViewStyle}>
        <Input
          name="subject"
          labelText={'Ticket subject*'}
          inputLabelStyle={styles.lableTextStyle}
          placeholder={'Enter subject'}
          value={formik.values.subject}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasError={formik.touched.subject && formik.errors.subject ? formik.errors.subject : ''}
        />
        <Input
          name="note"
          type="textarea"
          labelText={'Ticket notes (Optional)'}
          inputLabelStyle={styles.lableTextStyle}
          placeholder={'Enter description here'}
          value={formik.values.note}
          onChange={formik.handleChange}
          inputStyle={styles.noteViewStyle}
          hasError={formik.touched.note && formik.errors.note ? formik.errors.note : ''}
        />
        <ImageUpload
          label="Upload image (Optional)"
          placeholder="JPG, JPEG, or PNG (Less than 5MB)"
          image={formik.values.image}
          onSuccess={(img) => formik.setFieldValue('image', img)}
        />
      </div>
    );
  };

  const renderBtnSection = () => {
    return (
      <div className={styles.btnViewStyle}>
        <p className={styles.errorTextStyle}>{apiError}</p>
        <Button
          type="submit"
          title={step === 1 ? 'Preview' : actionLoading ? 'Loading...' : 'Raise complaint'}
          btnStyle={styles.customBtnStyle}
        />
      </div>
    );
  };

  const renderTicketPreview = () => {
    return (
      <div className={styles.ticketPreviewContainerStyle}>
        <div className={styles.timelineContainer}>
          <ul className={styles.timeline}>
            <li className={styles.timelineItem}>
              <div className={styles.indicator}>
                <div className={styles.innerDot}></div>
              </div>
              {renderTicketDetails()}
            </li>
            <li className={styles.timelineItem}>
              <div className={styles.indicator}>
                <div className={styles.innerDot}></div>
              </div>
              {renderAssetDetails()}
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderTicketDetails = () => {
    return (
      <div className={styles.event}>
        <div className={styles.descriptionContainer}>
          <p className={styles.timelineTitleTextStyle}>{strings.ticketDDetails}</p>
          <div className={styles.ticketAssetDetailsStyle}>
            <Image
              src={formik.values.image ? formik.values.image : sampleImg}
              alt="img"
              containerStyle={styles.assetImageViewStyle}
              imgStyle={styles.assetImageStyle}
            />
            <div className={styles.assetDetailStyle}>
              <p className={styles.assetDetailTitleStyle}>{formik.values.subject}</p>
              <p className={styles.assetDetailDescriptionStyle}>{formik.values.note}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAssetDetails = () => {
    return (
      <div className={styles.event}>
        <div className={styles.descriptionContainer}>
          <p className={styles.timelineTitleTextStyle}>{strings.assetUserDetails}</p>
          <div className={styles.ticketAssetDetailsStyle}>
            <AvatarInfoCard title={user?.full_name} description={user?.email} icon={avatar1Icon} />
          </div>
        </div>
      </div>
    );
  };

  const renderModalContainer = () => {
    return (
      <Modal show={isModalOpen} showOverlay containerStyle={styles.modalCustomStyle}>
        <SuccessCard
          title={'A ticket has been raised'}
          description={`Your complaint has reached the respective authorities and they are working on resolving it at the earliest. We will send you an email once the issue gets resolved. 

          Have a good day!`}
          rightBtnTitle="Close session"
          rightAction={() => {
            setIsModalOpen(false);
            navigation(-1);
          }}
          rightBtnStyle={styles.successCardBtnStyle}
          cardBtnStyle={styles.successCardBtnStyle}
        />
      </Modal>
    );
  };

  return (
    <div className={styles.mainContainerStyle}>
      {rendedrHeaderSection()}
      {renderAssetSection()}
      <form onSubmit={formik.handleSubmit} className={styles.ticketDetailsViewStyle}>
        {step === 1 ? renderStepOne() : renderTicketPreview()}
        {renderBtnSection()}
      </form>
      {/* {renderImgPreViewModal()} */}
      {renderModalContainer()}
    </div>
  );
};

export default RaiseTicketThroughQR;
