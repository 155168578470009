import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { uploadImageApi } from 'networking/api/upload';
import Button from 'components/common/button';
import Image from 'components/common/image';
import { closeCircleFillGrayIcon, galleryBlueIcon, uploadImgIcon } from 'resources/images';
import ImagePreview from 'components/common/image-preview';
import { useToast } from 'providers/toastprovider';
import styles from './styles.module.css';

const ImageUpload = (props) => {
  const {
    label,
    image,
    imageName,
    placeholder,
    btnTitle,
    icon,
    labelStyle,
    placeholderStyle,
    btnStyle,
    iconStyle,
    containerStyle,
    uploadBoxStyle,
    onSuccess = () => {},
    setImageName = () => {},
    children,
  } = props;
  const toast = useToast();
  const fileInputRef = useRef();

  const [imageURI, setImageURI] = useState(image);
  const [selctedFile, setSelectedFile] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showUploadPreview, setShowUploadPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setImageURI(image);
  }, [image]);

  const handleUploadImg = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('image', selctedFile);
      const response = await uploadImageApi(formData);
      setImageURI(response.data.data);
      setIsLoading(false);
      setShowUploadPreview(false);
      onSuccess(response.data.data);
      setImageName(selctedFile?.name);
      toast.success(response.data.message || 'Image uploaded successfully');
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  const handleUploadImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageUploadSelection = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setImageURI(URL.createObjectURL(file));
    setShowUploadPreview(true);
    // handleUploadTicketSolvedImg(file);
  };

  const handleRemoveImage = () => {
    setImageURI(null);
    setSelectedFile(null);
    fileInputRef.current.value = '';
    onSuccess('');
  };

  return (
    <div className={classNames(styles.containerStyle, containerStyle)}>
      {label && <p className={classNames(styles.labelStyle, labelStyle)}>{label}</p>}
      <div className={classNames(styles.uploadBoxStyle, uploadBoxStyle)}>
        {placeholder && (
          <p className={classNames(styles.placeholderStyle, placeholderStyle)}>{placeholder}</p>
        )}
        <input
          type="file"
          accept="image/jpeg, image/jpg, image/png"
          placeholder="Upload an image"
          ref={fileInputRef}
          onChange={handleImageUploadSelection}
          hidden
        />
        {imageURI ? (
          <div className={styles.removeFileStyle}>
            <Image src={imageURI} containerStyle={styles.removeFileImgStyle} />
            <p className={styles.removeFileNameStyle}>{selctedFile?.name || imageName}</p>
            <div className={styles.verticalLineStyle} />
            <Image src={closeCircleFillGrayIcon} onClick={handleRemoveImage} />
          </div>
        ) : (
          <Button
            title={btnTitle || 'Upload an image'}
            leftIcon={icon || uploadImgIcon}
            leftIconStyle={classNames(iconStyle)}
            onClick={handleUploadImageClick}
            variant="gray"
            btnStyle={classNames(styles.btnStyle, btnStyle)}
          />
        )}
      </div>
      {imageURI && (
        <div onClick={() => setShowPreview(true)} className={styles.imgPreviewLinkStyle}>
          <Image src={galleryBlueIcon} containerStyle={styles.imgPreviewIconStyle} />
          <p className={styles.imgPreviewTextStyle}>Click to preview image</p>
        </div>
      )}
      {showUploadPreview && (
        <ImagePreview
          src={imageURI}
          onClose={() => {
            handleRemoveImage();
            setShowUploadPreview(false);
          }}
          onDone={handleUploadImg}
          isLoading={isLoading}
        />
      )}
      {showPreview && (
        <ImagePreview
          src={imageURI}
          onClose={() => setShowPreview(false)}
          onDone={() => setShowPreview(false)}
        />
      )}
      {children && children}
    </div>
  );
};

ImageUpload.propTypes = {
  label: PropTypes.string,
  image: PropTypes.string,
  setImage: PropTypes.func,
  imageName: PropTypes.string,
  setImageName: PropTypes.func,
  placeholder: PropTypes.string,
  btnTitle: PropTypes.string,
  icon: PropTypes.string,
  labelStyle: PropTypes.string,
  placeholderStyle: PropTypes.string,
  btnStyle: PropTypes.string,
  iconStyle: PropTypes.string,
  containerStyle: PropTypes.string,
  uploadBoxStyle: PropTypes.string,
  onSuccess: PropTypes.func,
};

export default ImageUpload;
