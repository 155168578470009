import React from 'react';
import { logoIcon } from 'resources/images';
import Image from 'components/common/image';
import styles from './style.module.css';

const AuthLayout = ({ children }) => {
  return (
    <div className={styles.authLayoutStyle}>
      <div className={styles.authLayoutContentStyle}>
        <Image src={logoIcon} containerStyle={styles.logoWrapperStyle} alt="Trakr" />
        {children}
      </div>
      <div className={styles.authLayoutRightSectionStyle} />
    </div>
  );
};

export default AuthLayout;
