import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'components/common/image';
import {
  chevronDownFillBlackIcon,
  flagFillWhiteIcon,
  forwardRightBlackIcon,
  forwardRightWhiteIcon,
  sampleImg,
} from 'resources/images';
import { useAuth } from 'providers/authprovider';
import PopOver from 'components/common/pop-over';
import Modal from 'components/common/modal';
import moment from 'moment';
import classNames from 'classnames';
import Button from 'components/common/button';
import { account_types } from 'resources/data';
import { useStrings } from 'providers/stringsprovider';
import Info from 'components/common/info';
import styles from './styles.module.css';

const TicketOverViewCard = (props) => {
  const { ticketData, takeAction = () => {}, forwardAction = () => {} } = props;
  const { user, userType } = useAuth();
  const { strings } = useStrings();
  const [ticket, setTicket] = useState([]);
  const [forwardedData, setForwardedData] = useState([]);
  const [ticketForworfRef, setTicketForworfRef] = useState(null);
  const [isShowTimelineModal, setIsShowTimelineModal] = useState(false);
  const [imageModal, setImageModal] = useState(false);

  useEffect(() => {
    if (ticketData) {
      setTicket(ticketData);
    }
  }, [ticketData]);

  useEffect(() => {
    if (ticket) {
      setForwardedData(
        ticket?.activity?.filter((item) => item.type === 'Forward' || item.type === 'Raise')
      );
    }
  }, [ticket]);

  const forwardedTextMessage = () => {
    let msg = `${forwardedData[0]?.action_by?.full_name} `;
    if (user?._id === forwardedData[0]?.action_by?._id) {
      msg += `(You) `;
    }
    msg += 'forwarded to ';
    if (forwardedData[0]?.users.length > 0) {
      msg += `${forwardedData[0].users[0].full_name}`;
      if (forwardedData[0].users[0]._id === user._id) {
        msg += ' (You)';
      }
    }
    return msg;
  };

  const taskProgressBgColor = (type) => {
    if (type === 'Forward') {
      return styles.primaryBgStyle;
    } else {
      return styles.redBgStyle;
    }
  };

  const checkEditAction = () => {
    let is_assigned = ticket?.ticket_status !== 'Complaint raised';
    let is_visible = false;
    let data = ticket?.ticket_users?.find((item) => item.user._id === user?._id);
    if (data?.can_edit === true) {
      is_visible = true;
    } else {
      is_visible = false;
    }
    return (
      !is_assigned &&
      (is_visible ||
        userType === account_types.super_admin ||
        (userType === account_types.admin && ticket?.ticket_status === 'Complaint raised'))
    );
  };

  const checkMemberExist = () => {
    let data = ticket?.ticket_users?.find((item) => item.user._id === user?._id);
    if (data && data.can_edit === true) {
      return false;
    } else if (ticket?.ticket_status === 'Complaint raised' && data === undefined) {
      return false;
    } else {
      return true;
    }
  };

  // ui section ----

  const renderForwardedSection = () => {
    return (
      <div className={styles.forwardedWrapperStyle} ref={setTicketForworfRef}>
        <Image src={forwardRightBlackIcon} alt="forward" />
        <p className={styles.forwardedTextStyle}>{forwardedTextMessage()}</p>
        <Image
          src={chevronDownFillBlackIcon}
          alt="down"
          containerStyle={styles.dropDownIconStyle}
          onClick={() => setIsShowTimelineModal(true)}
        />
        {renderTimeLine()}
      </div>
    );
  };

  const renderTimeLine = () => {
    return (
      <PopOver
        showOverlay
        show={isShowTimelineModal}
        reference={ticketForworfRef}
        onClose={() => setIsShowTimelineModal(false)}
        containerStyle={styles.ticketTimelineModalStyle}
      >
        <div className={styles.timeLineContainerStyle}>
          {forwardedData &&
            forwardedData.map((activity, index) => (
              <div key={'act' + index} className={styles.timeLineItemWrapperStyle}>
                <div
                  className={classNames(
                    styles.timeLineImgContainerStyle,
                    taskProgressBgColor(activity.type)
                  )}
                >
                  <Image
                    src={activity.type === 'Forward' ? forwardRightWhiteIcon : flagFillWhiteIcon}
                  />
                </div>
                <div className={styles.timeLineContentWrapperStyle}>
                  <p className={styles.timeLineTitleStyle}>
                    {activity.type === 'Forward'
                      ? `${activity?.action_by && activity?.action_by?.full_name}${
                          activity?.action_by && activity?.action_by?._id === user?._id
                            ? `(${strings.you})`
                            : ''
                        } ${strings.forwardedTo} ${activity?.users[0].full_name} ${
                          activity?.users[0]._id === user._id ? `(${strings.you})` : ''
                        }`
                      : `${strings.complaintRaisedBy} ${
                          activity?.action_by && activity?.action_by.full_name
                        }`}
                  </p>
                  {activity.note && (
                    <div className={styles.timelineNoteViewStyle}>
                      <p className={styles.timelineNoteTextStyle}>{activity.note}</p>
                    </div>
                  )}
                  <span className={styles.timeLineSubTitleStyle}>
                    {`${moment(activity.created_at).format('hh:mm a')}, ${moment(
                      activity.created_at
                    ).fromNow()}`}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </PopOver>
    );
  };

  const renderTicketDetails = () => {
    return (
      <div className={styles.ticketWrapperStyle}>
        <span className={styles.titleTextStyle}>
          {`#${ticket?.asset?.asset_uid}: ${ticket?.asset?.asset_name}`}
        </span>
        <Image
          src={ticket?.image || sampleImg}
          alt={ticket?.asset?.asset_name}
          onClick={() => setImageModal(true)}
          containerStyle={styles.ticketImageStyle}
        />
      </div>
    );
  };

  const renderIssuedDetails = () => {
    return (
      <div className={styles.issueDetailsViewStyle}>
        <div className={styles.infoViewStyle}>
          <Info
            leftlable={'Ticket Subject'}
            leftValue={ticket?.subject}
            customLableStyle={styles.customLabelStyle}
          />
          <Info
            leftlable={'Ticket Note'}
            leftValue={ticket?.note}
            customLableStyle={styles.customLabelStyle}
          />
          {!checkEditAction() && (
            <Info
              leftlable={strings.highPriority}
              leftValue={ticket?.is_high_priority ? 'Yes' : 'No'}
              rightlable={ticket?.difficulty && strings.ticketDifficulty}
              rightValue={ticket?.difficulty ? ticket?.difficulty : '--'}
              customLableStyle={styles.customLabelStyle}
            />
          )}
        </div>
      </div>
    );
  };

  const renderTaskActionButtonSection = () => {
    return (
      <div className={styles.ationBtnsViewStyle}>
        <Button
          title={'Take action'}
          btnStyle={classNames(styles.btnStyle)}
          onClick={takeAction}
          disabled={checkMemberExist()}
        />
        <Button
          title={'Forward to'}
          variant="light"
          btnStyle={classNames(styles.btnStyle)}
          onClick={forwardAction}
          disabled={checkMemberExist()}
        />
        {/* <Button
          title={'Archive'}
          variant="light"
          btnStyle={classNames(styles.btnStyle)}
          onClick={archiveAction}
        /> */}
      </div>
    );
  };
  const renderImageViewModal = () => {
    return (
      <Modal
        onClose={() => setImageModal(false)}
        closeOnOutSideClick
        showOverlay
        show={imageModal}
        containerStyle={styles.imageModalContainerStyle}
      >
        <div className={styles.imageModalWrapperStyle}>
          <Image
            src={ticket?.image ? ticket?.image : sampleImg}
            alt="image"
            containerStyle={styles.modalImageStyle}
          />
        </div>
      </Modal>
    );
  };

  return (
    <div className={styles.ticketOverViewCardWrapperStyle}>
      {forwardedData &&
        forwardedData?.length > 0 &&
        forwardedData[0]?.type === 'Forward' &&
        ticket?.ticket_status === 'Complaint raised' &&
        renderForwardedSection()}
      <div className={styles.ticketDetailsViewStyle}>
        {renderTicketDetails()}
        {renderIssuedDetails()}
      </div>
      {checkEditAction() && renderTaskActionButtonSection()}
      {renderImageViewModal()}
    </div>
  );
};

TicketOverViewCard.propTypes = {
  ticketData: PropTypes.object,
  takeAction: PropTypes.func,
  forwardAction: PropTypes.func,
  archiveAction: PropTypes.func,
};

export default TicketOverViewCard;
