import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import Image from 'components/common/image';
import CircularProgressBar from 'components/common/circular-progress-bar';
import {
  chevronLeftBlackIcon,
  chevronRightBlackIcon,
  infoCircleDownFillGrayIcon,
} from 'resources/images';
import styles from './styles.module.css';

const weeks = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const CustomCalendar = (props) => {
  const { value = new Date(), onChange = () => {}, minDate, maxDate, reports = [] } = props;
  const [selectedDate, setSelectedDate] = useState(moment(value).isValid() ? value : new Date());
  const [selectedMonthDates, setSelectedMonthDates] = useState(getDatesInMonth(value));
  const [showMonths, setShowMonths] = useState(false);
  const [prevMonthDays, setPrevMonthDays] = useState(moment(value).startOf('month').get('day'));
  const [nextMonthDays, setNextMonthDays] = useState(6 - moment(value).endOf('month').get('day'));
  const [data, setData] = useState([]);

  const handleData = useCallback(() => {
    const startOfMonth = moment(selectedDate).startOf('month');
    const endOfMonth = moment(selectedDate).endOf('month');
    let calendarData = [];
    reports.map((report) => {
      if (report) {
        const startDate = moment(report.start_date).utc();
        const endDate = moment(report.end_date).utc();
        if (startDate.isSameOrAfter(startOfMonth) && endDate.isSameOrBefore(endOfMonth)) {
          let assets_count = 0;
          let checked_count = 0;
          let { complaints_count, resolved_count } = getComplaintsAndResolvedCount(report);
          report.cluster_assets.map((asset) => {
            if (
              !asset.on_hold ||
              (asset.on_hold && moment(asset.on_hold).isBetween(startDate, endDate))
            ) {
              assets_count += 1;
            }
            if (
              asset.checked &&
              (!asset.on_hold ||
                (asset.on_hold && moment(asset.on_hold).isBetween(startDate, endDate)))
            ) {
              checked_count += 1;
            }
            return asset;
          });
          calendarData.push({
            end_date: endDate.format('YYYY-MM-DD'),
            icon: !checked_count ? true : false,
            indicatorColor:
              checked_count === assets_count ? '#236bfe' : checked_count > 0 ? '#ffb800' : '',
            dotColor:
              complaints_count > resolved_count ? '#ec0000' : resolved_count ? '#00ae4d' : '',
            percent: (checked_count / assets_count) * 100,
          });
        }
      }
      return report;
    });
    setData(calendarData);
  }, [reports, selectedDate]);

  useEffect(() => {
    const startDateOMonth = new Date(moment(selectedDate).startOf('month'));
    const endDateOMonth = new Date(moment(selectedDate).endOf('month'));
    setSelectedMonthDates(getDatesInMonth(selectedDate));
    setPrevMonthDays(moment(startDateOMonth).get('day'));
    setNextMonthDays(6 - moment(endDateOMonth).get('day'));
    handleData();
  }, [selectedDate, handleData]);

  function getDatesInMonth(date) {
    const startDate = moment(date).startOf('month');
    const endDate = moment(startDate).endOf('month');
    const datesArray = [];
    let value = moment(startDate);
    while (value.isSameOrBefore(endDate)) {
      datesArray.push(new Date(moment(value)));
      value.add(1, 'day');
    }
    return datesArray;
  }

  const handleNextMonthClick = () => {
    setSelectedDate(new Date(moment(selectedDate).add(1, 'month')));
  };

  const handlePreviousMonthClick = () => {
    setSelectedDate(new Date(moment(selectedDate).subtract(1, 'month')));
  };

  const isDisabled = (date) => {
    return (
      (minDate && moment(minDate).isValid() && moment(date).isBefore(minDate, 'day')) ||
      (maxDate && moment(minDate).isValid() && moment(date).isAfter(maxDate, 'day'))
    );
  };

  const getDateWrapperStyles = (date) => {
    return classNames(
      styles.dateWrapperStyle,
      moment(new Date()).isSame(date, 'date') && styles.currentDateWrapperStyle,
      moment(value).isSame(date, 'date') && styles.selectedDateWrapperStyle,
      isDisabled(date) && styles.disabledDateWrapperStyle
    );
  };

  const getDateLabelStyles = (date) => {
    return classNames(
      styles.dateLabelStyle,
      moment(value).isSame(date, 'date') && styles.selectedDateLabelStyle
    );
  };

  function getComplaintsAndResolvedCount(report) {
    let complaints_count = 0;
    let resolved_count = 0;
    report.cluster_assets.map((ca) => {
      if (ca.checklist.length > 0) {
        ca.checklist.map((cl) => {
          cl.items.map((cli) => {
            if (cli?.complaint) {
              complaints_count += 1;
              if (cli.complaint.ticket_status === 'Closed') {
                resolved_count += 1;
              }
            }
            return cli;
          });
          return cl;
        });
        ca.complaints &&
          ca.complaints.map((com) => {
            complaints_count += 1;
            if (com.ticket_status === 'Closed') {
              resolved_count += 1;
            }
            return com;
          });
      }
      return ca;
    });
    return { complaints_count, resolved_count };
  }

  return (
    <div className={styles.containerStyle}>
      <div className={styles.headerWarpperStyle}>
        <Image
          src={chevronLeftBlackIcon}
          containerStyle={styles.iconWrapperStyle}
          imgStyle={styles.iconStyle}
          onClick={handlePreviousMonthClick}
        />
        <div className={styles.headerContentStyle}>
          <p className={styles.headerTitleStyle} onClick={() => setShowMonths(true)}>
            {moment(selectedDate).format('MMMM')},
          </p>
          <p className={styles.headerTitleStyle}>{moment(selectedDate).format('YYYY')}</p>
        </div>
        <Image
          src={chevronRightBlackIcon}
          containerStyle={styles.iconWrapperStyle}
          imgStyle={styles.iconStyle}
          onClick={handleNextMonthClick}
        />
      </div>
      <div className={styles.weeksWrapperStyle}>
        {weeks.map((week) => (
          <p key={week} className={styles.weekTitleStyle}>
            {week}
          </p>
        ))}
      </div>
      <div className={styles.daysWrapperStyle}>
        {Array.from({ length: prevMonthDays }).map((_, index) => (
          <div key={index} className={styles.dateWrapperStyle}></div>
        ))}
        {selectedMonthDates.map((date, index) => {
          let fillData = data.find((d) => moment(date).isSame(moment(d.end_date), 'day'));
          if (fillData) {
            if (
              moment(date).isSame(new Date(), 'day') &&
              moment(date).isSameOrAfter(fillData.end_date)
            ) {
              fillData.icon = false;
            }
          }
          return (
            <div
              key={index}
              className={getDateWrapperStyles(date)}
              onClick={() => {
                if (!isDisabled(date)) {
                  onChange(date);
                  setSelectedDate(date);
                }
              }}
            >
              {fillData?.icon ? (
                <Image
                  src={infoCircleDownFillGrayIcon}
                  containerStyle={styles.emptyCheckedImgStyle}
                >
                  {fillData?.dotColor && (
                    <div
                      className={styles.dotStyle}
                      style={{ '--dot-color': fillData?.dotColor }}
                    />
                  )}
                </Image>
              ) : (
                <CircularProgressBar
                  progress={fillData?.percent || 0}
                  indicatorColor={fillData?.indicatorColor}
                >
                  {fillData?.dotColor && (
                    <div
                      className={styles.dotStyle}
                      style={{ '--dot-color': fillData?.dotColor }}
                    />
                  )}
                </CircularProgressBar>
              )}
              <p className={getDateLabelStyles(date)}>{moment(date).format('D')}</p>
            </div>
          );
        })}
        {Array.from({ length: nextMonthDays }).map((_, index) => (
          <div key={index} className={styles.dateWrapperStyle}></div>
        ))}
      </div>
      {showMonths && (
        <div className={classNames(styles.monthWrapperStyle)}>
          {months.map((month, index) => (
            <div
              key={month}
              className={styles.monthStyle}
              onClick={() => {
                setSelectedDate(moment(selectedDate).set('month', month));
                setShowMonths(false);
              }}
            >
              {month}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

CustomCalendar.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  reports: PropTypes.array,
};

export default CustomCalendar;
