import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { useAppData } from 'providers/appdataprovider';
import { useStrings } from 'providers/stringsprovider';
import { useAuth } from 'providers/authprovider';
import { useUserData } from 'providers/userdataprovider';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useSearchParams } from 'react-router-dom';
import { searchIcon } from 'resources/images';
import routePaths from 'routes/paths';
import Input from 'components/common/input';
import Avatar from 'components/common/avatar';
import AssetAndUserCard from 'components/asset&user-card';
import Loader from 'components/common/loader';
import { getAssetsByPageApi } from 'networking/api/asset';
import InfiniteScroll from 'components/infinite-scroll';
import styles from './styles.module.css';

const AssetList = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { strings } = useStrings();
  const [searchParams, setSearchParams] = useSearchParams();
  const { assets, setAssets } = useUserData();
  const { company } = useAuth();
  const { setShowHeader, setBreadcrumbs } = useAppData();
  const [query, setQuery] = useState(searchParams.get('query') || '');
  const [filteredAssets, setFilteredAssets] = useState(assets);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [debouncedQuery, setDebouncedQuery] = useState(searchParams.get('query') || '');

  useEffect(() => {
    searchParams.get('query') && setQuery(searchParams.get('query'));
  }, [searchParams]);

  useEffect(() => {
    setFilteredAssets(assets);
    setBreadcrumbs([{ title: strings.allAssets, route: '' }]);
  }, [assets, setBreadcrumbs, setFilteredAssets, strings.allAssets]);

  useEffect(() => {
    if (width <= 480) {
      setShowHeader(true);
    }
  }, [width, setShowHeader]);

  const debouncedSearch = useMemo(
    () =>
      debounce((searchValue) => {
        setDebouncedQuery(searchValue);
        setCurrentPage(1);
      }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(query);
  }, [query, debouncedSearch]);

  const getAssets = useCallback(async () => {
    try {
      if (currentPage === 1) {
        setAssets([]);
      }
      setLoading(true);
      const response = await getAssetsByPageApi(company?._id, currentPage, debouncedQuery, 10);
      setAssets((prev) => {
        if (currentPage === 1) {
          return response.data.data.assets;
        } else {
          return [...prev, ...response.data.data.assets];
        }
      });
      setHasMore(response.data.data?.meta?.hasNextPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [company, currentPage, debouncedQuery, setAssets]);

  useEffect(() => {
    getAssets();
  }, [getAssets]);

  // ui section

  const renderFilterSection = () => {
    return (
      <div className={styles.headerWrapperStyle}>
        <div className={styles.filterWrapperStyle}>
          <Input
            placeholder={strings.searchAssets}
            inputStyle={styles.filterInputStyle}
            leftIcon={searchIcon}
            leftIconStyle={styles.filterIconStyle}
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              if (e.target.value.length > 0) {
                setSearchParams({ query: e.target.value });
              } else {
                setSearchParams(searchParams.delete('query'));
              }
            }}
          />
        </div>
      </div>
    );
  };

  const getStatusStyle = (state) => {
    if (state === 'Assigned') {
      return styles.assignedStyle;
    } else if (state === 'Marked lost') {
      return styles.lostStyle;
    } else if (state === 'Unassigned') {
      return styles.unAssignedStyle;
    } else {
    }
  };

  const getBgClolor = (status) => {
    if (status === 'Assigned') {
      return styles.assignedBgStyle;
    } else if (status === 'Unassigned') {
      return styles.unAssignedBgStyle;
    } else if (status === 'Marked lost') {
      return styles.markLostBgStyle;
    }
  };

  const renderListSection = () => {
    return (
      <div className={styles.tableWrapperStyle}>
        <InfiniteScroll
          data={filteredAssets}
          loading={loading}
          hasMore={hasMore}
          setCurrentPage={setCurrentPage}
        >
          <table>
            <thead>
              <tr className={styles.tableHeaderRowStyle}>
                <th>{strings.SerialNo}</th>
                <th>{strings.assetInfo}</th>
                <th>{strings.assignedTo}</th>
                <th>{strings.status}</th>
                <th>{strings.lastActivity}</th>
                {/* <th>{strings.upcomingActivity}</th> */}
              </tr>
            </thead>
            <tbody>
              {filteredAssets.length > 0
                ? filteredAssets.map((item, index) => (
                    <tr
                      key={index}
                      className={styles.tableRowStyle}
                      onClick={() => navigate(routePaths.assetDetails(item._id))}
                    >
                      <td>{'#' + item.asset_uid}</td>
                      <td>
                        <div className={styles.customColStyle}>
                          <span>{item.asset_name}</span>
                          <span className={styles.tableSmallTextStyle}>{item.asset_type}</span>
                        </div>
                      </td>
                      <td>
                        {item.assigned_to ? (
                          <div className={styles.avatarWrapperStyle}>
                            <Avatar
                              src={item.assigned_to.image}
                              label={item.assigned_to.full_name}
                              containerStyle={styles.avatarStyle}
                            />
                            <p>{item.assigned_to.full_name}</p>
                          </div>
                        ) : (
                          <p className={styles.unAssignedStyle}>Unassigned</p>
                        )}
                      </td>
                      <td>
                        <div className={styles.statusViewStyle}>
                          {item.asset_status !== 'Unassigned' && (
                            <div
                              className={classNames(
                                styles.dotViewStyle,
                                getBgClolor(item.asset_status)
                              )}
                            ></div>
                          )}
                          <p className={classNames(getStatusStyle(item.asset_status))}>
                            {item.asset_status}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className={styles.customColStyle}>
                          <span>{item.last_activity}</span>
                          <span className={styles.tableSmallTextStyle}>
                            {moment(item.updated_at).fromNow()}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                : !loading && (
                    <tr className={styles.tableRowStyle} colSpan={5}>
                      <td className={styles.emptyDataStyle}>{strings.noData}</td>
                    </tr>
                  )}
              {loading && (
                <tr>
                  <td colSpan={5}>
                    <div className={styles.loaderSectionStyle}>
                      <Loader containerStyle={styles.loaderStyle} />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    );
  };

  const renderListMobileView = () => {
    return (
      <div className={styles.mobileViewStyle}>
        <InfiniteScroll
          data={filteredAssets}
          loading={loading}
          hasMore={hasMore}
          setCurrentPage={setCurrentPage}
        >
          <div className={styles.mobileListWrapperStyle}>
            {filteredAssets.length > 0
              ? filteredAssets.map((item, index) => (
                  <React.Fragment key={index}>
                    <AssetAndUserCard
                      title={item.asset_name}
                      description={`#${item.asset_uid}`}
                      image={item?.image}
                      leftLable="Last assigned"
                      leftTitle={item.assigned_to ? item.assigned_to?.full_name : '- - - -'}
                      leftDescription={item.assigned_to?.email}
                      rightLable="Status"
                      rightTitle={item.asset_status}
                      onClick={() => navigate(routePaths.assetDetails(item._id))}
                      containerStyle={styles.cardStyle}
                    />
                    {loading && index === filteredAssets.length - 1 && (
                      <div className={styles.loaderSectionStyle}>
                        <Loader containerStyle={styles.loaderMobileViewStyle} />
                      </div>
                    )}
                  </React.Fragment>
                ))
              : !loading && (
                  <div className={styles.emptyViewStyle}>
                    <p className={styles.emptyDataStyle}>No data available</p>
                  </div>
                )}
            {loading && filteredAssets.length === 0 && (
              <div className={styles.loaderSectionStyle}>
                <Loader containerStyle={styles.loaderMobileViewStyle} />
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <div className={styles.listContainerStyle}>
      {renderFilterSection()}
      <div className={styles.tableContainerStyle}>
        {width > 480 ? renderListSection() : renderListMobileView()}
      </div>
    </div>
  );
};

export default AssetList;
