import React, { createContext, useState, useMemo, useEffect, useContext, useCallback } from 'react';
import { useSpinner } from 'providers/spinnerprovider';
import { useAuth } from 'providers/authprovider';
import { getUserCompletedRemindersApi } from 'networking/api/employee';
import { getAssetCompletedRemindersApi } from 'networking/api/asset';
import { socket } from './socketprovider';
import {
  updateAllTicketFunction,
  updateClusterFunc,
  updateClusterMembersFunction,
  updateClustersWhenArchiveFunc,
  updateOverAllAssetFunc,
  updateOverAllAssetWhenAssetUpdatedFunc,
  updateOverAllUsersFunc,
  updateOverAllUsersWhenAssetUnAssignFunc,
  updateOverAllUsersWhenAssetUpdatedFunc,
  updateTicketsStatustoCannotSolve,
  updateTicketsStatustoCompleted,
  updateTicketsStatustoResolved,
  updateTicketsStatustoRevert,
  updateTicketsStatustoTaskUpdated,
  updateTicketsStatustoUserInvite,
  updateTicketsStatustoWorking,
  updateTicketsWhenAssign,
  updateTicketsWhenForword,
} from 'functions';

const UserDataContext = createContext();

const UserDataProvider = (props) => {
  const { company, userType, user } = useAuth();
  const { setIsShowLoading } = useSpinner();
  const [assets, setAssets] = useState([]);
  const [users, setUsers] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [userReminders, setUserReminders] = useState([]);
  const [assetReminders, setAssetReminders] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [isShowReminderAlert, setIsShowReminderAlert] = useState(false);
  const [reminderAlertData, setReminderAlertData] = useState({});

  useEffect(() => {
    let remindersData = [];
    remindersData.push(
      ...[...userReminders].map((item) => ({ notificationType: 'User reminder', ...item }))
    );
    remindersData.push(
      ...[...assetReminders].map((item) => ({ notificationType: 'Asset reminder', ...item }))
    );
    remindersData = remindersData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
    setNotifications(remindersData);
  }, [userReminders, assetReminders]);

  const socketListener = useCallback(() => {
    socket.on('user-create', (data) => {
      setUsers((prev) => {
        let prevData = [...prev];
        let findIndex = prevData.findIndex((item) => item._id === data._id);
        if (findIndex !== -1) {
          prevData[findIndex] = data;
          return prevData;
        } else {
          return [data, ...prevData];
        }
      });
    });
    socket.on('user-update', (data) => {
      setUsers((prev) => {
        let previous_data = [...prev];
        let findIndex = previous_data.findIndex((item) => item._id === data._id);
        if (findIndex !== -1) {
          previous_data[findIndex] = data;
          return previous_data;
        } else {
          return previous_data;
        }
      });
    });
    socket.on('user-delete', (data) => {
      setUsers((prev) => {
        let previous = [...prev];
        return previous.filter((item) => item._id !== data._id);
      });
    });
    socket.on('asset-create', (data) => {
      setAssets((prev) => {
        let prevData = [...prev];
        let findIndex = prevData.findIndex((item) => item._id === data._id);
        if (findIndex !== -1) {
          prevData[findIndex] = data;
          return prevData;
        } else {
          return [data, ...prevData];
        }
      });
    });
    socket.on('asset-assign', (data) => {
      setUsers((prev) => {
        let previous_data = [...prev];
        return updateOverAllUsersFunc(previous_data, data);
      });
      setAssets((prev) => {
        let previous = [...prev];
        return updateOverAllAssetFunc(previous, data);
      });
    });
    socket.on('asset-assign-other', (data) => {
      setUsers((prev) => {
        let previous_data = [...prev];
        return updateOverAllUsersFunc(previous_data, data);
      });
      setAssets((prev) => {
        let previous = [...prev];
        return updateOverAllAssetFunc(previous, data);
      });
    });
    socket.on('asset-unassign', (data) => {
      setUsers((prev) => {
        let previous_data = [...prev];
        return updateOverAllUsersWhenAssetUnAssignFunc(previous_data, data);
      });
      setAssets((prev) => {
        let previous = [...prev];
        return updateOverAllAssetFunc(previous, data);
      });
    });
    socket.on('asset-update', (data) => {
      setUsers((prev) => {
        let previous_data = [...prev];
        return updateOverAllUsersWhenAssetUpdatedFunc(previous_data, data);
      });
      setAssets((prev) => {
        let previous = [...prev];
        return updateOverAllAssetWhenAssetUpdatedFunc(previous, data);
      });
    });
    socket.on('asset-delete', (data) => {
      setAssets((prev) => {
        let previous = [...prev];
        return previous.filter((item) => item._id !== data._id);
      });
      setTickets((prev) => {
        let previous_tickets = [...prev];
        return previous_tickets.map((ticket) => {
          if (ticket.asset._id === data._id) {
            ticket.ticket_status = 'Closed';
            ticket.updated_at = data.updated_at;
            ticket.last_activity = data.last_activity
              ? data.last_activity.length > 0
                ? data.last_activity[0]
                : ticket?.last_activity
              : ticket?.last_activity;
          }
          return ticket;
        });
      });
    });
    // cluster socketss
    socket.on('cluster-create', (data) => {
      setClusters((prev) => {
        let findIndex = prev.findIndex((item) => item._id === data._id);
        if (findIndex !== -1) {
          prev[findIndex] = data;
          return prev;
        } else {
          return [data, ...prev];
        }
      });
    });
    socket.on('cluster-update', (data) => {
      setClusters((prev) => {
        let previous_data = [...prev];
        return updateClusterFunc(previous_data, data);
      });
    });
    socket.on('cluster-inspect', (data) => {
      setClusters((prev) => {
        let previous_data = [...prev];
        let cluster_index = previous_data.findIndex((item) => item._id === data.mcluster);
        if (
          cluster_index !== -1 &&
          previous_data[cluster_index].last_report &&
          previous_data[cluster_index].last_report._id === data._id
        ) {
          previous_data[cluster_index].last_report = data;
        }
        return previous_data;
      });
    });
    socket.on('cluster-archive', (data) => {
      setClusters((prev) => {
        let previous_data = [...prev];
        return updateClustersWhenArchiveFunc(previous_data, data);
      });
    });
    socket.on('cluster-asset-unhold', (data) => {
      setClusters((prev) => {
        let previous_data = [...prev];
        let cluster_index = previous_data.findIndex((item) => item._id === data.mcluster);
        if (cluster_index !== -1 && previous_data[cluster_index].last_report) {
          let asset_index = previous_data[cluster_index].last_report.cluster_assets.findIndex(
            (item) => item._id === data.asset
          );
          if (asset_index !== -1) {
            previous_data[cluster_index].last_report.cluster_assets[asset_index].on_hold =
              undefined;
          }
        }
        return previous_data;
      });
    });
    socket.on('cluster-users-invite', (data) => {
      setClusters((prev) => {
        let previous_data = [...prev];
        return updateClusterMembersFunction(previous_data, data);
      });
    });
    socket.on('cluster-users-remove', (data) => {
      setClusters((prev) => {
        let prev_data = [...prev];
        let findCluster = prev_data.findIndex((item) => item._id === data._id);
        if (findCluster !== -1) {
          let updatedUserIds = data.users.map((item) => item.user._id);
          let previousUserIds = prev_data[findCluster].users.map((item) => item.user._id);
          let filteredUserIds = previousUserIds.filter((item) => !updatedUserIds.includes(item));
          if (filteredUserIds.includes(user?._id)) {
            prev_data = prev_data.filter((item) => item._id !== data._id);
          }
        }
        return prev_data;
      });
    });
    // tickets
    socket.on('ticket-create', (data) => {
      setTickets((prev) => {
        let previous_data = [...prev];
        return updateAllTicketFunction(previous_data, data);
      });
    });
    socket.on('ticket-forward', (data) => {
      setTickets((prev) => {
        let previous_data = [...prev];
        return updateTicketsWhenForword(previous_data, data);
      });
    });
    socket.on('ticket-assign', (data) => {
      setTickets((prev) => {
        let previous_data = [...prev];
        return updateTicketsWhenAssign(previous_data, data);
      });
    });
    socket.on('ticket-working', (data) => {
      setTickets((prev) => {
        let previous_data = [...prev];
        return updateTicketsStatustoWorking(previous_data, data);
      });
    });
    socket.on('ticket-completed', (data) => {
      setTickets((prev) => {
        if (prev) {
          let previous_data = [...prev];
          return updateTicketsStatustoCompleted(previous_data, data);
        }
      });
    });
    socket.on('ticket-resolved', (data) => {
      setTickets((prev) => {
        if (prev) {
          let previous_data = [...prev];
          return updateTicketsStatustoResolved(previous_data, data);
        }
      });
    });
    socket.on('ticket-revert', (data) => {
      setTickets((prev) => {
        let previous_data = [...prev];
        return updateTicketsStatustoRevert(previous_data, data);
      });
    });
    socket.on('ticket-cannot-solve', (data) => {
      setTickets((prev) => {
        let previous_data = [...prev];
        return updateTicketsStatustoCannotSolve(previous_data, data);
      });
    });
    socket.on('ticket-task-update', (data) => {
      setTickets((prev) => {
        if (Array.isArray(prev)) {
          let previous_data = [...prev];
          return updateTicketsStatustoTaskUpdated(previous_data, data);
        } else {
          return prev;
        }
      });
    });
    socket.on('ticket-users-invite', (data) => {
      setTickets((prev) => {
        let previous_data = [...prev];
        return updateTicketsStatustoUserInvite(previous_data, data);
      });
    });
    socket.on('ticket-users-remove', (data) => {
      setTickets((prev) => {
        let prev_data = [...prev];
        let findTicket = prev_data.findIndex((item) => item._id === data._id);
        if (findTicket !== -1) {
          let updatedUserIds = data.ticket_users.map((item) => item.user._id);
          let previousUserIds = prev_data[findTicket].ticket_users.map((item) => item.user._id);
          let filteredUserIds = previousUserIds.filter((item) => !updatedUserIds.includes(item));
          if (filteredUserIds.includes(user?._id)) {
            prev_data = prev_data.filter((item) => item._id !== data._id);
          }
        }
        return prev_data;
      });
    });
    socket.on('user-reminder-trigger', (data) => {
      setIsShowReminderAlert(true);
      setReminderAlertData({ ...data, status: [data.status] });
      setNotifications((prev) => {
        let previous_data = [...prev];
        let reminder_index = previous_data.findIndex((item) => item._id === data._id);
        if (reminder_index !== -1) {
          previous_data[reminder_index] = {
            ...data,
            status: [data.status],
            notificationType: 'User reminder',
          };
          return previous_data;
        } else {
          return [
            { ...data, status: [data.status], notificationType: 'User reminder' },
            ...previous_data,
          ];
        }
      });
    });
    socket.on('asset-reminder-trigger', (data) => {
      setIsShowReminderAlert(true);
      setReminderAlertData({ ...data, status: [data.status] });
      setNotifications((prev) => {
        let previous_data = [...prev];
        let reminder_index = previous_data.findIndex((item) => item._id === data._id);
        if (reminder_index !== -1) {
          previous_data[reminder_index] = {
            ...data,
            status: [data.status],
            notificationType: 'Asset reminder',
          };
          return previous_data;
        } else {
          return [
            { ...data, status: [data.status], notificationType: 'Asset reminder' },
            ...previous_data,
          ];
        }
      });
    });
  }, [user]);

  useEffect(() => {
    socketListener();
  }, [socketListener]);

  const getUserCompletedReminders = useCallback(async () => {
    try {
      if (company) {
        setIsShowLoading(true);
        let response = await getUserCompletedRemindersApi(company._id);
        setUserReminders(response.data.data);
        setIsShowLoading(false);
      }
    } catch (error) {
      setIsShowLoading(false);
    }
  }, [company, setIsShowLoading]);

  const getAssetCompletedReminders = useCallback(async () => {
    try {
      if (company) {
        setIsShowLoading(true);
        let response = await getAssetCompletedRemindersApi(company._id);
        setAssetReminders(response.data.data);
        setIsShowLoading(false);
      }
    } catch (error) {
      setIsShowLoading(false);
    }
  }, [company, setIsShowLoading]);

  useEffect(() => {
    if (userType && company) {
      getUserCompletedReminders();
      getAssetCompletedReminders();
    }
  }, [userType, company, getUserCompletedReminders, getAssetCompletedReminders]);

  const memoizedValue = useMemo(
    () => ({
      assets,
      setAssets,
      users,
      setUsers,
      tickets,
      setTickets,
      clusters,
      setClusters,
      userReminders,
      setUserReminders,
      assetReminders,
      setAssetReminders,
      notifications,
      setNotifications,
      isShowReminderAlert,
      setIsShowReminderAlert,
      reminderAlertData,
      setReminderAlertData,
    }),
    [
      assets,
      setAssets,
      users,
      setUsers,
      tickets,
      setTickets,
      clusters,
      setClusters,
      userReminders,
      setUserReminders,
      assetReminders,
      setAssetReminders,
      notifications,
      setNotifications,
      isShowReminderAlert,
      setIsShowReminderAlert,
      reminderAlertData,
      setReminderAlertData,
    ]
  );

  return (
    <UserDataContext.Provider value={memoizedValue}>{props.children}</UserDataContext.Provider>
  );
};

export default UserDataProvider;

export const useUserData = () => {
  const context = useContext(UserDataContext);

  if (context === undefined) {
    throw new Error('useUserData must be used within UserDataProvider');
  }
  return context;
};
