import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useAppData } from 'providers/appdataprovider';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Image from 'components/common/image';
import {
  avatar1Icon,
  bellIcon,
  chevronDownFillBlackIcon,
  hamBurgerIcon,
  logoutIcon,
  searchIcon,
} from 'resources/images';
import PopOver from 'components/common/pop-over';
import { useAuth } from 'providers/authprovider';
import Chip from 'components/common/chip';
import AvatarInfoCard from 'components/common/avatar-info-card';
import { useNavigate } from 'react-router';
import Modal from 'components/common/modal';
import SearchBox from 'components/search-box';
import Notifications from 'components/notifications';
import routePaths from 'routes/paths';
import { socket } from 'providers/socketprovider';
import SuccessCard from 'components/success-card';
import { useStrings } from 'providers/stringsprovider';
import styles from './styles.module.css';

const Header = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { user, company, setCompany, logout } = useAuth();
  const { setShowNavMenu, showHeader, breadcrumbs } = useAppData();
  const { strings } = useStrings();
  const [profileRef, setProfileRef] = useState();
  const [profileOpen, setProfileOpen] = useState(false);
  const [swicthCompany, setSwitchCompany] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [notificationsModal, setNotificationsModal] = useState(false);
  const [notifyRef, setNotifyRef] = useState();
  const [userDeletedModal, setUserDeletedModal] = useState(false);

  const logOutAccount = async () => {
    await logout();
  };

  const socketListener = useCallback(() => {
    socket.on('user-delete', (data) => {
      if (user._id === data._id) {
        setUserDeletedModal(true);
      }
    });
  }, [user]);

  useEffect(() => {
    socketListener();
  }, [socketListener]);

  const renderProfilePopSection = () => {
    return (
      <PopOver
        reference={profileRef}
        show={profileOpen}
        showOverlay
        containerStyle={styles.profilePopupStyle}
        onClose={() => setProfileOpen(false)}
        placement="bottom-end"
      >
        <div className={styles.profileWrapperStyle}>
          <AvatarInfoCard title={user?.full_name} description={user?.email} icon={user?.image} />
          <Chip label={user?.account_type} />
        </div>
        {user?.companies.length > 1 && (
          <div className={styles.switchAccWrapperStyle}>
            <div
              className={styles.accountWrapperStyle}
              onClick={() => setSwitchCompany(!swicthCompany)}
            >
              <p className={styles.accTextStyle}>{company?.company_name}</p>
              <Image src={chevronDownFillBlackIcon} containerStyle={styles.downArrowImgStyle} />
            </div>
            {swicthCompany && (
              <div className={styles.companyListWrapperStyle}>
                {user?.companies.map((item, index) => (
                  <div
                    key={'company' + index}
                    onClick={() => {
                      setCompany(item);
                      localStorage.setItem('company', item._id);
                      setSwitchCompany(!swicthCompany);
                      navigate(routePaths.dashboard);
                    }}
                    className={classNames(
                      styles.accDataWrapperStyle,
                      item._id === company._id && styles.selectedAccDataWrapperStyle
                    )}
                  >
                    {item?.company_name}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <div className={styles.logOutBlockStyle}>
          <Image src={logoutIcon} containerStyle={styles.logOutImgStyle} />
          <p className={styles.logOutTextStyle} onClick={logOutAccount}>
            Log out
          </p>
        </div>
      </PopOver>
    );
  };

  const renderSearchModal = () => {
    return (
      <Modal
        show={searchModal}
        showOverlay={true}
        handleClickOutSide={() => setSearchModal(false)}
        containerStyle={styles.searchModalViewStyle}
      >
        <SearchBox setClose={setSearchModal} />
      </Modal>
    );
  };

  const renderNotificationsModal = () => {
    return (
      <PopOver
        reference={notifyRef}
        show={notificationsModal}
        showOverlay
        placement="bottom-end"
        containerStyle={styles.notificationsModalViewStyle}
      >
        <Notifications onClose={() => setNotificationsModal(false)} />
      </PopOver>
    );
  };

  const renderUserDeletedModal = () => {
    return (
      <Modal
        show={userDeletedModal}
        showOverlay={true}
        handleClickOutSide={() => {
          logOutAccount();
          setUserDeletedModal(false);
        }}
        containerStyle={styles.userDeleteModalStyle}
      >
        <SuccessCard
          title={'Deleted!'}
          description={`Your account is deleted. You will be redirected to login page.`}
          titleStyle={styles.userDeletedAlertTitleStyle}
          leftBtnTitle={strings.continue}
          leftAction={() => {
            logOutAccount();
            setUserDeletedModal(false);
            navigate(routePaths.login);
          }}
        />
      </Modal>
    );
  };

  return (
    <React.Fragment>
      {showHeader && (
        <div className={styles.headerWrapperStyle}>
          <div className={styles.headerLeftSectionStyle}>
            {width <= 480 && (
              <Image
                src={hamBurgerIcon}
                onClick={() => setShowNavMenu(true)}
                containerStyle={styles.headerIconStyle}
              />
            )}
            <div className={styles.headerTitleWrapperStyle}>
              {breadcrumbs.map((bred, index) => (
                <React.Fragment key={index}>
                  <p
                    className={classNames(
                      styles.headerTitleStyle,
                      index === breadcrumbs.length - 1 &&
                        breadcrumbs.length > 1 &&
                        styles.inActiveTitleStyle,
                      index !== breadcrumbs.length - 1 &&
                        breadcrumbs.length > 1 &&
                        styles.cursorStyle
                    )}
                    onClick={() => {
                      bred.route && navigate(bred.route);
                    }}
                  >
                    {bred.title}
                  </p>
                  {index < breadcrumbs.length - 1 && <p>{'/'}</p>}
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className={styles.headerRightSectionStyle}>
            <Image
              src={searchIcon}
              onClick={() => setSearchModal(true)}
              containerStyle={styles.headerIconStyle}
            />
            <React.Fragment>
              <Image
                src={bellIcon}
                onClick={() => setNotificationsModal(true)}
                containerStyle={styles.headerIconStyle}
                reference={setNotifyRef}
              />
              <div ref={setProfileRef}>
                <Image
                  src={avatar1Icon}
                  containerStyle={classNames(styles.headerIconStyle, styles.profileIconStyle)}
                  onClick={() => setProfileOpen(true)}
                >
                  {renderProfilePopSection()}
                </Image>
              </div>
            </React.Fragment>
          </div>
          {renderSearchModal()}
          {renderNotificationsModal()}
          {renderUserDeletedModal()}
        </div>
      )}
    </React.Fragment>
  );
};

export default Header;
