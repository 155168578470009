import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from 'components/common/image';
import styles from './styles.module.css';

const Chip = (props) => {
  const {
    reference,
    label,
    onClick,
    containerStyle,
    labelStyle,
    leftIcon,
    leftIconStyle,
    rightIcon,
    rightIconStyle,
    children,
  } = props;

  return (
    <div
      className={classNames(styles.containerStyle, containerStyle, onClick && styles.pointerStyle)}
      onClick={onClick}
      ref={reference}
    >
      {leftIcon && (
        <Image
          containerStyle={classNames(styles.leftIconStyle, leftIconStyle)}
          src={leftIcon}
          alt="icon"
        />
      )}
      <span className={classNames(styles.labelStyle, labelStyle)}>{label}</span>
      {rightIcon && (
        <Image
          containerStyle={classNames(styles.rightIconStyle, rightIconStyle)}
          src={rightIcon}
          alt="icon"
        />
      )}
      {children && children}
    </div>
  );
};

Chip.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  containerStyle: PropTypes.string,
  labelStyle: PropTypes.string,
  reference: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  leftIcon: PropTypes.string,
  leftIconStyle: PropTypes.string,
  rightIcon: PropTypes.string,
  rightIconStyle: PropTypes.string,
};

export default Chip;
