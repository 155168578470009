import React from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';
import { useAppData } from 'providers/appdataprovider';
import { useUserData } from 'providers/userdataprovider';
import useWindowDimensions from 'hooks/useWindowDimensions';
import SideBar from 'components/sidebar';
import Header from 'components/header';
import RightSideBar from 'components/right-sidebar';
import Modal from 'components/common/modal';
import ReminderDetails from 'components/reminder-details';
import styles from './style.module.css';

const DashboardLayout = () => {
  const { width } = useWindowDimensions();
  const { showHeader } = useAppData();
  const { isShowReminderAlert, setIsShowReminderAlert, reminderAlertData, setReminderAlertData } =
    useUserData();

  const renderReminderAlertModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={isShowReminderAlert}
        containerStyle={styles.remainderOverModalStyle}
        overlayStyle={styles.remainderOverModalOverlayStyle}
      >
        <ReminderDetails
          reminder={reminderAlertData}
          onCloseAction={() => {
            setIsShowReminderAlert(false);
            setReminderAlertData({});
          }}
          type="reminder_alert"
        />
      </Modal>
    );
  };

  return (
    <div className={styles.dashboardLayoutStyle}>
      <SideBar />

      <div className={styles.headerAndContentWrapperStyle}>
        <Header />
        <div
          className={classNames(
            styles.contenWrappertStyle,
            !showHeader && styles.contentWithNoHeeaderStyle
          )}
        >
          {renderReminderAlertModal()}
          <Outlet />
        </div>
      </div>
      {width > 480 && <RightSideBar />}
    </div>
  );
};

export default DashboardLayout;
