import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import Image from 'components/common/image';
import { chevronDownFillGrayIcon } from 'resources/images';
import Calendar from 'components/common/calendar';
import CustomCalendar from 'components/common/calendar-custom';
import PopOver from 'components/common/pop-over';
import styles from './styles.module.css';

const DatePicker = (props) => {
  const {
    type = 'default',
    format = 'DD/MM/YYYY',
    placeholder = 'DD/MM/YYYY',
    isOpen = false,
    labelText,
    value,
    onChange = () => {},
    minDate,
    maxDate,
    disabled = false,
    icon,
    containerStyle,
    labelTextStyle,
    valueStyle,
    iconStyle,
    reports = [],
  } = props;

  const [pickerOpen, setPickerOpen] = useState(isOpen);
  const [pickerRef, setPickerRef] = useState();

  return (
    <div className={styles.wrapperStyle}>
      {labelText && (
        <p className={classNames(styles.labelTextStyle, labelTextStyle)}>{labelText}</p>
      )}
      <div
        className={classNames(
          styles.pikcerContainerStyle,
          disabled && styles.disabledStyle,
          containerStyle
        )}
        onClick={() => setPickerOpen(true)}
        ref={setPickerRef}
      >
        <span
          className={classNames(
            styles.labelStyle,
            value ? styles.valueStyle : styles.placeHolderStyle,
            valueStyle
          )}
        >
          {value ? moment(value).format(format) : placeholder}
        </span>
        <Image
          src={icon ? icon : chevronDownFillGrayIcon}
          containerStyle={classNames(styles.iconWrapperStyle, iconStyle)}
          imgStyle={pickerOpen && !icon ? styles.iconRotateStyle : ''}
        />
        <PopOver reference={pickerRef} show={pickerOpen} onClose={() => setPickerOpen(false)}>
          {type === 'default' && (
            <Calendar
              value={value}
              minDate={minDate}
              maxDate={maxDate}
              onChange={(date) => {
                onChange(date);
                setPickerOpen(false);
              }}
            />
          )}
          {type === 'custom' && (
            <CustomCalendar
              value={value}
              minDate={minDate}
              maxDate={maxDate}
              onChange={(date) => {
                onChange(date);
                setPickerOpen(false);
              }}
              reports={reports}
            />
          )}
        </PopOver>
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  type: PropTypes.oneOf(['default', 'custom']),
  labelText: PropTypes.string,
  labelTextStyle: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  placeholder: PropTypes.string,
  isOpen: PropTypes.bool,
  format: PropTypes.string,
  reports: PropTypes.array,
  icon: PropTypes.string,
  iconStyle: PropTypes.string,
  valueStyle: PropTypes.string,
  containerStyle: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DatePicker;
