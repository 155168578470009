import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Switch = (props) => {
  const { checked, onChange, containerStyle, switchStyle } = props;

  return (
    <div
      onClick={onChange}
      className={classNames(
        styles.switchContainerStyle,
        checked && styles.checkedStyle,
        containerStyle
      )}
    >
      <div className={classNames(styles.switchViewStyle, switchStyle)}></div>
    </div>
  );
};

Switch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  containerStyle: PropTypes.string,
  switchStyle: PropTypes.string,
};

export default Switch;
