import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { assetIcon, closeIcon, searchIcon } from 'resources/images';
import routePaths from 'routes/paths';
import Input from 'components/common/input';
import Capsule from 'components/common/capsule';
import AvatarInfoCard from 'components/common/avatar-info-card';
import { getAllEmployeesWithLastAssetApi } from 'networking/api/employee';
import { debounce } from 'lodash';
import { useAuth } from 'providers/authprovider';
import { getAssetsByPageApi } from 'networking/api/asset';
import { getAllTicketsApi } from 'networking/api/alltickets';
import Loader from 'components/common/loader';
import styles from './styles.module.css';

const SearchBox = (props) => {
  const { setClose } = props;
  const { company } = useAuth();
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [filterBy, setFilterBy] = useState('All');
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [assets, setAssets] = useState([]);
  const [assetsCount, setAssetsCount] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [ticketsCount, setTicketsCount] = useState(0);
  const displayLimit = 3;
  const Options = ['All', 'Users', 'Assets', 'Tickets'];

  const getSearchResults = useMemo(
    () =>
      debounce(async (searchQuery) => {
        if (!searchQuery) {
          setUsers([]);
          setAssets([]);
          setTickets([]);
          return;
        }
        try {
          setLoading(true);
          const [usersResponse, assetsResponse, ticketsResponse] = await Promise.all([
            getAllEmployeesWithLastAssetApi(company?._id, 1, searchQuery, displayLimit),
            getAssetsByPageApi(company?._id, 1, searchQuery, displayLimit),
            getAllTicketsApi(company?._id, 1, searchQuery, displayLimit),
          ]);
          setUsers(usersResponse.data.data.users);
          setUsersCount(usersResponse.data.data.meta.totalItems);
          setAssets(assetsResponse.data.data.assets);
          setAssetsCount(assetsResponse.data.data.meta.totalItems);
          setTickets(ticketsResponse.data.data.tickets);
          setTicketsCount(ticketsResponse.data.data.meta.totalItems);
          setLoading(false);
        } catch (err) {
          setLoading(false);
        }
      }, 500),
    [company]
  );

  useEffect(() => {
    getSearchResults(query);
  }, [query, getSearchResults]);

  const renderSearchInput = () => {
    return (
      <div className={styles.searchInputWrapperStyle}>
        <Input
          placeholder="Type anything here . . ."
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          leftIcon={searchIcon}
          rightIcon={closeIcon}
          inputStyle={styles.searchInputStyle}
          onClickRightIcon={() => setClose(false)}
        />
      </div>
    );
  };

  const renderSearchResults = () => {
    return (
      <div className={styles.searchResultsWrapperStyle}>
        {renderFilterBySection()}
        {loading && <Loader />}
        {(filterBy === 'All' || filterBy === 'Users') && renderUserSection()}
        {(filterBy === 'All' || filterBy === 'Assets') && renderAssetsSection()}
        {(filterBy === 'All' || filterBy === 'Tickets') && renderTicketsSection()}
      </div>
    );
  };

  const renderFilterBySection = () => {
    return (
      <div className={styles.eachSectionStyle}>
        <p className={styles.lableTextStyle}>Filter by</p>
        <div className={styles.filterOptionsViewStyle}>
          {Options.map((opt, index) => (
            <Capsule
              key={index}
              label={opt}
              onClick={() => setFilterBy(opt)}
              labelStyle={classNames(
                styles.capsuleLableStyle,
                filterBy === opt && styles.activeOptionTextStyle
              )}
              containerStyle={classNames(
                styles.capsuleViewStyle,
                filterBy === opt && styles.activeOptionStyle
              )}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderUserSection = () => {
    return (
      <div className={styles.eachSectionStyle}>
        <p className={styles.lableTextStyle}>
          Users <span>{usersCount}</span>
        </p>
        {users.length > 0 && (
          <div className={styles.usersViewStyle}>
            {users?.slice(0, filterBy === 'Users' ? users.length : 3).map((user, index) => (
              <AvatarInfoCard
                key={'usr' + index}
                icon={user.image}
                title={user.full_name}
                description={user.email}
                containerStyle={styles.blockListItemStyle}
                avatarStyle={styles.blockListItemUserIconStyle}
                titleStyle={styles.blockListItemTitleStyle}
                onClick={() => {
                  navigate(routePaths.employeeDetails(user._id));
                  setClose(false);
                }}
              />
            ))}
            {usersCount > displayLimit && (
              <p
                className={styles.blockLinkStyle}
                onClick={() => {
                  navigate(routePaths.employeeList + `${query ? '?query=' + query : ''}`);
                  setClose(false);
                }}
              >
                See all results
              </p>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderAssetsSection = () => {
    return (
      <div className={styles.eachSectionStyle}>
        <p className={styles.lableTextStyle}>
          Assets <span>{assetsCount}</span>
        </p>
        {assets.length > 0 && (
          <div className={styles.usersViewStyle}>
            {assets?.slice(0, filterBy === 'Assets' ? assets.length : 3).map((asset, index) => (
              <AvatarInfoCard
                key={'asset' + index}
                icon={asset.image || assetIcon}
                title={`${asset.asset_uid} : ${asset.asset_name}`}
                description={
                  asset.assigned_to ? `Assigned to ${asset.assigned_to.full_name}` : 'UnAssigned'
                }
                containerStyle={styles.blockListItemStyle}
                avatarStyle={styles.blockListItemAssetIconStyle}
                titleStyle={styles.blockListItemTitleStyle}
                onClick={() => {
                  navigate(routePaths.assetDetails(asset._id));
                  setClose(false);
                }}
              />
            ))}
            {assetsCount > displayLimit && (
              <p
                className={styles.blockLinkStyle}
                onClick={() => {
                  navigate(routePaths.assetList + `${query ? '?query=' + query : ''}`);
                  setClose(false);
                }}
              >
                See all resultes
              </p>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderTicketsSection = () => {
    return (
      <div className={styles.eachSectionStyle}>
        <p className={styles.lableTextStyle}>
          Tickets <span>{ticketsCount}</span>
        </p>
        {tickets.length > 0 && (
          <div className={styles.usersViewStyle}>
            {tickets?.slice(0, filterBy === 'Tickets' ? tickets.length : 3).map((ticket, index) => (
              <AvatarInfoCard
                key={'ticket' + index}
                icon={ticket?.image || ticket?.asset?.image}
                title={`${ticket.ticket_uid} : ${ticket.subject}`}
                description={ticket.ticket_status}
                containerStyle={styles.blockListItemStyle}
                avatarStyle={styles.blockListItemIconStyle}
                titleStyle={styles.blockListItemTitleStyle}
                onClick={() => {
                  navigate(routePaths.ticketList + `?selected=${ticket._id}`);
                  setClose(false);
                }}
              />
            ))}
            {ticketsCount > displayLimit && (
              <p
                className={styles.blockLinkStyle}
                onClick={() => {
                  navigate(routePaths.ticketList + `${query ? '?query=' + query : ''}`);
                  setClose(false);
                }}
              >
                See all resultes
              </p>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.searchBoxWrapperStyle}>
      {renderSearchInput()}
      {query.length > 0 && renderSearchResults()}
    </div>
  );
};

SearchBox.propTypes = {
  setClose: PropTypes.func,
};

export default SearchBox;
