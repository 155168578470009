import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  checkBoxFillWhiteIcon,
  chevronDownBlackIcon,
  chevronDownFillBlueIcon,
  flagFillWhiteIcon,
  optionsHorizentBlackIcon,
  optionsHorizentGrayIcon,
  raceIcon,
  timerWhite,
  unCheckWhiteIcon,
} from 'resources/images';
import classNames from 'classnames';
import Image from 'components/common/image';
import Capsule from 'components/common/capsule';
import PopOver from 'components/common/pop-over';
import { useAuth } from 'providers/authprovider';
import { timeLineIcons } from 'resources/data';
import moment from 'moment';
import TicketOverViewCard from 'sections/ticket/ticket-overview-card';
import ChatInput from 'components/common/chat-input';
import Modal from 'components/common/modal';
import TicketForward from 'sections/ticket/ticket-forward';
import { useUserData } from 'providers/userdataprovider';
import { getTicketDataApi, updateTaskItemStateApi } from 'networking/api/alltickets';
import Loader from 'components/common/loader';
import { socket } from 'providers/socketprovider';
import TicketAssign from 'sections/ticket/ticket-assign';
import TicketHeader from 'sections/ticket/ticket-header';
import { useStrings } from 'providers/stringsprovider';
import Avatar from 'components/common/avatar';
import { sendTicketMessageApi } from 'networking/api/tickets';
import { useAppData } from 'providers/appdataprovider';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Info from 'components/common/info';
import { useToast } from 'providers/toastprovider';
import SuccessCard from 'components/success-card';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import styles from './styles.module.css';

const TicketDetails = (props) => {
  const { id } = props;
  const { user } = useAuth();
  const navigate = useNavigate();
  const { strings } = useStrings();
  const toast = useToast();
  const { setTickets } = useUserData();
  const { setShowHeader } = useAppData();
  const { width } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();
  const [ticketData, setTicketData] = useState(null);
  const [selectedTab, setSelectedTab] = useState('');

  const [loader, setLoader] = useState(false);

  const [checkListTimeLIneRef, setCheckListTimeLIneRef] = useState(null);
  const [message, setMessage] = useState('');
  const [selectedThread, setSelectedThread] = useState('');
  const [conversations, setConversations] = useState([]);
  const [showComments, setShowComments] = useState(true);
  const [taskConversations, setTaskConversations] = useState([]);
  const [complaintConversations, setComplaintConversations] = useState([]);
  const [conversationsLimit, setConversationsLimit] = useState(3);
  const [isShowForwardModal, setIsShowForwardModal] = useState(false);
  const [isShowTakeActionModal, setIsShowTakeActionModal] = useState(false);
  const [showCheckListMenu, setShowCheckListMenu] = useState([]);
  const [showCheckList, setShowCheckList] = useState([]);
  const [userCanEdit, setUserCanEdit] = useState(false);
  const [taskActivityModal, setTaskActivityModal] = useState(null);
  const [checkListLoader, setCheckListLoader] = useState(false);
  const [showUserRomovedPopup, setShowUserRomovedPopup] = useState(false);

  const ticketTabs = [
    {
      title: strings.overview,
      value: strings.overview,
    },
    {
      title: strings.task,
      value: strings.task,
    },
    {
      title: strings.timeline,
      value: strings.timeline,
    },
    {
      title: strings.complaintInfo,
      value: strings.overview,
    },
  ];

  useEffect(() => {
    if (width <= 480) {
      setShowHeader(false);
    }
  }, [width, setShowHeader]);

  // socket functionality
  const socketListner = useCallback(() => {
    socket.on('ticket-forward', (data) => {
      setTicketData((prev) => {
        let prev_data = { ...prev };
        if (data._id === id) {
          prev_data = data;
        }
        return prev_data;
      });
    });
    socket.on('ticket-assign', (data) => {
      setTicketData((prev) => {
        let prev_data = { ...prev };
        if (data._id === id) {
          prev_data = data;
        }
        return prev_data;
      });
    });
    socket.on('ticket-working', (data) => {
      setTicketData((prev) => {
        let prev_data = { ...prev };
        if (data.ticket === id) {
          prev_data = {
            ...prev_data,
            activity: data.activity,
            ticket_status: 'In progress',
          };
        }
        return prev_data;
      });
    });
    socket.on('ticket-completed', (data) => {
      setTicketData((prev) => {
        let prev_data = { ...prev };
        if (data.ticket === id) {
          prev_data = { ...prev_data, activity: data.activity, ticket_status: 'In review' };
        }
        return prev_data;
      });
    });
    socket.on('ticket-resolved', (data) => {
      setTicketData((prev) => {
        let prev_data = { ...prev };
        if (data.ticket === id) {
          prev_data = {
            ...prev_data,
            activity: data.activity,
            ticket_status: 'Closed',
          };
        }
        return prev_data;
      });
    });
    socket.on('ticket-revert', (data) => {
      setTicketData((prev) => {
        let prev_data = { ...prev };
        if (data._id === id) {
          prev_data = data;
        }
        return prev_data;
      });
    });
    socket.on('ticket-cannot-solve', (data) => {
      setTicketData((prev) => {
        let prev_data = { ...prev };
        if (data.ticket === id) {
          prev_data = {
            ...prev_data,
            activity: data.activity,
            ticket_status: 'In review*',
          };
        }
        return prev_data;
      });
    });
    socket.on('ticket-task-update', (data) => {
      setTicketData((prev) => {
        if (prev) {
          let previous_data = { ...prev };
          if (previous_data) {
            if (previous_data._id === data.task.ticket) {
              let taskIndex = previous_data?.tasks.findIndex((item) => item._id === data.task._id);
              if (taskIndex !== -1) {
                previous_data.tasks[taskIndex] = data.task;
              }
              previous_data.ticket_status = 'In progress';
              previous_data.activity = data.activity;
            }
          }
          return previous_data;
        } else {
          return prev;
        }
      });
    });
    socket.on('ticket-users-invite', (data) => {
      setTicketData((prev) => {
        let prev_data = { ...prev };
        if (prev_data) {
          if (prev_data._id === data._id) {
            return { ...prev_data, ...data };
          } else {
            return prev_data;
          }
        } else {
          return prev_data;
        }
      });
    });
    socket.on('ticket-users-access', (data) => {
      setTicketData((prev) => {
        let prev_data = { ...prev };
        if (data._id === id) {
          prev_data = {
            ...prev_data,
            ...data,
          };
        }
        return prev_data;
      });
    });
    socket.on('ticket-users-remove', (data) => {
      let updatedUserIds = data.ticket_users.map((item) => item.user._id);
      setTicketData((prev) => {
        let prev_data = { ...prev };
        let previousUserIds = prev_data.ticket_users.map((item) => item.user._id);
        let filteredUserIds = previousUserIds.filter((item) => !updatedUserIds.includes(item));
        if (filteredUserIds.includes(user?._id)) {
          setShowUserRomovedPopup(true);
        }
        if (prev_data) {
          if (prev_data._id === data._id) {
            return { ...prev_data, ...data };
          } else {
            return prev_data;
          }
        } else {
          return prev_data;
        }
      });
    });
    socket.on('ticket-comment-create', (data) => {
      if (data.ticket === id && data.type === 'Task') {
        setTaskConversations((prev) => {
          let previous_data = [...prev];
          let findIndex = previous_data.findIndex((item) => item._id === data._id);
          if (findIndex !== -1) {
            previous_data[findIndex] = data;
          } else {
            previous_data = [data, ...previous_data];
          }
          return previous_data;
        });
      }
      if (data.ticket === id && data.type === 'Complaint') {
        setComplaintConversations((prev) => {
          let previous_data = [...prev];
          let findIndex = previous_data.findIndex((item) => item._id === data._id);
          if (findIndex !== -1) {
            previous_data[findIndex] = data;
          } else {
            previous_data = [data, ...previous_data];
          }
          return previous_data;
        });
      }
    });
    socket.on('asset-delete', (data) => {
      setTicketData((prev) => {
        let prev_data = { ...prev };
        if (prev_data.asset._id === data._id) {
          prev_data = {
            ...prev_data,
            activity: data.last_activity,
            ticket_status: 'Closed',
          };
        }
        return prev_data;
      });
    });
  }, [id, user]);

  const getTicketDetails = useCallback(async () => {
    setTaskConversations([]);
    setComplaintConversations([]);
    setConversations([]);
    try {
      setLoader(true);
      const response = await getTicketDataApi(id);
      setTicketData(response.data.data);
      if (response.data.data.conversations.length > 0) {
        setTaskConversations(
          response.data.data.conversations.filter((conv) => conv.type === 'Task')
        );
        setComplaintConversations(
          response.data.data.conversations.filter((conv) => conv.type === 'Complaint')
        );
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getTicketDetails();
      socketListner();
    }
  }, [id, getTicketDetails, socketListner]);

  const checkEditAccess = useCallback(() => {
    if (ticketData) {
      const userPresent = ticketData?.ticket_users.find((u) => u?.user?._id === user?._id);
      if (userPresent && userPresent.can_edit) {
        setUserCanEdit(true);
      } else {
        setUserCanEdit(false);
      }
    }
  }, [ticketData, user]);

  useEffect(() => {
    if (ticketData) {
      setSelectedTab(
        ticketData?.ticket_status !== 'Complaint raised' ? strings.task : strings.overview
      );
      checkEditAccess();
    }
  }, [ticketData, strings.task, strings.overview, checkEditAccess]);

  useEffect(() => {
    if (selectedTab === 'Task') {
      setConversations(taskConversations);
    } else {
      setConversations(complaintConversations);
    }
  }, [selectedTab, id, taskConversations, complaintConversations]);

  const getBgClolor = (type) => {
    if (type === 'Unresolved' || type === 'Found, Unrecovered') {
      return styles.unresolvedBgViewStyles;
    } else if (type === 'Assigned') {
      return styles.assigenedBgViewStyles;
    } else if (type === 'In progress' || type === 'Recovered') {
      return styles.inProgressBgViewStyles;
    } else if (type === 'In review') {
      return styles.inReviewBgViewStyles;
    } else if (type === 'Marked lost' || type === 'Complaint raised') {
      return styles.inMarkAsLostBgViewStyles;
    } else if (type === 'In review*') {
      return styles.cannotSolveBgViewStyles;
    } else if (type === 'Closed') {
      return styles.inClosedBgViewStyles;
    } else {
      return styles.primaryBgStyle;
    }
  };

  const getTimeLineBgClolor = (type) => {
    if (type === 'Raise' || type === "Can't solve") {
      return styles.pinkBgStyle;
    } else if (type === 'Forward' || type === 'Uncheck') {
      return styles.grayBgStyle;
    } else if (type === 'Assign') {
      return styles.primaryBgStyle;
    } else if (type === 'Working' || type === 'Revert' || type === 'Check') {
      return styles.greenBgStyle;
    } else if (type === 'Completed') {
      return styles.orangeBgStyle;
    } else if (type === 'Resolved' || type === 'Force close') {
      return styles.lightGrayBgStyle;
    } else if (type === 'Remove user') {
      return styles.redBgStyle;
    } else {
      return styles.primaryBgStyle;
    }
  };

  const timeStampOfTicket = (date) => {
    var today = moment();
    var yesterday = moment().subtract(1, 'day');
    if (moment(date).isSame(today, 'd')) {
      return 'Today';
    } else if (moment(date).isSame(yesterday, 'd')) {
      return 'Yesterday';
    } else {
      return moment(date).format('MMM DD Y');
    }
  };

  const getTimeLineString = (activity) => {
    if (activity.type === 'Raise') {
      return `${strings.complaintRaisedBy} ${
        activity.action_by ? activity.action_by.full_name : activity.action_by_guest
      }`;
    } else if (activity.type === 'Forward') {
      return `${activity?.action_by.full_name} ${strings.forwardedTo} ${activity?.users[0]?.full_name}`;
    } else if (activity.type === 'Assign') {
      return `${activity?.action_by.full_name} ${strings.assignedTo} ${activity?.users[0]?.full_name}`;
    } else if (activity.type === 'Working') {
      return `${activity?.action_by.full_name} ${strings.ticketStatusMsg.replace(
        '{status}',
        activity.type
      )}`;
    } else if (activity.type === 'Revert') {
      return `${activity?.action_by.full_name} ${strings.ticketStatusMsg.replace(
        '{status}',
        'Working'
      )}`;
    } else if (activity.type === 'Completed') {
      return `${activity?.action_by.full_name} ${strings.ticketStatusMsg.replace(
        '{status}',
        activity.type
      )}`;
    } else if (activity.type === 'Resolved') {
      return `${activity?.action_by.full_name} ${strings.ticketStatusWhenClosed}`;
    } else if (activity.type === "Can't solve") {
      return `${activity?.action_by.full_name} ${strings.ticketStatusMsg.replace(
        '{status}',
        activity.type
      )}`;
    } else if (activity.type === 'Check') {
      return `${activity?.note} - checked by ${activity?.action_by.full_name}`;
    } else if (activity.type === 'Uncheck') {
      return `${activity?.note} - Unchecked by ${activity?.action_by.full_name}`;
    } else if (activity.type === 'Change access') {
      return `${activity?.action_by.full_name} modified the access of ${activity?.users[0].full_name}`;
    } else if (activity.type === 'Invite') {
      return `${activity?.action_by.full_name} added ${activity?.users[0].full_name}`;
    } else if (activity.type === 'Force close') {
      return `Ticket automatically closed when asset deleted by ${activity?.action_by.full_name}`;
    } else if (activity.type === 'Remove user') {
      return `${activity?.users[0].full_name} removed by ${activity?.action_by.full_name}`;
    } else {
      return '';
    }
  };

  const handlePostComment = async () => {
    if (message.trim().length > 0) {
      try {
        let data = {
          type: selectedTab === 'Task' ? 'Task' : 'Complaint',
          ticket: id,
          comment: message,
          thread_id: selectedThread,
        };
        let res = await sendTicketMessageApi(data);
        if (res.data.data.type === 'Task') {
          setTaskConversations((prev) => {
            let previous_data = [...prev];
            let findIndex = previous_data.findIndex((item) => item._id === res.data.data._id);
            if (findIndex !== -1) {
              previous_data[findIndex] = res.data.data;
            } else {
              previous_data = [res.data.data, ...previous_data];
            }
            return previous_data;
          });
        } else {
          setComplaintConversations((prev) => {
            let previous_data = [...prev];
            let findIndex = previous_data.findIndex((item) => item._id === res.data.data._id);
            if (findIndex !== -1) {
              previous_data[findIndex] = res.data.data;
            } else {
              previous_data = [res.data.data, ...previous_data];
            }
            return previous_data;
          });
        }
        setMessage('');
      } catch (error) {}
    }
  };

  const toggleCheckList = (id) => {
    if (showCheckList.includes(id)) {
      setShowCheckList((prev) => prev.filter((a) => a !== id));
    } else {
      setShowCheckList((prev) => [...prev, id]);
    }
    setShowCheckListMenu([]);
  };

  const updateTaskInnerState = async (task, taskItem, checked) => {
    try {
      setCheckListLoader(true);
      if (task && taskItem && userCanEdit) {
        let data = {
          ticket: ticketData._id,
          task: task._id,
          inner_task: taskItem._id,
          checked,
        };
        let response = await updateTaskItemStateApi(data);
        let tasksData = ticketData.tasks;
        let taskIndex = tasksData.findIndex((item) => item._id === task._id);
        if (taskIndex !== -1) {
          tasksData[taskIndex] = response.data.data.task;
          setTicketData({
            ...ticketData,
            ticket_status: 'In progress',
            tasks: tasksData,
            activity: response.data.data.activity,
          });
          setTickets((prev) => {
            let previous_data = [...prev];
            let ticketIndex = previous_data.findIndex(
              (item) => item._id === response.data.data.task.ticket
            );
            if (ticketIndex !== -1) {
              previous_data[ticketIndex].ticket_status = 'In progress';
              return previous_data;
            }
          });
        }
        toast.success(response.data.message);
        setCheckListLoader(false);
      }
    } catch (error) {
      setCheckListLoader(false);
      toast.error(error.message);
    }
  };

  // ui section ------

  const renderActivitiesContainer = () => {
    return (
      <div className={styles.activityContainerStyle}>
        <div className={styles.activitySubContainerStyle}>
          {renderTabs()}
          {selectedTab === strings.task && renderTask()}
          {selectedTab === strings.overview && renderOverViewAndInfoSection()}
          {selectedTab === strings.timeline && renderTimeLine()}
          {selectedTab !== strings.timeline && renderComments()}
        </div>
      </div>
    );
  };

  const renderTabs = () => {
    return (
      <div className={styles.tabsWrapperStyle}>
        {ticketTabs
          .filter((tab) => {
            return ticketData?.ticket_status !== 'Complaint raised'
              ? tab.title !== strings.overview
              : tab.title === strings.overview || tab.title === strings.timeline;
          })
          .map((item, index) => (
            <Capsule
              key={index}
              label={item.title}
              onClick={() => setSelectedTab(item.value)}
              containerStyle={selectedTab === item.value ? styles.selectedCapsuleSyle : null}
            />
          ))}
      </div>
    );
  };

  const renderTask = () => {
    const assignedData = ticketData?.activity?.filter((a) => a.type === 'Assign')[0];
    const otherData = ticketData?.activity
      ?.filter(
        (a) =>
          a.type === 'Completed' ||
          a.type === 'Resolved' ||
          a.type === 'Revert' ||
          a.type === "Can't solve" ||
          a.type === 'Closed'
      )
      .reverse();
    const bgColorStyle = getBgClolor(ticketData?.ticket_status);
    return (
      assignedData && (
        <div
          className={classNames(
            styles.taskProgressContainerStyle,
            otherData.length && styles.taskProgressContainerReverseStyle
          )}
        >
          <div className={styles.raiseTicketDetailsBlockStyles}>
            <div className={styles.ticketRaiseByViewStyles}>
              <Image
                src={flagFillWhiteIcon}
                alt={'ticketIcon'}
                containerStyle={classNames(styles.ticketRaiseIconViewStyle, bgColorStyle)}
              />
              <div className={styles.ticketRaiseByUserStyles}>
                <p
                  className={styles.ticketRaiseTextStyles}
                >{`${assignedData.action_by.full_name} ${strings.assignedThisTask}`}</p>
                <p className={styles.ticketRaiseDateTextStyles}>
                  {`${moment(assignedData?.created_at).format('hh:mm a')}, ${timeStampOfTicket(
                    assignedData?.created_at
                  )}`}
                </p>
              </div>
            </div>
            <div className={styles.tickeViewStyles}>
              <div className={styles.curvedViewStyle}></div>
              <TicketOverViewCard ticketData={ticketData} />
            </div>
          </div>
          {ticketData?.tasks?.length > 0 && (
            <div className={styles.taskChecklistContainerStyle}>
              {checkListLoader && <Loader />}
              {ticketData?.tasks.map((task, index) => (
                <div key={'task' + index} className={styles.taskChecklistWrapperStyle}>
                  <div className={styles.taskChecklistHeaderWrapperStyle}>
                    <div className={styles.taskChecklistTitleInfoWrapperStyle}>
                      <Image
                        src={checkBoxFillWhiteIcon}
                        containerStyle={classNames(
                          styles.activityTypeImgContainerStyle,
                          bgColorStyle
                        )}
                      />
                      <div className={styles.taskChecklistTitleWrapperStyle}>
                        <p className={styles.taskChecklistTitleStyle}>{strings.checklist}</p>
                        <span className={styles.taskChecklistSubTitleStyle}>{`${
                          (task.items.filter((item) => item.checked).length / task.items.length) *
                          100
                        }% ${strings.complete} ${
                          task.items.filter((item) => item.checked).length
                        } of ${task.items.length} ${strings.checked}`}</span>
                      </div>
                    </div>
                    {ticketData?.ticket_status === 'In progress' ||
                    ticketData?.ticket_status === 'Assigned' ? (
                      <OptionsMenu
                        show={showCheckListMenu?.includes(task._id)}
                        onClick={() => setShowCheckListMenu((prev) => [...prev, task._id])}
                        onClose={() =>
                          setShowCheckListMenu((prev) => prev?.filter((a) => a !== task._id))
                        }
                        options={
                          showCheckList?.includes(task._id)
                            ? strings.unhidecCecklist
                            : strings.hideChecklist
                        }
                        onClickOption={() => toggleCheckList(task._id)}
                      ></OptionsMenu>
                    ) : (
                      <Image
                        src={chevronDownBlackIcon}
                        containerStyle={classNames(
                          styles.taskToggleImgContainerStyle,
                          showCheckList.includes(task._id) && styles.taskToggleImgRevContainerStyle
                        )}
                        onClick={() => toggleCheckList(task._id)}
                      />
                    )}
                  </div>
                  {task.items.length > 0 && !showCheckList?.includes(task._id) && (
                    <div key={'taskItem' + index} className={styles.tickeViewStyles}>
                      <div className={styles.curvedViewStyle}></div>
                      <div className={styles.taskCLIContentWrapperStyle}>
                        <div className={styles.infoViewStyle}>
                          <Info
                            leftlable={'Checklist Title :'}
                            leftValue={task?.title}
                            customLableStyle={styles.customLabelStyle}
                          />
                        </div>
                        {task.items.map((taskItem, index) => (
                          <div key={index} className={styles.taskItemWrapperStyle}>
                            <input
                              type="checkbox"
                              className={classNames(
                                styles.customCheckBoxStyle,
                                taskItem.checked && bgColorStyle
                              )}
                              disabled={!userCanEdit || ticketData.ticket_status === 'In review'}
                              checked={taskItem.checked}
                              onChange={(e) =>
                                updateTaskInnerState(task, taskItem, e.target.checked)
                              }
                            />
                            <div className={styles.taskItemTitleWrapperStyle}>
                              <p
                                className={classNames(
                                  styles.taskItemTitleStyle,
                                  taskItem.checked && styles.taskItemTitleStrikeStyle
                                )}
                              >
                                {taskItem.item_title}
                              </p>
                              {taskItem.activity.length > 0 && (
                                <div className={styles.taskItemActivityWrapperStyle}>
                                  <span className={styles.taskItemSubTitleStyle}>
                                    {`${
                                      taskItem.activity[0].type === 'Check'
                                        ? strings.checked
                                        : strings.unchecked
                                    } ${strings.by} ${taskItem.activity[0].by.full_name}`}
                                  </span>
                                  {taskItem.activity.length > 1 && (
                                    <Image
                                      src={optionsHorizentGrayIcon}
                                      containerStyle={styles.taskItemActionContainerStyle}
                                      onClick={(e) => {
                                        setTaskActivityModal(taskItem);
                                        setCheckListTimeLIneRef(e.target);
                                      }}
                                      // reference={setCheckListTimeLIneRef}
                                    >
                                      <PopOver
                                        show={taskItem._id === taskActivityModal?._id}
                                        onClose={() => setTaskActivityModal(null)}
                                        reference={checkListTimeLIneRef}
                                        showOverlay
                                        containerStyle={styles.taskItemActivityModalStyle}
                                      >
                                        <div className={styles.timeLineContainerStyle}>
                                          {taskItem.activity.map((activity, index) => (
                                            <div
                                              key={'act' + index}
                                              className={styles.timeLineItemWrapperStyle}
                                            >
                                              <Image
                                                src={
                                                  activity.type === 'Check'
                                                    ? checkBoxFillWhiteIcon
                                                    : unCheckWhiteIcon
                                                }
                                                containerStyle={[
                                                  styles.timeLineImgContainerStyle,
                                                  activity.type === 'Check' && bgColorStyle,
                                                ].join(' ')}
                                                imgStyle={styles.timeLineImgStyle}
                                              />
                                              <div className={styles.timeLineContentWrapperStyle}>
                                                <p className={styles.timeLineTitleStyle}>
                                                  {`${
                                                    activity.type === 'Check'
                                                      ? strings.checked
                                                      : strings.unchecked
                                                  } ${strings.by} ${activity.by.full_name}`}
                                                </p>
                                                <span className={styles.timeLineSubTitleStyle}>
                                                  {`${moment(activity.at).format(
                                                    'hh:mm a'
                                                  )}, ${moment(activity.at).fromNow()}`}
                                                </span>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </PopOver>
                                    </Image>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {otherData.map((activity, index) => (
            <div key={index} className={styles.signleActivityContainerStyle}>
              {(activity.type === 'Completed' || activity.type === "Can't solve") && (
                <React.Fragment>
                  <div className={styles.ticketRaiseByViewStyles}>
                    {/* <div className={classNames(styles.ticketRaiseIconViewStyle, bgColorStyle)}> */}
                    <Image
                      src={timerWhite}
                      alt={'Complete'}
                      containerStyle={classNames(styles.ticketRaiseIconViewStyle, bgColorStyle)}
                    />
                    {/* </div> */}
                    <div className={styles.ticketRaiseByUserStyles}>
                      <p className={styles.ticketRaiseTextStyles}>{`${
                        activity.action_by.full_name
                      } ${strings.changeTaskStatusTo} ${
                        activity.type === 'Completed' ? 'Complete' : "Can't solve"
                      }`}</p>
                      <p className={styles.ticketRaiseDateTextStyles}>
                        {`${moment(assignedData?.created_at).format(
                          'hh:mm a'
                        )}, ${timeStampOfTicket(assignedData?.created_at)}`}
                      </p>
                    </div>
                  </div>
                  <div className={styles.tickeViewStyles}>
                    <div className={styles.curvedViewStyle} />
                    <div className={styles.pcsContentWrapperStyle}>
                      <div className={styles.taskAssetStyles}>
                        <span className={styles.taskAssetTitleStyles}>
                          {`#${ticketData?.asset?.asset_uid}: ${ticketData?.asset?.asset_name}`}
                        </span>
                        <div className={styles.taskAssetImgWrapperStyles}>
                          <Image
                            src={activity.image ? activity.image : ticketData?.asset?.image}
                            alt={ticketData?.asset?.asset_name}
                            imgStyle={styles.taskAssetImgStyles}
                          />
                        </div>
                      </div>
                      {activity?.note && (
                        <div className={styles.infoViewStyle}>
                          {activity?.note && (
                            <Info
                              leftlable={'Note :'}
                              leftValue={activity?.note}
                              customLableStyle={styles.customLabelStyle}
                            />
                          )}
                        </div>
                      )}
                      {/* 
                      // Todo temporarily commented for future use
                      <div className={styles.noteBorderWrapperStyle}>
                        <div className={styles.noteBorderStyle} />
                        <span className={styles.pciNoteStyle}>{activity?.note}</span>
                      </div> */}
                    </div>
                  </div>
                </React.Fragment>
              )}
              {activity.type === 'Resolved' && (
                <div className={styles.ticketRaiseByViewStyles}>
                  <div className={classNames(styles.ticketRaiseIconViewStyle, bgColorStyle)}>
                    <Image src={raceIcon} alt={'Closed'} imgStyle={styles.imageStyle} />
                  </div>
                  <div className={styles.ticketRaiseByUserStyles}>
                    <p className={styles.ticketRaiseTextStyles}>
                      {activity.action_by.full_name}&nbsp;{strings.ticketStatusWhenClosed}
                    </p>
                    <p className={styles.ticketRaiseDateTextStyles}>
                      {`${moment(activity?.created_at).format('hh:mm a')}, ${timeStampOfTicket(
                        activity?.created_at
                      )}`}
                    </p>
                  </div>
                </div>
              )}
              {activity.type === 'Revert' && (
                <React.Fragment>
                  <div className={styles.ticketRaiseByViewStyles}>
                    <div className={classNames(styles.ticketRaiseIconViewStyle, bgColorStyle)}>
                      <Image src={flagFillWhiteIcon} alt={'Revert'} imgStyle={styles.imageStyle} />
                    </div>
                    <div className={styles.ticketRaiseByUserStyles}>
                      <p className={styles.ticketRaiseTextStyles}>
                        {activity.action_by.full_name}&nbsp;{strings.changeTaskStatusToWorking}
                      </p>
                      <p className={styles.ticketRaiseDateTextStyles}>
                        {`${moment(assignedData?.created_at).format(
                          'hh:mm a'
                        )}, ${timeStampOfTicket(assignedData?.created_at)}`}
                      </p>
                    </div>
                  </div>
                  {activity?.note && (
                    <div className={styles.tickeViewStyles}>
                      <div className={styles.curvedViewStyle} />
                      <div className={styles.pcsContentWrapperStyle}>
                        <div className={styles.infoViewStyle}>
                          <Info
                            leftlable={'Note :'}
                            leftValue={activity?.note}
                            customLableStyle={styles.customLabelStyle}
                          />
                        </div>
                        {/* 
                        // Todo temporarily commented for future use
                        <div className={styles.noteBorderWrapperStyle}>
                          <div className={styles.noteBorderStyle} />
                          <span className={styles.pciNoteStyle}>{activity?.note}</span>
                        </div> */}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          ))}
        </div>
      )
    );
  };

  const renderOverViewAndInfoSection = () => {
    return (
      <div className={styles.overViewWrapperStyle}>
        <div className={styles.activityWrapperStyle}>
          <div
            className={classNames(styles.flagIconViewStyle, getBgClolor(ticketData?.ticket_status))}
          >
            <Image src={flagFillWhiteIcon} alt="flag" containerStyle={styles.flagStyle} />
          </div>
          <div className={styles.activityDetailsViewStyle}>
            <p className={styles.titleTextStyle}>
              {strings.complaintRaisedBy}&nbsp;
              {ticketData?.created_by ? ticketData?.created_by.full_name : 'Guest user'}
            </p>
            <p className={styles.descTextStyle}>
              {`${moment(ticketData?.created_at).format('hh:mm a')}, ${timeStampOfTicket(
                ticketData?.created_at
              )}`}
            </p>
          </div>
        </div>
        <div className={styles.ticketWrapperStyle}>
          <div className={styles.curvedViewStyle}></div>
          <TicketOverViewCard
            ticketData={ticketData}
            takeAction={() => setIsShowTakeActionModal(true)}
            forwardAction={() => setIsShowForwardModal(true)}
          />
        </div>
      </div>
    );
  };

  const renderForwardToSection = () => {
    return (
      <Modal
        showOverlay={true}
        show={isShowForwardModal}
        containerStyle={styles.modalViewStyle}
        handleClickOutSide={() => setIsShowForwardModal(false)}
      >
        <TicketForward
          ticket={ticketData}
          setTicket={setTicketData}
          onClose={setIsShowForwardModal}
        />
      </Modal>
    );
  };

  const renderTakeActionSection = () => {
    return (
      <Modal
        showOverlay={true}
        show={isShowTakeActionModal}
        containerStyle={styles.takeActionModalViewStyle}
        handleClickOutSide={() => setIsShowTakeActionModal(false)}
      >
        <TicketAssign
          ticket={JSON.parse(JSON.stringify(ticketData))}
          setTicket={setTicketData}
          onClose={(val) => setIsShowTakeActionModal(val)}
        />
      </Modal>
    );
  };

  const renderTimeLine = () => {
    return (
      <div className={styles.timeLineContainerStyle}>
        {ticketData?.activity.map((activity, index) => (
          <div key={'act' + index} className={styles.timeLineItemWrapperStyle}>
            <Image
              src={timeLineIcons[activity.type]}
              containerStyle={[
                styles.timeLineImgContainerStyle,
                getTimeLineBgClolor(activity.type),
              ].join(' ')}
              imgStyle={styles.timeLineImgStyle}
            />
            <div className={styles.timeLineContentWrapperStyle}>
              <p className={styles.timeLineTitleStyle}>{getTimeLineString(activity)}</p>
              <span className={styles.timeLineSubTitleStyle}>
                {` ${moment(activity.created_at).format('hh:mm a')}, ${moment(
                  activity.created_at
                ).fromNow()}`}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderComments = () => {
    return (
      <div className={styles.commentsBlcokStyle}>
        <div
          className={styles.blockTitleWrapperStyle}
          onClick={() => setShowComments(!showComments)}
        >
          <p className={styles.titleStyle}>Conversations</p>
        </div>
        <div className={styles.commentsContentSectionStyle}>
          <ChatInput
            setValue={(text) => {
              setMessage(text);
              setSelectedThread('');
            }}
            onInputFocus={() => setSelectedThread('')}
            value={selectedThread === '' ? message : ''}
            sendAction={() => handlePostComment()}
            disabled={ticketData?.ticket_status === 'Closed'}
          />
          <div className={styles.commentsListStyle}>
            {conversations.length > 0 &&
              conversations.slice(0, conversationsLimit).map((conv, index) => (
                <React.Fragment key={'conv' + index}>
                  <div className={styles.commentsListItemStyle}>
                    <div className={styles.commentsListItemProfileWrapperStyle}>
                      <Avatar
                        src={conv.user?.image}
                        label={conv?.user?.full_name}
                        containerStyle={styles.commentAvatarStyle}
                      />
                      <p className={styles.commentProfileTitleStyle}>{conv?.user?.full_name}</p>
                      <p className={styles.commentTimeStampStyle}>
                        {`• ${moment(conv.created_at).fromNow()}`}
                      </p>
                    </div>
                    <p className={styles.commentTextStyle}>{conv.comment}</p>
                    <div className={styles.replyListWrapperStyle}>
                      <div
                        onClick={() => {
                          setMessage('');
                          setSelectedThread((prev) => (prev === conv._id ? '' : conv._id));
                          setTimeout(() => {
                            document.getElementById(conv._id)?.scrollIntoView({ block: 'center' });
                          }, 100);
                        }}
                        className={styles.replyWrapperStyle}
                      >
                        <p className={styles.replyLinkStyle}>
                          {conv?.replies.length > 0
                            ? `${conv.replies.length} ${
                                conv.replies.length === 1 ? 'reply' : 'replies'
                              }`
                            : 'Reply'}
                        </p>
                        {conv?.replies.length > 0 && (
                          <Image
                            src={chevronDownFillBlueIcon}
                            alt="avatar"
                            containerStyle={classNames(
                              selectedThread === conv._id && styles.rotateIcon180Style
                            )}
                          />
                        )}
                      </div>
                      {selectedThread === conv._id && (
                        <React.Fragment>
                          {conv?.replies.length > 0 &&
                            conv.replies.map((thread, index) => (
                              <div key={'thread' + index} className={styles.replyItemStyle}>
                                <div className={styles.replyCurveStyle} />
                                <div className={styles.commentsListItemStyle}>
                                  <div className={styles.commentsListItemProfileWrapperStyle}>
                                    <Avatar
                                      src={thread?.user?.image}
                                      label={thread?.user?.full_name}
                                      containerStyle={styles.commentAvatarStyle}
                                    />
                                    <p className={styles.commentProfileTitleStyle}>
                                      {thread?.user?.full_name}
                                    </p>
                                    <p className={styles.commentTimeStampStyle}>
                                      {`• ${moment(thread.created_at).fromNow()}`}
                                    </p>
                                  </div>
                                  <p className={styles.commentTextStyle}>{thread.comment}</p>
                                </div>
                              </div>
                            ))}
                          <ChatInput
                            id={conv._id}
                            value={selectedThread === conv._id ? message : ''}
                            setValue={(text) => setMessage(text)}
                            sendAction={() => handlePostComment()}
                            disabled={ticketData?.ticket_status === 'Closed'}
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  {conversations.length !== index + 1 && <div className={styles.dividerStyle} />}
                </React.Fragment>
              ))}
            {conversations.length > conversationsLimit && (
              <span
                className={styles.moreCommentsLinkStyle}
                onClick={() => setConversationsLimit((prev) => prev + 3)}
              >
                Click to show more
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderUserRemovedAlertModal = () => {
    return (
      <Modal
        showOverlay={true}
        show={showUserRomovedPopup}
        containerStyle={styles.alertDeleteModalViewStyle}
      >
        <SuccessCard
          title={'Removed!'}
          description={`You are removed from this ticket #${ticketData?.ticket_uid}, please click on Continue to proceed`}
          titleStyle={styles.assetDeletedAlertTitleStyle}
          leftBtnTitle={strings.continue}
          leftAction={() => {
            setShowUserRomovedPopup(false);
            setTicketData(null);
            navigate(-1);
            setSearchParams(searchParams.delete('selected'));
          }}
        />
      </Modal>
    );
  };

  return (
    <div className={styles.containerStyle}>
      {loader ? (
        <Loader />
      ) : ticketData ? (
        <React.Fragment>
          <TicketHeader
            ticket={ticketData}
            setTicket={setTicketData}
            forwardAction={() => setIsShowForwardModal(true)}
            revertTicketAction={() => setIsShowTakeActionModal(true)}
          />
          {renderActivitiesContainer()}
          {renderForwardToSection()}
          {renderTakeActionSection()}
          {renderUserRemovedAlertModal()}
        </React.Fragment>
      ) : (
        <div className={styles.emptyViewStyle}>
          <p className={styles.emptyViewTextStyle}>
            Ticket data not found (or) You don't have access
          </p>
        </div>
      )}
    </div>
  );
};

TicketDetails.propTypes = {
  ticketData: PropTypes.object,
};

export default TicketDetails;

export const OptionsMenu = (props) => {
  const { onClick = () => {}, show, onClose = () => {}, onClickOption = () => {}, options } = props;
  const [checkListRef, setCheckListRef] = useState(null);
  return (
    <Image
      src={optionsHorizentBlackIcon}
      containerStyle={styles.activityActionImgContainerStyle}
      reference={setCheckListRef}
      onClick={onClick}
    >
      <PopOver
        show={show}
        showOverlay
        containerStyle={styles.taskChecklistModalStyle}
        reference={checkListRef}
        onClose={onClose}
      >
        <div className={styles.menuWrapperStyle}>
          <p className={styles.menuItemStyle} onClick={onClickOption}>
            {options}
          </p>
        </div>
      </PopOver>
    </Image>
  );
};
