import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { closeLightGrayIcon } from 'resources/images';
import Image from 'components/common/image';
import styles from './styles.module.css';

const SelectedCard = (props) => {
  const { title, removeItem, containerStyle, icon, iconStyle, devider = false } = props;
  return (
    <div className={classNames(styles.containerStyle, containerStyle)}>
      <p className={styles.titleTextStyle}>{title}</p>
      {devider && <div className={styles.dividerStyle}></div>}
      <div className={styles.iconViewStyle} onClick={() => removeItem()}>
        <Image
          src={icon ? icon : closeLightGrayIcon}
          alt="closeGrayIcon"
          containerStyle={classNames(styles.iconStyle, iconStyle)}
        />
      </div>
    </div>
  );
};

SelectedCard.propTypes = {
  title: PropTypes.string,
  removeItem: PropTypes.func,
  containerStyle: PropTypes.string,
  icon: PropTypes.string,
  iconStyles: PropTypes.string,
  devider: PropTypes.bool,
};

export default SelectedCard;
