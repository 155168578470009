import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const BasicSelect = (props) => {
  const {
    label,
    selectedOption,
    options,
    customContainerStyle,
    setSelectedOptions,
    hasError,
    disabled = false,
  } = props;

  return (
    <div className={styles.selectWrapperStyle}>
      <p className={styles.labelTextStyle}>{label}</p>
      <Select
        options={options}
        onChange={(e) => setSelectedOptions(e)}
        value={selectedOption}
        isDisabled={disabled}
        className={classNames(styles.containerStyle, customContainerStyle)}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: '0 !important',
            boxShadow: '0 !important',
            '&:hover': {
              border: '0 !important',
            },
            outline: 'none',
            borderRadius: '8px',
            padding: '3px 3px',
          }),
          option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused
              ? 'rgba(233, 246, 229, 1);'
              : isSelected
              ? 'rgba(229, 236, 246, 1);'
              : undefined,
            color: '#1e1e20',
            zIndex: 1,
          }),
        }}
      />
      <p className={styles.errorTextStyle}>{hasError}</p>
    </div>
  );
};

BasicSelect.propTypes = {
  label: PropTypes.string,
  selectedOption: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  containerStyle: PropTypes.string,
};

export default BasicSelect;
