import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Avatar from 'components/common/avatar';
import styles from './styles.module.css';

const AvatarGroup = (props) => {
  const {
    reference,
    data = [],
    onClick = () => {},
    containerStyle,
    imgContainerStyle,
    imgStyle,
    maxItems = 1,
  } = props;

  if (data.length === 0) {
    return null;
  }

  return (
    <div
      onClick={onClick}
      className={classNames(styles.containerStyle, containerStyle)}
      ref={reference}
    >
      {data.slice(0, maxItems).map((item, index) => (
        <Avatar
          key={'avatar' + index}
          src={item.src}
          label={item?.label}
          alt={item.alt}
          containerStyle={classNames(styles.imgContainerStyle, imgContainerStyle)}
          imgStyle={imgStyle}
        />
      ))}
      {data.length - maxItems > 0 && (
        <div className={classNames(styles.imgContainerStyle, imgContainerStyle)}>{`+${
          data.length - maxItems
        }`}</div>
      )}
    </div>
  );
};

AvatarGroup.propTypes = {
  reference: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      src: PropTypes.string,
    })
  ),
  onClick: PropTypes.func,
  containerStyle: PropTypes.string,
  imgContainerStyle: PropTypes.string,
  imgStyle: PropTypes.string,
  maxItems: PropTypes.number,
};
export default AvatarGroup;
