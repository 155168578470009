import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { checkCircleFillPurpleIcon, uncheckPurpleIcon } from 'resources/images';
import Image from 'components/common/image';
import styles from './styles.module.css';

const ReminderCard = (props) => {
  const { data, onClick = () => {}, status, customContainerStyle } = props;

  return (
    <div
      className={classNames(styles.remainderItemWrapperStyle, customContainerStyle)}
      onClick={onClick}
    >
      <div className={styles.remainderItemLeftWrapperStyle}>
        <Image
          containerStyle={styles.iconCheckContainerStyle}
          src={data?.status?.length > 0 ? checkCircleFillPurpleIcon : uncheckPurpleIcon}
          alt="icon"
        />
        <div className={styles.remainderTextWrapperStyle}>
          <p className={styles.remainderTitleTextStyle}>{data?.title}</p>
          <p className={styles.remainderTimeTextStyle}>
            <span>{status ? 'Ongoing' : data.status.length > 0 ? 'Completed' : 'Upcoming'}</span> •{' '}
            {moment(data?.start_date).format('DD/MM/YYYY')} •{' '}
            {moment(data?.start_date).format('LT')}
            {data?.repeat_interval?.value
              ? ` • ${data?.repeat_interval?.value}  ${data?.repeat_interval?.type}`
              : ''}{' '}
            .
          </p>
        </div>
      </div>
      {/*
      // Todo No functionality available for this
       <Image
        containerStyle={classNames(styles.iconOptionsContainerStyle, customRightIconStyle)}
        src={rightIcon}
        alt="icon"
        onClick={onAction}
      /> */}
    </div>
  );
};

ReminderCard.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.object,
  customRightIconStyle: PropTypes.string,
  rightIcon: PropTypes.string,
  onAction: PropTypes.func,
  customContainerStyle: PropTypes.string,
  status: PropTypes.bool,
};

export default ReminderCard;
