import moment from 'moment';
import {
  checkWhiteIcon,
  closeWhiteIcon,
  downloadIcon,
  flagFillWhiteIcon,
  forwardIcon,
  forwardRightWhiteIcon,
  infoCircleIcon,
  pencilEditBlackIcon,
  pencilEditWhiteIcon,
  plusCircleBlackIcon,
  plusCircleWhite,
  raceIcon,
  timerWhite,
} from 'resources/images';

export const account_types = {
  super_admin: 'Super Admin',
  admin: 'Admin',
  employee: 'Employee',
};
export const timeLineIcons = {
  Raise: flagFillWhiteIcon,
  Forward: forwardRightWhiteIcon,
  Assign: flagFillWhiteIcon,
  Working: flagFillWhiteIcon,
  Completed: timerWhite,
  Revert: flagFillWhiteIcon,
  "Can't solve": flagFillWhiteIcon,
  'Force close': flagFillWhiteIcon,
  Resolved: raceIcon,
  Closed: raceIcon,
  Check: checkWhiteIcon,
  Uncheck: closeWhiteIcon,
  'Change access': pencilEditWhiteIcon,
  Invite: plusCircleWhite,
  'Remove user': closeWhiteIcon,
};
export const ticketMenuOptions = [
  // Todo temporarily commented for future use
  // {
  // Todo temporarily commented for future use
  //   title: 'Unmute alerts',
  //   icon: audioIcon,
  // },
  {
    title: 'Forward complaint',
    icon: forwardIcon,
  },
  // Todo temporarily commented for future use
  // {
  //   title: 'Archive complaint',
  //   icon: archiveBlackIcon,
  // },
];
export const asset_options = {
  raise_a_ticket: 'Raise a ticket',
  maintenance: 'Maintenance',
  asset_details: 'Asset details',
};
export const chat_options = {
  asset_details: 'Asset details',
  mark_as_returned: 'Mark as returned',
  mark_as_recovered: 'Mark as recovered',
  report_chat: 'Report chat',
};
export const clusterMenuOptions = [
  {
    title: 'Cluster info',
    icon: infoCircleIcon,
  },
  {
    title: 'Edit cluster',
    icon: pencilEditBlackIcon,
  },
  {
    title: 'Add members',
    icon: plusCircleBlackIcon,
  },
  {
    title: 'Archive cluster',
    icon: downloadIcon,
  },
];
export const reminderActionTypes = {
  add: 'add',
  edit: 'edit',
};

export const weekDays = [
  {
    label: 'Su',
    value: 'Sun',
  },
  {
    label: 'Mo',
    value: 'Mon',
  },
  {
    label: 'Tu',
    value: 'Tue',
  },
  {
    label: 'We',
    value: 'Wed',
  },
  {
    label: 'Th',
    value: 'Thu',
  },
  {
    label: 'Fr',
    value: 'Fri',
  },
  {
    label: 'Sa',
    value: 'Sat',
  },
];

export const recurrencTypes = ['Days', 'Weeks', 'Months', 'Years'];

export const maintanenceFrequencyOptions = [
  {
    title: "Doesn't repeat",
    value: "Doesn't repeat",
    desc: '',
  },
  {
    title: 'Daily',
    value: 'Daily',
    desc: 'Repeats every day until end date, each maintenance cycle closes after every 24 hours',
  },
  {
    title: 'Weekly',
    value: 'Weekly',
    desc: 'Repeats every week until end date, each maintenance cycle closes after every 7 days',
  },
  {
    title: 'Monthly',
    value: 'Monthly',
    desc: 'Repeats every month until end date, each maintenance cycle closes after every 30 days',
  },
  {
    title: 'Every weekday (Monday to Friday)',
    value: 'Weekday',
    desc: 'Repeats every weekday until end date, each maintenance cycle closes after every 24 hours',
  },
  {
    title: 'Custom',
    value: 'Custom',
    desc: '',
  },
];

export const reportsSortOptions = [
  {
    title: 'All reports',
    value: 'All reports',
  },
  {
    title: 'Last 7 days',
    value: 'Last 7 days',
  },
  {
    title: 'Last 15 days',
    value: 'Last 15 days',
  },
  {
    title: `${moment().format('MMMM YYYY')} (This month)`,
    value: 'This month',
  },
  {
    title: `${moment().subtract(1, 'month').format('MMMM')}`,
    value: 'last month',
  },
  {
    title: `${moment().subtract(2, 'month').format('MMMM')}`,
    value: 'before last month',
  },
];

export const ticketCommonOptions = [
  {
    title: 'Assigned',
    desc: 'Indicates that admin has assigned the task & assignees are yet to change the status',
    status: 'Assign',
    userTypes: [account_types.super_admin, account_types.admin, account_types.employee],
  },
  {
    title: 'Working (In-progress)',
    desc: 'As an assignee, if you started working on the task, update the status to working',
    status: 'Working',
    userTypes: [account_types.super_admin, account_types.admin, account_types.employee],
  },
  {
    title: 'Task completed (In-review)',
    desc: 'Assignees should update to this if and only when the assigned task is completed',
    status: 'Completed',
    userTypes: [account_types.super_admin, account_types.admin, account_types.employee],
  },
  {
    title: 'Can’t solve (In-review)',
    desc: 'For some reason if the assigned task cannot be solved, assignees should update the status to this',
    status: "Can't solve",
    userTypes: [account_types.super_admin, account_types.admin, account_types.employee],
  },
];

export const ticketAdminOptions = [
  {
    title: 'Resolved (Close ticket)',
    desc: 'Choose this if the ticket is resolved. This action will also close the ticket permanently',
    status: 'Resolved',
    userTypes: [account_types.super_admin, account_types.admin],
  },
  {
    title: 'Revert to working',
    desc: 'If the completed task is not up to the mark, choose this to push it back to working',
    status: 'Revert',
    userTypes: [account_types.super_admin, account_types.admin],
  },
];
