import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { debounce } from 'lodash';
import { useAppData } from 'providers/appdataprovider';
import { useStrings } from 'providers/stringsprovider';
import { useAuth } from 'providers/authprovider';
import { useUserData } from 'providers/userdataprovider';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { searchIcon } from 'resources/images';
import routePaths from 'routes/paths';
import Input from 'components/common/input';
import Avatar from 'components/common/avatar';
import AssetAndUserCard from 'components/asset&user-card';
import Loader from 'components/common/loader';
import { getAllEmployeesWithLastAssetApi } from 'networking/api/employee';
import InfiniteScroll from 'components/infinite-scroll';
import styles from './styles.module.css';

const EmployeeList = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { width } = useWindowDimensions();
  const { users, setUsers } = useUserData();
  const { user, company } = useAuth();
  const { setBreadcrumbs, setShowHeader } = useAppData();
  const { strings } = useStrings();
  const [query, setQuery] = useState(searchParams.get('query') || '');
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [debouncedQuery, setDebouncedQuery] = useState(searchParams.get('query') || '');
  const [searchLoader, setSearchLoader] = useState(false);

  useEffect(() => {
    searchParams.get('query') && setQuery(searchParams.get('query'));
  }, [searchParams]);

  useEffect(() => {
    setFilteredUsers(users);
    setBreadcrumbs([{ title: strings.allEmployees, route: '' }]);
  }, [users, setBreadcrumbs, strings.allEmployees]);

  useEffect(() => {
    if (width <= 480) {
      setShowHeader(true);
    }
    setSearchLoader(true);
  }, [width, setShowHeader]);

  const debouncedSearch = useMemo(
    () =>
      debounce((searchValue) => {
        setDebouncedQuery(searchValue);
        setCurrentPage(1);
      }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(query);
  }, [query, debouncedSearch]);

  const getUsers = useCallback(async () => {
    try {
      if (currentPage === 1) {
        setUsers([]);
      }
      setLoading(true);
      const response = await getAllEmployeesWithLastAssetApi(
        company?._id,
        currentPage,
        debouncedQuery,
        10
      );
      setUsers((prev) => {
        if (currentPage === 1) {
          return response.data.data.users;
        } else {
          return [...prev, ...response.data.data.users];
        }
      });
      setHasMore(response.data.data?.meta?.hasNextPage);
      setSearchLoader(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setSearchLoader(false);
    }
  }, [company, currentPage, debouncedQuery, setUsers]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  // ui section

  const renderFilterSection = () => {
    return (
      <div className={styles.headerWrapperStyle}>
        <div className={styles.filterWrapperStyle}>
          <Input
            placeholder={strings.searchInput}
            inputStyle={styles.filterInputStyle}
            leftIcon={searchIcon}
            leftIconStyle={styles.filterIconStyle}
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              if (e.target.value.length > 0) {
                setSearchParams({ query: e.target.value });
              } else {
                setSearchParams(searchParams.delete('query'));
              }
            }}
          />
        </div>
      </div>
    );
  };

  const renderListSection = () => {
    return (
      <div className={styles.tableWrapperStyle}>
        <InfiniteScroll
          data={filteredUsers}
          loading={loading}
          hasMore={hasMore}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        >
          <table>
            <thead>
              <tr className={styles.tableHeaderRowStyle}>
                <th>{strings.userAndEmp}</th>
                <th>{strings.lastAssign}</th>
                <th>{strings.dateAndTime}</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.length > 0
                ? filteredUsers.map((item, index) => (
                    <tr
                      key={index}
                      className={styles.tableRowStyle}
                      onClick={() => navigate(routePaths.employeeDetails(item._id))}
                    >
                      <td>
                        <div className={styles.avatarWrapperStyle}>
                          <Avatar
                            src={item.image}
                            label={item.full_name}
                            containerStyle={styles.avatarStyle}
                          />
                          <div className={styles.customColStyle}>
                            <span>
                              {item.full_name} {item._id === user._id && '(You)'}
                            </span>
                            <span className={styles.tableSmallTextStyle}>
                              {strings.account} {item.account_type}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className={styles.customColStyle}>
                          {item?.asset_id ? (
                            <>
                              <span>{item.asset.asset_name}</span>
                              <span className={styles.tableSmallTextStyle}>
                                #{item.asset.asset_uid}
                              </span>
                            </>
                          ) : (
                            <span className={styles.emptyAssetLineStyle}>-- -- -- -- -- --</span>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className={styles.customColStyle}>
                          {item?.asset_id ? (
                            <>
                              <span>{moment(item.assigned_at).format('ll')}</span>
                              <span className={styles.tableSmallTextStyle}>
                                {moment(item.assigned_at).format('LT')}
                              </span>
                            </>
                          ) : (
                            <span className={styles.emptyAssetLineStyle}>-- -- -- -- -- --</span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                : !loading && (
                    <tr className={styles.tableRowStyle}>
                      <td className={styles.emptyDataStyle}>{strings.noData}</td>
                    </tr>
                  )}
              {loading && (
                <tr>
                  <td colSpan={3}>
                    <div className={styles.loaderSectionStyle}>
                      <Loader containerStyle={styles.loaderStyle} />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    );
  };

  const renderListMobileSection = () => {
    return (
      <div className={styles.mobileViewStyle}>
        <InfiniteScroll
          data={filteredUsers}
          loading={loading}
          hasMore={hasMore}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        >
          <div className={styles.mobileListWrapperStyle}>
            {filteredUsers.length > 0
              ? filteredUsers.map((item, index) => (
                  <React.Fragment key={index}>
                    <AssetAndUserCard
                      type="user"
                      title={item.full_name}
                      description={`${item.account_type} acc..`}
                      image={item?.image}
                      leftLable="Last assigned"
                      leftTitle={item.asset ? item.asset?.asset_name : '- - - -'}
                      leftDescription={item.asset ? item.asset?.asset_uid : ''}
                      rightLable="Date & Time :"
                      rightTitle={
                        item.assigned_at ? moment(item.assigned_at).format('ll') : '- - -'
                      }
                      rightDescription={
                        item.assigned_at ? moment(item.assigned_at).format('LT') : ''
                      }
                      onClick={() => navigate(routePaths.employeeDetails(item._id))}
                      containerStyle={styles.cardStyle}
                    />
                    {loading && index === filteredUsers.length - 1 && (
                      <div className={styles.loaderSectionStyle}>
                        <Loader containerStyle={styles.loaderMobileViewStyle} />
                      </div>
                    )}
                  </React.Fragment>
                ))
              : !searchLoader && (
                  <div className={styles.emptyViewStyle}>
                    <p className={styles.emptyDataStyle}>No data available</p>
                  </div>
                )}
            {loading && filteredUsers.length === 0 && (
              <div className={styles.loaderSectionStyle}>
                <Loader containerStyle={styles.loaderMobileViewStyle} />
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <div className={styles.listContainerStyle}>
      {renderFilterSection()}
      <div className={styles.tableContainerStyle}>
        {width > 480 ? renderListSection() : renderListMobileSection()}
      </div>
    </div>
  );
};

export default EmployeeList;
