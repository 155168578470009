import axios from 'utils/axios';
import * as endPoints from 'networking/endpoints';

export const raiseTicketApi = async (data) =>
  Promise.resolve(axios.post(endPoints.raiseTicket, data));

export const updateUserTicketAccessApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateUserTicketAccess, data));

export const sendTicketMessageApi = async (data) =>
  Promise.resolve(axios.post(endPoints.sendTicketMessage, data));

export const markAsWorkingApi = async (data) =>
  Promise.resolve(axios.post(endPoints.markAsWorking, data));

export const markAsCompletedApi = async (data) =>
  Promise.resolve(axios.post(endPoints.markAsCompleted, data));

export const markAsCannotSolveApi = async (data) =>
  Promise.resolve(axios.post(endPoints.markAsCantSolve, data));

export const markAsResolvedApi = async (data) =>
  Promise.resolve(axios.post(endPoints.markAsResolved, data));

export const markAsRevertApi = async (data) =>
  Promise.resolve(axios.post(endPoints.markAsRevert, data));

export const inviteTicketMembersApi = async (data) =>
  Promise.resolve(axios.post(endPoints.inviteTicketMembers, data));

export const removeTicketMemberApi = async (data) =>
  Promise.resolve(axios.post(endPoints.removeTicketMember, data));
