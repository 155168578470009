import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import classNames from 'classnames';
import {
  addUserIcon,
  assetFillGrayIcon,
  chevronRightFillBlackIcon,
  chevronRightLightGrayIcon,
  closeIcon,
  dashboardIcon,
  flagFillGrayIcon,
  homeIcon,
  infoCircleFillGrayIcon,
  logoIcon,
  maintenanceIcon,
  plusCircleFillGrayIcon,
  scannerIcon,
  searchIcon,
  ticketsIcon,
  usersIcon,
} from 'resources/images';
import { account_types } from 'resources/data';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useStrings } from 'providers/stringsprovider';
import { useAuth } from 'providers/authprovider';
import { useAppData } from 'providers/appdataprovider';
import routePaths from 'routes/paths';
import Image from 'components/common/image';
import Input from 'components/common/input';
import styles from './styles.module.css';

const SideBar = () => {
  const { logout, userType } = useAuth();
  const { showNavMenu, setShowNavMenu } = useAppData();
  const { width } = useWindowDimensions();
  const { strings } = useStrings();
  const { pathname } = useLocation();
  const [currrentRoute, setCurrentRoute] = useState(pathname);
  const navigate = useNavigate();
  const { id } = useParams();

  const quickActionsData = useMemo(
    () => [
      {
        icon: plusCircleFillGrayIcon,
        title: strings.addCompany,
        route: routePaths.createCompany,
        children: [],
        user_types: [account_types.super_admin],
      },
      {
        icon: assetFillGrayIcon,
        title: strings.addAsset,
        route: routePaths.createAsset,
        children: [],
        user_types: [account_types.super_admin, account_types.admin],
      },
      {
        icon: addUserIcon,
        title: strings.addEmployees,
        route: routePaths.createEmployee,
        children: [],
        user_types: [account_types.super_admin, account_types.admin],
      },
      {
        icon: flagFillGrayIcon,
        title: strings.raiseTicket,
        route: routePaths.raiseTicket,
        children: [],
        user_types: [account_types.super_admin, account_types.admin, account_types.employee],
      },
    ],
    [strings]
  );
  const navData = useMemo(
    () => [
      // Todo : temporarily hided
      // {
      //   icon: homeIcon,
      //   title: strings.getStarted,
      //   route: '',
      //   children: [],
      //   user_types: [account_types.super_admin, account_types.admin, account_types.employee],
      // },
      {
        icon: dashboardIcon,
        title: strings.dashboard,
        route: routePaths.dashboard,
        children: [],
        user_types: [account_types.super_admin, account_types.admin, account_types.employee],
      },
      {
        icon: homeIcon,
        title: 'All companies',
        route: routePaths.companyList,
        children: [],
        user_types: [account_types.super_admin],
      },
      {
        icon: assetFillGrayIcon,
        title: strings.allAssets,
        route: routePaths.assetList,
        children: [routePaths.assetDetails(id)],
        user_types: [account_types.super_admin, account_types.admin, account_types.employee],
      },
      {
        icon: usersIcon,
        title: strings.allEmployees,
        route: routePaths.employeeList,
        children: [routePaths.employeeDetails(id)],
        user_types: [account_types.super_admin, account_types.admin],
      },
      {
        icon: ticketsIcon,
        title: strings.allTickets,
        route: routePaths.ticketList,
        children: [],
        user_types: [account_types.super_admin, account_types.admin, account_types.employee],
      },
      {
        icon: maintenanceIcon,
        title: strings.maintenance,
        route: routePaths.clusterList,
        children: [],
        user_types: [account_types.super_admin, account_types.admin, account_types.employee],
      },
      // Todo : temporarily hided
      // {
      //   icon: bookmarkFillGrayIcon,
      //   title: strings.reservationSystem,
      //   route: '',
      //   children: [],
      //   user_types: [account_types.super_admin, account_types.admin, account_types.employee],
      // },
      // {
      //   icon: checkCircleFillGrayIcon,
      //   title: strings.checkInOut,
      //   route: '',
      //   children: [],
      //   user_types: [account_types.super_admin, account_types.admin, account_types.employee],
      // },
      {
        icon: scannerIcon,
        title: strings.scanQR,
        route: routePaths.scanner,
        children: [],
        user_types: [account_types.super_admin, account_types.admin, account_types.employee],
      },
      // Todo : temporarily hided
      // {
      //   icon: analyticsIcon,
      //   title: strings.reportingAndAnalysis,
      //   route: '',
      //   children: [],
      //   user_types: [account_types.super_admin, account_types.admin, account_types.employee],
      // },
      // {
      //   icon: plansIcon,
      //   title: strings.plansAndPricing,
      //   route: '',
      //   children: [],
      //   user_types: [account_types.super_admin, account_types.admin, account_types.employee],
      // },
      // {
      //   icon: settingsFillGrayIcon,
      //   title: strings.toolsAndSettings,
      //   route: '',
      //   children: [],
      //   user_types: [account_types.super_admin, account_types.admin, account_types.employee],
      // },
      {
        icon: infoCircleFillGrayIcon,
        title: strings.logOut,
        route: '',
        children: [],
        user_types: [account_types.super_admin, account_types.admin, account_types.employee],
      },
    ],
    [strings, id]
  );

  useEffect(() => {
    if (width > 480) {
      setShowNavMenu(false);
    }
  }, [width, setShowNavMenu]);

  useEffect(() => {
    if (pathname) {
      let navItems = [...quickActionsData, ...navData];
      let currentRoute = navItems.find(
        (a) => a.route === pathname || a.children.includes(pathname)
      );
      setCurrentRoute(currentRoute?.route);
    }
  }, [pathname, quickActionsData, navData]);

  const handleClickNavItem = (item) => {
    if (item.route !== '') {
      navigate(item.route);
      setShowNavMenu(false);
    }
    if (item.title === 'Log out') {
      logout();
    }
  };

  return (
    <React.Fragment>
      {showNavMenu && <div className={styles.sideBarOverlayStyle} />}
      {(width > 480 || showNavMenu) && (
        <div className={styles.sideBarWrapperStyle}>
          <div className={styles.sideBarLogoSectionStyle}>
            <Image
              src={logoIcon}
              containerStyle={styles.sideBarLogoStyle}
              onClick={() => navigate(routePaths.dashboard)}
            />
            {showNavMenu && (
              <Image
                src={closeIcon}
                onClick={() => setShowNavMenu(false)}
                containerStyle={styles.sideBarCloseIconStyle}
              />
            )}
          </div>
          {showNavMenu && (
            <Input
              placeholder="Search anything here"
              inputStyle={styles.sideBarSearchInputStyle}
              leftIcon={searchIcon}
              leftIconStyle={styles.sideBarSearchIconStyle}
            />
          )}
          <div className={styles.sideBarNavOuterWrapperStyle}>
            <div className={styles.sideBarNavWrapperStyle}>
              <span className={styles.sideBarNavLabelStyle}>Quick Actions</span>
              {quickActionsData.map((item, index) => (
                <React.Fragment key={'qa' + index}>
                  {item.user_types.includes(userType) && (
                    <div
                      className={classNames(
                        styles.sideBarNavItemStyle,
                        currrentRoute === item.route && styles.selectedNavItemStyle
                      )}
                      onClick={() => handleClickNavItem(item)}
                    >
                      <Image
                        src={
                          currrentRoute === item.route
                            ? chevronRightFillBlackIcon
                            : chevronRightLightGrayIcon
                        }
                        containerStyle={styles.sideBarNavItemLeftIconStyle}
                      />
                      <Image src={item.icon} containerStyle={styles.sideBarNavItemRightIconStyle} />
                      <p className={styles.sideBarNavItemTitleStyle}>{item.title}</p>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <div className={styles.sideBarNavWrapperStyle}>
              <span className={styles.sideBarNavLabelStyle}>Navigation</span>
              {navData.map((item, index) => (
                <React.Fragment key={'nav' + index}>
                  {item.user_types.includes(userType) && (
                    <div
                      className={classNames(
                        styles.sideBarNavItemStyle,
                        currrentRoute === item.route && styles.selectedNavItemStyle
                      )}
                      onClick={() => handleClickNavItem(item)}
                    >
                      <Image
                        src={
                          currrentRoute === item.route
                            ? chevronRightFillBlackIcon
                            : chevronRightLightGrayIcon
                        }
                        containerStyle={styles.sideBarNavItemLeftIconStyle}
                      />
                      <Image src={item.icon} containerStyle={styles.sideBarNavItemRightIconStyle} />
                      <p className={styles.sideBarNavItemTitleStyle}>{item.title}</p>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SideBar;
